.logo-big{
  display: none;
  padding: 15px 10px;
  letter-spacing: 4px;
  @include mq(md){
    display: block;
  }

  &:hover{
    text-decoration: none;
  }
  img{
    width: 160px;
  }
}
.main-nav{
  background-color: $header-bg;
  height: $header-height;
  position: fixed;
  z-index: 11;
  width: 100%;
  box-shadow: 0 2px 2px rgba(0,0,0,0.05), 0 1px 0 rgba(0,0,0,0.05);
  -moz-box-shadow: 0 2px 2px rgba(0,0,0,0.05),0 1px 0 rgba(0,0,0,0.05);
  -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.05), 0 1px 0 rgba(0,0,0,0.05);
  .navbar{
    margin-right: 15px;
  }
}
.navbar-left{
  height: $header-height;
  color: $white;
  @include mq(md){
    width: 220px;
    background-color: $logo-bg;
  }
}
.navbar{
  margin-bottom: 0;
  height: $header-height;
  .search-conatiner{
    margin-right: 15px;

  }
  &>ul{
    padding-left: 0;
    list-style: none;
    margin-top: 15px;
    &>li{
      float: left;
      position: relative;
      margin-left: 17px;
    }
  }
}
.user-panel-top{
  color: $base-dark;
  button{
    line-height: 36px;
  }
}
.search-btn{
  border: 0;
  background-color: transparent;
  color: $white;
  line-height: 21px;
  font-size: 16px;
  padding: 15px;
}


.notify {
  position: relative;
  margin-top: -18px;
  display: none;
  .heartbit {
    position: absolute;
    top: -21px;
    right: -10px;
    height: 25px;
    width: 25px;
    z-index: 9;
    border: 5px solid #fb381d;
    border-radius: 70px;
    -moz-animation: heartbit 1s ease-out;
    -moz-animation-iteration-count: infinite;
    -o-animation: heartbit 1s ease-out;
    -o-animation-iteration-count: infinite;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  .point {
    width: 6px;
    height: 6px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: #fb381d;
    position: absolute;
    right: 0px;
    top: -12px;
  }
}
.right-header-btn{
  font-size: 25px;
  color: lighten($header-bg, 14%);
  line-height: 36px;
  &:hover,&:focus{
    color: lighten($header-bg, 18%);
  }
  &.toggled{
    color: #fff;
  }
  &.active{
    .notify{
      display: block;
    }
  }

}
.lg-header {
  text-align: center;
  padding: 5px 10px 13px;
  line-height: 100%;
  border-bottom: 1px solid #F0F0F0;
  color: #4C4C4C;
  margin-bottom: 10px;
  font-weight: 500;
  text-transform: uppercase;
}
@keyframes heartbit{
  0%{transform:scale(0);opacity:0}
  25%{transform:scale(.1);opacity:.1}
  50%{transform:scale(.5);opacity:.3}
  75%{transform:scale(.8);opacity:.5}
  100%{transform:scale(1);opacity:0}
}

.h-search-wrap {
  position: absolute;
  top: -65px;
  left: 0;
  width: 100%;
  height: 70px;
  background: #fff;
  transition: all;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 10;
  -webkit-transition: all;
  -o-transition: all;
  .hsw-close {
    position: absolute;
    top: 15px;
    font-size: 23px;
    font-style: normal;
    width: 45px;
    text-align: center;
    border-radius: 2px 0 0 2px;
    cursor: pointer;
    left: 15px;
    height: 40px;
    padding-top: 9px;
    &:hover {
      background-color: #e3e3e3;
    }
  }
  input[type=text] {
    border: 0;
    height: 40px;
    padding: 0 10px 0 55px;
    font-size: 18px;
    border-radius: 2px;
    background-color: #efefef;
    width: 100%;
  }
}
.main-nav.search-toggled {
  z-index: 12;
}
.main-nav.search-toggled .h-search-wrap {
  top: 0;
  opacity: 1;
  filter: alpha(opacity=100);
}
.hsw-inner {
  position: relative;
  padding: 15px;
  max-width: 700px;
  display: block;
  margin: 0 auto;
}