.popover {
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
    border: 1px solid #ffffff;
    border-radius: 2px;
    &.top{
        &>.arrow{
            border-top-color: #ffffff;
        }
    }
    &.left{
        &>.arrow{
            border-left-color: #ffffff;
        }
    }
    &.bottom{
        &>.arrow{
            border-bottom-color: #ffffff;
        }
    }
    &.right{
        &>.arrow{
            border-right-color: #ffffff;
        }
    }
}

.popover-title {
    border-bottom: 0;
    padding: 15px; 
    font-size: 12px;
    text-transform: uppercase;
    background-color: #fff;
    border-radius: 1px 1px 0 0;
    
    & + .popover-content { 
        padding-top: 0;
    }
}   

.popover-content {
    padding: 15px; 
    
    p {
        margin-bottom: 0; 
    }
} 