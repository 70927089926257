html,body{
  font-family: $roboto;
  -webkit-font-smoothing: antialiased;
}
input:focus{
  outline: none;
}

button:focus, a:focus, :focus{
  outline: 0;
}
ul{
  margin-bottom: 0;
}
.navbar{
  border: 0;
}
.btn{
  outline: none;
}
.bootstrap-select .dropdown-toggle:focus{
  box-shadow: none;
  outline: none !important;
}
.mCSB_inside > .mCSB_container {
  margin-right: 0;
}

.dropdown-toggle > [class^="icon-"], .dropdown-toggle > [class*=" icon-"]{
  font-size: 11px;
}
[class^="sparkline-"]{
  canvas{
    width: 100% !important;
  }
}
.dropdown-menu{
  &>li{
    &>a{
      color: #333;
    }
  }
}