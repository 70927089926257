.main-container {
  min-height: calc(100vh - 60px);
  position: relative;
  margin-left: 0;

  margin-top: $header-height;
  background-color: $content-bg;
  @include mq(md){
    margin-left: 220px;
    -webkit-transition: all 350ms cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 350ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 350ms cubic-bezier(0.19, 1, 0.22, 1);
  }
}
.page-title{
  //background-color: $pageTitle-bg;
  //box-shadow: rgba(227, 228, 229, 0.5) 2px 2px 0px 0px;
  //border-bottom: 1px solid rgb(222, 229, 231);
  padding: 10px 15px;
  @include mq(md){
    position: fixed;
    margin-top: -72px;
    z-index: 11;
  }
  h1{
    font-family: $open-sans;
    font-size: 22px;
    color: #636363;
    font-weight: 500;
    margin-top: 0;
    @include mq(md){
      color: $white;
    }
  }
  .breadcrumb{
    margin-bottom: 0;
    float: left;
    background: transparent;
    padding: 0;
    border-bottom: 0;
    a{
      font-size: 12px;
      color: #636363;
      &:hover,&:focus,&:active{
        text-decoration: underline;
      }
      @include mq(md){
        color: $white;
      }
    }
    li{
      &:last-child{
        a{
          color: #9c9c9c;
          cursor: text;
          &:hover,&:focus,&:active{
            text-decoration: none;
          }
          @include mq(md){
            color: #c3c3c3;
          }
        }
      }
    }
  }
}
.page-body{
  padding: 15px;
}