#myTable #counter {
  width: 10%;
}
#myTable #stg {
  width: 30%;
}
#myTable #p-and-t {
  width: 30%;
}
#myTable #score-a {
  width: 15%;
}
#myTable #score-b {
  width: 15%;
}

.logo {
  width: unset;
  margin-left: auto;
  margin-right: auto;
}
.toggle-switch {
  width: 100%;
  padding-right: 50px;
}
.toggle-switch .ts-helper {
  float: right;
}

// Score Table
.table-score > thead > tr > th, .table-score > thead > tr > td, .table-score > tbody > tr > th, .table-score > tbody > tr > td, .table-score > tfoot > tr > th, .table-score > tfoot > tr > td {
  border: 2px solid #616161;
}

.table-responsive-score {
  width: 100%;
  margin-bottom: 15px;
  overflow-y: visible;  // Add overflow-y visible
  overflow-x: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border: 1px solid #ddd;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 992px) {
  .table-responsive-score {
    overflow: unset;
  }
}
.gray-bg {
  background-color: #ebebeb;
}
