.dropdown-menu {
    box-shadow: $dropdown-shadow;
    @include animated(fadeIn, 300ms);
    padding: 10px 0;
    border-radius: 3px;
    top: -1px;
    margin: 0;
    border: 0;
    
    & > li > a { 
        padding: 10px 20px;
        @include transition(background-color);
        @include transition-duration(300ms);
        &:hover,&:focus,&:active{
            background-color: rgba(0,0,0,.075);
        }
    }

    &.dm-icon {
        & > li > a > .zmdi {
            line-height: 100%;
            vertical-align: top;
            font-size: 18px;
            width: 28px;
        } 
    }
    .divider {
        margin: 8px 0;
        background-color: rgba(0,0,0,.08);
    }
    &[class*=bgm-] > li > a {
        color: $white;
    }
}

.dm-dark {
    & > li {
        & > a,
        & > a:hover {
            color: #fff;
        }
    }
}

.dropdown-header {
    padding: 10px 16px 5px;
    color: #B9B9B9;
    text-transform: uppercase;
}