.crop-box{
  height: 200px;
  width: 100%;
  overflow: hidden;
}
.round-crop + .cropper-container .cropper-view-box,
.round-crop + .cropper-container .cropper-face {
  border-radius: 50%;
}
#result{
  width: 55%;
  img{
    width: 100%;
  }
}