.tooltip-inner {
    border-radius: 2px;
    padding: 5px 15px 5px;
    background-color: #737373;
}
.tooltip{
    &.top{
        .tooltip-arrow {
            border-top-color: #737373;
        }
    }
    &.left{
        .tooltip-arrow {
            border-left-color: #737373;
        }
    }
    &.bottom{
        .tooltip-arrow {
            border-bottom-color: #737373;
        }
    }
    &.right{
        .tooltip-arrow {
            border-right-color: #737373;
        }
    }
}