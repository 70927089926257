//COLORS
$base-dark: #456D9F;
$header-bg: #383D40;
$logo-bg: #3E6595;
$content-bg: #f3f3f3;
$pageTitle-bg: #ffffff;
$white: #ffffff;

$primary: #3096f3;
$success: #59af51;
$info: #55bcd5;
$warning: #f19634;
$danger: #e94034;
