

/*
 * Lightbox  
 */

.lightbox {	 
	.lightbox-item {
		
		& > img {
			width: 100%;
			border-radius: 2px;
		}

		&:hover {
			cursor: pointer;
			@include opacity(0.85);
		}
	}
	
	[data-src] {
		@include clearfix();
	}
	
	.lightbox-item:not(.p-item) {
		position: relative;
	}
}

/*
 * Carousel
 */
.carousel {
	.carousel-control {
		@include transition(all);
		@include transition-duration(250ms);
		@include opacity(0);
		
		.zmdi {
			position: absolute;
			top: 50%;
			left: 50%;
			line-height: 100%;
			
			@media screen and (min-width: $screen-sm-min) {
				font-size: 60px;
				width: 60px;
				height: 60px;
				margin-top: -30px;
				margin-left: -30px;
			}
			
			@media screen and (max-width: $screen-sm-max) {
				width: 24px;
				height: 24px;
				margin-top: -12px;
				margin-left: -12px;
			}
		}
	}
	
	&:hover {
		.carousel-control {
			@include opacity(1);
		}
	}
	
	.carousel-caption {
		background: rgba(0,0,0,0.6);
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		padding-bottom: 50px;
		
		& > h3 {
			color: #fff;
			margin: 0 0 5px;
			font-weight: 300;
		}
		
		& > p {
			margin: 0;
		}
		
		@media screen and (max-width: $screen-sm-max) {
			display: none;
		}
	}
	
	.carousel-indicators {
		bottom: 10px;
		margin: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 0 0 6px;
		background: rgba(0,0,0,0.6);
		
		li {
			border-radius: 0;
			width: 15px;
			border: 0;
			background: #fff;
			height: 3px;
			margin: 0;
			@include transition(all);
			@include transition-duration(250ms);
			
			&.active {
				width: 25px;
				height: 3px;
				background: $m-orange;
			}
		}
	}
}

