.badge {
  border-radius: 10px;
  &.badge-roundless{
    border-radius: 0;
  }
}
.badge-default{
  @include badge-variant($gray-light);
}
.badge-primary{
  @include badge-variant($brand-primary);
}
.badge-info{
  @include badge-variant($brand-info);
}
.badge-success{
  @include badge-variant($brand-success);
}
.badge-warning{
  @include badge-variant($brand-warning);
}
.badge-danger{
  @include badge-variant($brand-danger);
}