.menu{
  width: 220px;
  height: calc(100% - #{$header-height});
  position: fixed;
  top: $header-height;
  background: $base-dark;
  z-index: 10;
  @media only screen and (max-width: 1023px){
    -webkit-transition: all 350ms cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 350ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 350ms cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transform: translate3d(-220px, 0, 0);
    -moz-transform: translate3d(-220px, 0, 0);
    transform: translate3d(-220px, 0, 0);
  }
  &.toggled{
    box-shadow: 0 0 20px rgba(14,18,21,.38);
    @media only screen and (max-width: 1023px){
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      z-index: 111;
    }
  }
}
.main-menu{
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  margin-top: 10px;
  &>li{
    &>a{
      border-left-width: 3px;
      border-left-color: transparent;
      border-left-style: solid;
      position: relative;
      &.menu-parent{
        &:before{
          position: absolute;
          content: "\e606";
          font-family: 'simple-line-icons';
          right: 13px;
          top: 14px;
          speak: none;
          font-size: 10px;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          -webkit-transition: all 0.4s ease;
          -o-transition: all 0.4s ease;
          transition: all 0.4s ease;
        }
      }

      &:hover,&:focus{
        text-decoration: none;
      }
      &:hover{
        background-color: lighten($header-bg, 2%);
      }
      &.active{
        border-left-color: cornflowerblue;
        color: #FBBF00;
        &:hover,&:focus{
          background-color: transparent;
        }
      }
    }

    a{
      padding-top: 9px;
      padding-right: 20px;
      padding-bottom: 8px;
      padding-left: 42px;
      color: $white;
      display: block;
      vertical-align: middle;
      position: relative;
      &:hover,&:focus{
        text-decoration: none;
      }
      i{
        position: absolute;
        left: 6px;
        font-size: 20px;
        top: 0;
        width: 25px;
        text-align: center;
        padding: 9px 0;
      }
    }

    &.open{
      &>a{
        background-color: lighten($base-dark, 2%);
        color: #f3f3f3;
        &.menu-parent{
          &:before{
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }
      .submenu{
        display: block;
      }
    }

    &.menu-title{
      padding-top: 13px;
      padding-right: 20px;
      padding-bottom: 7px;
      padding-left: 15px;
      color: #789FD1;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      //border-top: 1px solid lighten($base-dark, 3%);
      margin-top: 8px;
      letter-spacing: 1px;
    }

    .submenu{
      position: relative;
      display: none;
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;
      background-color: darken($base-dark, 5%);
      li{
        position: relative;
        &:before{
          content: "";
          border-bottom: 1px dotted rgba(134, 159, 177, 0.5);
          width: 10px;
          height: 0;
          position: absolute;
          top: 50%;
          left: 21px;
        }
      }
      &:after{
        position: absolute;
        top: 0;
        left: 20px;
        width: 0;
        height: 100%;
        border-left: 1px dotted rgba(134, 159, 177, 0.5);
        content: "";
      }
      a{
        padding-left: 42px;
        &:hover{
          color: $white;
        }
        &.active{
          color: #FBBF00;
        }
      }
    }
  }
}

.sidebar-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9;
  @include mq(sm){
    display: none;
  }
}

.ma-backdrop, .ac-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  cursor: pointer;
}
.navbar-left{
  position: relative;
}
.user-alerts{
  position: fixed;
  top: 0;
  width: 320px;
  background: #fff;
  height: 100%;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  right: -320px;
  opacity: 0;
  transition: all;
  transition-duration: 250ms;
  &.toggled {
    right: 0;
    opacity: 1;
    z-index: 101;
  }
}
.sua-menu {
  margin: -22px 0 0;
  padding: 10px 0;
  position: relative;
  z-index: 1;
  text-align: center;

  li {
    padding: 0;

    &:hover a {
      color: #fff;
    }

    a {
      color: rgba(255,255,255,0.7);
      padding: 0 10px;
      font-size: 12px;

      i {
        font-size: 15px;
        line-height: 100%;
        vertical-align: top;
        margin-top: 2px;
        margin-right: 3px;
      }
    }
  }
}