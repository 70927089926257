/* Material Design Iconic Font uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
$zmdi-var-3d-rotation: '\f101' !default;
$zmdi-var-airplane-off: '\f102' !default;
$zmdi-var-airplane: '\f103' !default;
$zmdi-var-album: '\f104' !default;
$zmdi-var-archive: '\f105' !default;
$zmdi-var-assignment-account: '\f106' !default;
$zmdi-var-assignment-alert: '\f107' !default;
$zmdi-var-assignment-check: '\f108' !default;
$zmdi-var-assignment-o: '\f109' !default;
$zmdi-var-assignment-return: '\f10a' !default;
$zmdi-var-assignment-returned: '\f10b' !default;
$zmdi-var-assignment: '\f10c' !default;
$zmdi-var-attachment-alt: '\f10d' !default;
$zmdi-var-attachment: '\f10e' !default;
$zmdi-var-audio: '\f10f' !default;
$zmdi-var-badge-check: '\f110' !default;
$zmdi-var-balance-wallet: '\f111' !default;
$zmdi-var-balance: '\f112' !default;
$zmdi-var-battery-alert: '\f113' !default;
$zmdi-var-battery-flash: '\f114' !default;
$zmdi-var-battery-unknown: '\f115' !default;
$zmdi-var-battery: '\f116' !default;
$zmdi-var-bike: '\f117' !default;
$zmdi-var-block-alt: '\f118' !default;
$zmdi-var-block: '\f119' !default;
$zmdi-var-boat: '\f11a' !default;
$zmdi-var-book-image: '\f11b' !default;
$zmdi-var-book: '\f11c' !default;
$zmdi-var-bookmark-outline: '\f11d' !default;
$zmdi-var-bookmark: '\f11e' !default;
$zmdi-var-brush: '\f11f' !default;
$zmdi-var-bug: '\f120' !default;
$zmdi-var-bus: '\f121' !default;
$zmdi-var-cake: '\f122' !default;
$zmdi-var-car-taxi: '\f123' !default;
$zmdi-var-car-wash: '\f124' !default;
$zmdi-var-car: '\f125' !default;
$zmdi-var-card-giftcard: '\f126' !default;
$zmdi-var-card-membership: '\f127' !default;
$zmdi-var-card-travel: '\f128' !default;
$zmdi-var-card: '\f129' !default;
$zmdi-var-case-check: '\f12a' !default;
$zmdi-var-case-download: '\f12b' !default;
$zmdi-var-case-play: '\f12c' !default;
$zmdi-var-case: '\f12d' !default;
$zmdi-var-cast-connected: '\f12e' !default;
$zmdi-var-cast: '\f12f' !default;
$zmdi-var-chart-donut: '\f130' !default;
$zmdi-var-chart: '\f131' !default;
$zmdi-var-city-alt: '\f132' !default;
$zmdi-var-city: '\f133' !default;
$zmdi-var-close-circle-o: '\f134' !default;
$zmdi-var-close-circle: '\f135' !default;
$zmdi-var-close: '\f136' !default;
$zmdi-var-cocktail: '\f137' !default;
$zmdi-var-code-setting: '\f138' !default;
$zmdi-var-code-smartphone: '\f139' !default;
$zmdi-var-code: '\f13a' !default;
$zmdi-var-coffee: '\f13b' !default;
$zmdi-var-collection-bookmark: '\f13c' !default;
$zmdi-var-collection-case-play: '\f13d' !default;
$zmdi-var-collection-folder-image: '\f13e' !default;
$zmdi-var-collection-image-o: '\f13f' !default;
$zmdi-var-collection-image: '\f140' !default;
$zmdi-var-collection-item-1: '\f141' !default;
$zmdi-var-collection-item-2: '\f142' !default;
$zmdi-var-collection-item-3: '\f143' !default;
$zmdi-var-collection-item-4: '\f144' !default;
$zmdi-var-collection-item-5: '\f145' !default;
$zmdi-var-collection-item-6: '\f146' !default;
$zmdi-var-collection-item-7: '\f147' !default;
$zmdi-var-collection-item-8: '\f148' !default;
$zmdi-var-collection-item-9-plus: '\f149' !default;
$zmdi-var-collection-item-9: '\f14a' !default;
$zmdi-var-collection-item: '\f14b' !default;
$zmdi-var-collection-music: '\f14c' !default;
$zmdi-var-collection-pdf: '\f14d' !default;
$zmdi-var-collection-plus: '\f14e' !default;
$zmdi-var-collection-speaker: '\f14f' !default;
$zmdi-var-collection-text: '\f150' !default;
$zmdi-var-collection-video: '\f151' !default;
$zmdi-var-compass: '\f152' !default;
$zmdi-var-cutlery: '\f153' !default;
$zmdi-var-delete: '\f154' !default;
$zmdi-var-dialpad: '\f155' !default;
$zmdi-var-dns: '\f156' !default;
$zmdi-var-drink: '\f157' !default;
$zmdi-var-edit: '\f158' !default;
$zmdi-var-email-open: '\f159' !default;
$zmdi-var-email: '\f15a' !default;
$zmdi-var-eye-off: '\f15b' !default;
$zmdi-var-eye: '\f15c' !default;
$zmdi-var-eyedropper: '\f15d' !default;
$zmdi-var-favorite-outline: '\f15e' !default;
$zmdi-var-favorite: '\f15f' !default;
$zmdi-var-filter-list: '\f160' !default;
$zmdi-var-fire: '\f161' !default;
$zmdi-var-flag: '\f162' !default;
$zmdi-var-flare: '\f163' !default;
$zmdi-var-flash-auto: '\f164' !default;
$zmdi-var-flash-off: '\f165' !default;
$zmdi-var-flash: '\f166' !default;
$zmdi-var-flip: '\f167' !default;
$zmdi-var-flower-alt: '\f168' !default;
$zmdi-var-flower: '\f169' !default;
$zmdi-var-font: '\f16a' !default;
$zmdi-var-fullscreen-alt: '\f16b' !default;
$zmdi-var-fullscreen-exit: '\f16c' !default;
$zmdi-var-fullscreen: '\f16d' !default;
$zmdi-var-functions: '\f16e' !default;
$zmdi-var-gas-station: '\f16f' !default;
$zmdi-var-gesture: '\f170' !default;
$zmdi-var-globe-alt: '\f171' !default;
$zmdi-var-globe-lock: '\f172' !default;
$zmdi-var-globe: '\f173' !default;
$zmdi-var-graduation-cap: '\f174' !default;
$zmdi-var-home: '\f175' !default;
$zmdi-var-hospital-alt: '\f176' !default;
$zmdi-var-hospital: '\f177' !default;
$zmdi-var-hotel: '\f178' !default;
$zmdi-var-hourglass-alt: '\f179' !default;
$zmdi-var-hourglass-outline: '\f17a' !default;
$zmdi-var-hourglass: '\f17b' !default;
$zmdi-var-http: '\f17c' !default;
$zmdi-var-image-alt: '\f17d' !default;
$zmdi-var-image-o: '\f17e' !default;
$zmdi-var-image: '\f17f' !default;
$zmdi-var-inbox: '\f180' !default;
$zmdi-var-invert-colors-off: '\f181' !default;
$zmdi-var-invert-colors: '\f182' !default;
$zmdi-var-key: '\f183' !default;
$zmdi-var-label-alt-outline: '\f184' !default;
$zmdi-var-label-alt: '\f185' !default;
$zmdi-var-label-heart: '\f186' !default;
$zmdi-var-label: '\f187' !default;
$zmdi-var-labels: '\f188' !default;
$zmdi-var-lamp: '\f189' !default;
$zmdi-var-landscape: '\f18a' !default;
$zmdi-var-layers-off: '\f18b' !default;
$zmdi-var-layers: '\f18c' !default;
$zmdi-var-library: '\f18d' !default;
$zmdi-var-link: '\f18e' !default;
$zmdi-var-lock-open: '\f18f' !default;
$zmdi-var-lock-outline: '\f190' !default;
$zmdi-var-lock: '\f191' !default;
$zmdi-var-mail-reply-all: '\f192' !default;
$zmdi-var-mail-reply: '\f193' !default;
$zmdi-var-mail-send: '\f194' !default;
$zmdi-var-mall: '\f195' !default;
$zmdi-var-map: '\f196' !default;
$zmdi-var-menu: '\f197' !default;
$zmdi-var-money-box: '\f198' !default;
$zmdi-var-money-off: '\f199' !default;
$zmdi-var-money: '\f19a' !default;
$zmdi-var-more-vert: '\f19b' !default;
$zmdi-var-more: '\f19c' !default;
$zmdi-var-movie-alt: '\f19d' !default;
$zmdi-var-movie: '\f19e' !default;
$zmdi-var-nature-people: '\f19f' !default;
$zmdi-var-nature: '\f1a0' !default;
$zmdi-var-navigation: '\f1a1' !default;
$zmdi-var-open-in-browser: '\f1a2' !default;
$zmdi-var-open-in-new: '\f1a3' !default;
$zmdi-var-palette: '\f1a4' !default;
$zmdi-var-parking: '\f1a5' !default;
$zmdi-var-pin-account: '\f1a6' !default;
$zmdi-var-pin-assistant: '\f1a7' !default;
$zmdi-var-pin-drop: '\f1a8' !default;
$zmdi-var-pin-help: '\f1a9' !default;
$zmdi-var-pin-off: '\f1aa' !default;
$zmdi-var-pin: '\f1ab' !default;
$zmdi-var-pizza: '\f1ac' !default;
$zmdi-var-plaster: '\f1ad' !default;
$zmdi-var-power-setting: '\f1ae' !default;
$zmdi-var-power: '\f1af' !default;
$zmdi-var-print: '\f1b0' !default;
$zmdi-var-puzzle-piece: '\f1b1' !default;
$zmdi-var-quote: '\f1b2' !default;
$zmdi-var-railway: '\f1b3' !default;
$zmdi-var-receipt: '\f1b4' !default;
$zmdi-var-refresh-alt: '\f1b5' !default;
$zmdi-var-refresh-sync-alert: '\f1b6' !default;
$zmdi-var-refresh-sync-off: '\f1b7' !default;
$zmdi-var-refresh-sync: '\f1b8' !default;
$zmdi-var-refresh: '\f1b9' !default;
$zmdi-var-roller: '\f1ba' !default;
$zmdi-var-ruler: '\f1bb' !default;
$zmdi-var-scissors: '\f1bc' !default;
$zmdi-var-screen-rotation-lock: '\f1bd' !default;
$zmdi-var-screen-rotation: '\f1be' !default;
$zmdi-var-search-for: '\f1bf' !default;
$zmdi-var-search-in-file: '\f1c0' !default;
$zmdi-var-search-in-page: '\f1c1' !default;
$zmdi-var-search-replace: '\f1c2' !default;
$zmdi-var-search: '\f1c3' !default;
$zmdi-var-seat: '\f1c4' !default;
$zmdi-var-settings-square: '\f1c5' !default;
$zmdi-var-settings: '\f1c6' !default;
$zmdi-var-shield-check: '\f1c7' !default;
$zmdi-var-shield-security: '\f1c8' !default;
$zmdi-var-shopping-basket: '\f1c9' !default;
$zmdi-var-shopping-cart-plus: '\f1ca' !default;
$zmdi-var-shopping-cart: '\f1cb' !default;
$zmdi-var-sign-in: '\f1cc' !default;
$zmdi-var-sort-amount-asc: '\f1cd' !default;
$zmdi-var-sort-amount-desc: '\f1ce' !default;
$zmdi-var-sort-asc: '\f1cf' !default;
$zmdi-var-sort-desc: '\f1d0' !default;
$zmdi-var-spellcheck: '\f1d1' !default;
$zmdi-var-storage: '\f1d2' !default;
$zmdi-var-store-24: '\f1d3' !default;
$zmdi-var-store: '\f1d4' !default;
$zmdi-var-subway: '\f1d5' !default;
$zmdi-var-sun: '\f1d6' !default;
$zmdi-var-tab-unselected: '\f1d7' !default;
$zmdi-var-tab: '\f1d8' !default;
$zmdi-var-tag-close: '\f1d9' !default;
$zmdi-var-tag-more: '\f1da' !default;
$zmdi-var-tag: '\f1db' !default;
$zmdi-var-thumb-down: '\f1dc' !default;
$zmdi-var-thumb-up-down: '\f1dd' !default;
$zmdi-var-thumb-up: '\f1de' !default;
$zmdi-var-ticket-star: '\f1df' !default;
$zmdi-var-toll: '\f1e0' !default;
$zmdi-var-toys: '\f1e1' !default;
$zmdi-var-traffic: '\f1e2' !default;
$zmdi-var-translate: '\f1e3' !default;
$zmdi-var-triangle-down: '\f1e4' !default;
$zmdi-var-triangle-up: '\f1e5' !default;
$zmdi-var-truck: '\f1e6' !default;
$zmdi-var-turning-sign: '\f1e7' !default;
$zmdi-var-wallpaper: '\f1e8' !default;
$zmdi-var-washing-machine: '\f1e9' !default;
$zmdi-var-window-maximize: '\f1ea' !default;
$zmdi-var-window-minimize: '\f1eb' !default;
$zmdi-var-window-restore: '\f1ec' !default;
$zmdi-var-wrench: '\f1ed' !default;
$zmdi-var-zoom-in: '\f1ee' !default;
$zmdi-var-zoom-out: '\f1ef' !default;
$zmdi-var-alert-circle-o: '\f1f0' !default;
$zmdi-var-alert-circle: '\f1f1' !default;
$zmdi-var-alert-octagon: '\f1f2' !default;
$zmdi-var-alert-polygon: '\f1f3' !default;
$zmdi-var-alert-triangle: '\f1f4' !default;
$zmdi-var-help-outline: '\f1f5' !default;
$zmdi-var-help: '\f1f6' !default;
$zmdi-var-info-outline: '\f1f7' !default;
$zmdi-var-info: '\f1f8' !default;
$zmdi-var-notifications-active: '\f1f9' !default;
$zmdi-var-notifications-add: '\f1fa' !default;
$zmdi-var-notifications-none: '\f1fb' !default;
$zmdi-var-notifications-off: '\f1fc' !default;
$zmdi-var-notifications-paused: '\f1fd' !default;
$zmdi-var-notifications: '\f1fe' !default;
$zmdi-var-account-add: '\f1ff' !default;
$zmdi-var-account-box-mail: '\f200' !default;
$zmdi-var-account-box-o: '\f201' !default;
$zmdi-var-account-box-phone: '\f202' !default;
$zmdi-var-account-box: '\f203' !default;
$zmdi-var-account-calendar: '\f204' !default;
$zmdi-var-account-circle: '\f205' !default;
$zmdi-var-account-o: '\f206' !default;
$zmdi-var-account: '\f207' !default;
$zmdi-var-accounts-add: '\f208' !default;
$zmdi-var-accounts-alt: '\f209' !default;
$zmdi-var-accounts-list-alt: '\f20a' !default;
$zmdi-var-accounts-list: '\f20b' !default;
$zmdi-var-accounts-outline: '\f20c' !default;
$zmdi-var-accounts: '\f20d' !default;
$zmdi-var-face: '\f20e' !default;
$zmdi-var-female: '\f20f' !default;
$zmdi-var-male-alt: '\f210' !default;
$zmdi-var-male-female: '\f211' !default;
$zmdi-var-male: '\f212' !default;
$zmdi-var-mood-bad: '\f213' !default;
$zmdi-var-mood: '\f214' !default;
$zmdi-var-run: '\f215' !default;
$zmdi-var-walk: '\f216' !default;
$zmdi-var-cloud-box: '\f217' !default;
$zmdi-var-cloud-circle: '\f218' !default;
$zmdi-var-cloud-done: '\f219' !default;
$zmdi-var-cloud-download: '\f21a' !default;
$zmdi-var-cloud-off: '\f21b' !default;
$zmdi-var-cloud-outline-alt: '\f21c' !default;
$zmdi-var-cloud-outline: '\f21d' !default;
$zmdi-var-cloud-upload: '\f21e' !default;
$zmdi-var-cloud: '\f21f' !default;
$zmdi-var-download: '\f220' !default;
$zmdi-var-file-plus: '\f221' !default;
$zmdi-var-file-text: '\f222' !default;
$zmdi-var-file: '\f223' !default;
$zmdi-var-folder-outline: '\f224' !default;
$zmdi-var-folder-person: '\f225' !default;
$zmdi-var-folder-star-alt: '\f226' !default;
$zmdi-var-folder-star: '\f227' !default;
$zmdi-var-folder: '\f228' !default;
$zmdi-var-gif: '\f229' !default;
$zmdi-var-upload: '\f22a' !default;
$zmdi-var-border-all: '\f22b' !default;
$zmdi-var-border-bottom: '\f22c' !default;
$zmdi-var-border-clear: '\f22d' !default;
$zmdi-var-border-color: '\f22e' !default;
$zmdi-var-border-horizontal: '\f22f' !default;
$zmdi-var-border-inner: '\f230' !default;
$zmdi-var-border-left: '\f231' !default;
$zmdi-var-border-outer: '\f232' !default;
$zmdi-var-border-right: '\f233' !default;
$zmdi-var-border-style: '\f234' !default;
$zmdi-var-border-top: '\f235' !default;
$zmdi-var-border-vertical: '\f236' !default;
$zmdi-var-copy: '\f237' !default;
$zmdi-var-crop: '\f238' !default;
$zmdi-var-format-align-center: '\f239' !default;
$zmdi-var-format-align-justify: '\f23a' !default;
$zmdi-var-format-align-left: '\f23b' !default;
$zmdi-var-format-align-right: '\f23c' !default;
$zmdi-var-format-bold: '\f23d' !default;
$zmdi-var-format-clear-all: '\f23e' !default;
$zmdi-var-format-clear: '\f23f' !default;
$zmdi-var-format-color-fill: '\f240' !default;
$zmdi-var-format-color-reset: '\f241' !default;
$zmdi-var-format-color-text: '\f242' !default;
$zmdi-var-format-indent-decrease: '\f243' !default;
$zmdi-var-format-indent-increase: '\f244' !default;
$zmdi-var-format-italic: '\f245' !default;
$zmdi-var-format-line-spacing: '\f246' !default;
$zmdi-var-format-list-bulleted: '\f247' !default;
$zmdi-var-format-list-numbered: '\f248' !default;
$zmdi-var-format-ltr: '\f249' !default;
$zmdi-var-format-rtl: '\f24a' !default;
$zmdi-var-format-size: '\f24b' !default;
$zmdi-var-format-strikethrough-s: '\f24c' !default;
$zmdi-var-format-strikethrough: '\f24d' !default;
$zmdi-var-format-subject: '\f24e' !default;
$zmdi-var-format-underlined: '\f24f' !default;
$zmdi-var-format-valign-bottom: '\f250' !default;
$zmdi-var-format-valign-center: '\f251' !default;
$zmdi-var-format-valign-top: '\f252' !default;
$zmdi-var-redo: '\f253' !default;
$zmdi-var-select-all: '\f254' !default;
$zmdi-var-space-bar: '\f255' !default;
$zmdi-var-text-format: '\f256' !default;
$zmdi-var-transform: '\f257' !default;
$zmdi-var-undo: '\f258' !default;
$zmdi-var-wrap-text: '\f259' !default;
$zmdi-var-comment-alert: '\f25a' !default;
$zmdi-var-comment-alt-text: '\f25b' !default;
$zmdi-var-comment-alt: '\f25c' !default;
$zmdi-var-comment-edit: '\f25d' !default;
$zmdi-var-comment-image: '\f25e' !default;
$zmdi-var-comment-list: '\f25f' !default;
$zmdi-var-comment-more: '\f260' !default;
$zmdi-var-comment-outline: '\f261' !default;
$zmdi-var-comment-text-alt: '\f262' !default;
$zmdi-var-comment-text: '\f263' !default;
$zmdi-var-comment-video: '\f264' !default;
$zmdi-var-comment: '\f265' !default;
$zmdi-var-comments: '\f266' !default;
$zmdi-var-check-all: '\f267' !default;
$zmdi-var-check-circle-u: '\f268' !default;
$zmdi-var-check-circle: '\f269' !default;
$zmdi-var-check-square: '\f26a' !default;
$zmdi-var-check: '\f26b' !default;
$zmdi-var-circle-o: '\f26c' !default;
$zmdi-var-circle: '\f26d' !default;
$zmdi-var-dot-circle-alt: '\f26e' !default;
$zmdi-var-dot-circle: '\f26f' !default;
$zmdi-var-minus-circle-outline: '\f270' !default;
$zmdi-var-minus-circle: '\f271' !default;
$zmdi-var-minus-square: '\f272' !default;
$zmdi-var-minus: '\f273' !default;
$zmdi-var-plus-circle-o-duplicate: '\f274' !default;
$zmdi-var-plus-circle-o: '\f275' !default;
$zmdi-var-plus-circle: '\f276' !default;
$zmdi-var-plus-square: '\f277' !default;
$zmdi-var-plus: '\f278' !default;
$zmdi-var-square-o: '\f279' !default;
$zmdi-var-star-circle: '\f27a' !default;
$zmdi-var-star-half: '\f27b' !default;
$zmdi-var-star-outline: '\f27c' !default;
$zmdi-var-star: '\f27d' !default;
$zmdi-var-bluetooth-connected: '\f27e' !default;
$zmdi-var-bluetooth-off: '\f27f' !default;
$zmdi-var-bluetooth-search: '\f280' !default;
$zmdi-var-bluetooth-setting: '\f281' !default;
$zmdi-var-bluetooth: '\f282' !default;
$zmdi-var-camera-add: '\f283' !default;
$zmdi-var-camera-alt: '\f284' !default;
$zmdi-var-camera-bw: '\f285' !default;
$zmdi-var-camera-front: '\f286' !default;
$zmdi-var-camera-mic: '\f287' !default;
$zmdi-var-camera-party-mode: '\f288' !default;
$zmdi-var-camera-rear: '\f289' !default;
$zmdi-var-camera-roll: '\f28a' !default;
$zmdi-var-camera-switch: '\f28b' !default;
$zmdi-var-camera: '\f28c' !default;
$zmdi-var-card-alert: '\f28d' !default;
$zmdi-var-card-off: '\f28e' !default;
$zmdi-var-card-sd: '\f28f' !default;
$zmdi-var-card-sim: '\f290' !default;
$zmdi-var-desktop-mac: '\f291' !default;
$zmdi-var-desktop-windows: '\f292' !default;
$zmdi-var-device-hub: '\f293' !default;
$zmdi-var-devices-off: '\f294' !default;
$zmdi-var-devices: '\f295' !default;
$zmdi-var-dock: '\f296' !default;
$zmdi-var-floppy: '\f297' !default;
$zmdi-var-gamepad: '\f298' !default;
$zmdi-var-gps-dot: '\f299' !default;
$zmdi-var-gps-off: '\f29a' !default;
$zmdi-var-gps: '\f29b' !default;
$zmdi-var-headset-mic: '\f29c' !default;
$zmdi-var-headset: '\f29d' !default;
$zmdi-var-input-antenna: '\f29e' !default;
$zmdi-var-input-composite: '\f29f' !default;
$zmdi-var-input-hdmi: '\f2a0' !default;
$zmdi-var-input-power: '\f2a1' !default;
$zmdi-var-input-svideo: '\f2a2' !default;
$zmdi-var-keyboard-hide: '\f2a3' !default;
$zmdi-var-keyboard: '\f2a4' !default;
$zmdi-var-laptop-chromebook: '\f2a5' !default;
$zmdi-var-laptop-mac: '\f2a6' !default;
$zmdi-var-laptop: '\f2a7' !default;
$zmdi-var-mic-off: '\f2a8' !default;
$zmdi-var-mic-outline: '\f2a9' !default;
$zmdi-var-mic-setting: '\f2aa' !default;
$zmdi-var-mic: '\f2ab' !default;
$zmdi-var-mouse: '\f2ac' !default;
$zmdi-var-network-alert: '\f2ad' !default;
$zmdi-var-network-locked: '\f2ae' !default;
$zmdi-var-network-off: '\f2af' !default;
$zmdi-var-network-outline: '\f2b0' !default;
$zmdi-var-network-setting: '\f2b1' !default;
$zmdi-var-network: '\f2b2' !default;
$zmdi-var-phone-bluetooth: '\f2b3' !default;
$zmdi-var-phone-end: '\f2b4' !default;
$zmdi-var-phone-forwarded: '\f2b5' !default;
$zmdi-var-phone-in-talk: '\f2b6' !default;
$zmdi-var-phone-locked: '\f2b7' !default;
$zmdi-var-phone-missed: '\f2b8' !default;
$zmdi-var-phone-msg: '\f2b9' !default;
$zmdi-var-phone-paused: '\f2ba' !default;
$zmdi-var-phone-ring: '\f2bb' !default;
$zmdi-var-phone-setting: '\f2bc' !default;
$zmdi-var-phone-sip: '\f2bd' !default;
$zmdi-var-phone: '\f2be' !default;
$zmdi-var-portable-wifi-changes: '\f2bf' !default;
$zmdi-var-portable-wifi-off: '\f2c0' !default;
$zmdi-var-portable-wifi: '\f2c1' !default;
$zmdi-var-radio: '\f2c2' !default;
$zmdi-var-reader: '\f2c3' !default;
$zmdi-var-remote-control-alt: '\f2c4' !default;
$zmdi-var-remote-control: '\f2c5' !default;
$zmdi-var-router: '\f2c6' !default;
$zmdi-var-scanner: '\f2c7' !default;
$zmdi-var-smartphone-android: '\f2c8' !default;
$zmdi-var-smartphone-download: '\f2c9' !default;
$zmdi-var-smartphone-erase: '\f2ca' !default;
$zmdi-var-smartphone-info: '\f2cb' !default;
$zmdi-var-smartphone-iphone: '\f2cc' !default;
$zmdi-var-smartphone-landscape-lock: '\f2cd' !default;
$zmdi-var-smartphone-landscape: '\f2ce' !default;
$zmdi-var-smartphone-lock: '\f2cf' !default;
$zmdi-var-smartphone-portrait-lock: '\f2d0' !default;
$zmdi-var-smartphone-ring: '\f2d1' !default;
$zmdi-var-smartphone-setting: '\f2d2' !default;
$zmdi-var-smartphone-setup: '\f2d3' !default;
$zmdi-var-smartphone: '\f2d4' !default;
$zmdi-var-speaker: '\f2d5' !default;
$zmdi-var-tablet-android: '\f2d6' !default;
$zmdi-var-tablet-mac: '\f2d7' !default;
$zmdi-var-tablet: '\f2d8' !default;
$zmdi-var-tv-alt-play: '\f2d9' !default;
$zmdi-var-tv-list: '\f2da' !default;
$zmdi-var-tv-play: '\f2db' !default;
$zmdi-var-tv: '\f2dc' !default;
$zmdi-var-usb: '\f2dd' !default;
$zmdi-var-videocam-off: '\f2de' !default;
$zmdi-var-videocam-switch: '\f2df' !default;
$zmdi-var-videocam: '\f2e0' !default;
$zmdi-var-watch: '\f2e1' !default;
$zmdi-var-wifi-alt-2: '\f2e2' !default;
$zmdi-var-wifi-alt: '\f2e3' !default;
$zmdi-var-wifi-info: '\f2e4' !default;
$zmdi-var-wifi-lock: '\f2e5' !default;
$zmdi-var-wifi-off: '\f2e6' !default;
$zmdi-var-wifi-outline: '\f2e7' !default;
$zmdi-var-wifi: '\f2e8' !default;
$zmdi-var-arrow-left-bottom: '\f2e9' !default;
$zmdi-var-arrow-left: '\f2ea' !default;
$zmdi-var-arrow-merge: '\f2eb' !default;
$zmdi-var-arrow-missed: '\f2ec' !default;
$zmdi-var-arrow-right-top: '\f2ed' !default;
$zmdi-var-arrow-right: '\f2ee' !default;
$zmdi-var-arrow-split: '\f2ef' !default;
$zmdi-var-arrows: '\f2f0' !default;
$zmdi-var-caret-down-circle: '\f2f1' !default;
$zmdi-var-caret-down: '\f2f2' !default;
$zmdi-var-caret-left-circle: '\f2f3' !default;
$zmdi-var-caret-left: '\f2f4' !default;
$zmdi-var-caret-right-circle: '\f2f5' !default;
$zmdi-var-caret-right: '\f2f6' !default;
$zmdi-var-caret-up-circle: '\f2f7' !default;
$zmdi-var-caret-up: '\f2f8' !default;
$zmdi-var-chevron-down: '\f2f9' !default;
$zmdi-var-chevron-left: '\f2fa' !default;
$zmdi-var-chevron-right: '\f2fb' !default;
$zmdi-var-chevron-up: '\f2fc' !default;
$zmdi-var-forward: '\f2fd' !default;
$zmdi-var-long-arrow-down: '\f2fe' !default;
$zmdi-var-long-arrow-left: '\f2ff' !default;
$zmdi-var-long-arrow-return: '\f300' !default;
$zmdi-var-long-arrow-right: '\f301' !default;
$zmdi-var-long-arrow-tab: '\f302' !default;
$zmdi-var-long-arrow-up: '\f303' !default;
$zmdi-var-rotate-ccw: '\f304' !default;
$zmdi-var-rotate-cw: '\f305' !default;
$zmdi-var-rotate-left: '\f306' !default;
$zmdi-var-rotate-right: '\f307' !default;
$zmdi-var-square-down: '\f308' !default;
$zmdi-var-square-right: '\f309' !default;
$zmdi-var-swap-alt: '\f30a' !default;
$zmdi-var-swap-vertical-circle: '\f30b' !default;
$zmdi-var-swap-vertical: '\f30c' !default;
$zmdi-var-swap: '\f30d' !default;
$zmdi-var-trending-down: '\f30e' !default;
$zmdi-var-trending-flat: '\f30f' !default;
$zmdi-var-trending-up: '\f310' !default;
$zmdi-var-unfold-less: '\f311' !default;
$zmdi-var-unfold-more: '\f312' !default;
$zmdi-var-apps: '\f313' !default;
$zmdi-var-grid-off: '\f314' !default;
$zmdi-var-grid: '\f315' !default;
$zmdi-var-view-agenda: '\f316' !default;
$zmdi-var-view-array: '\f317' !default;
$zmdi-var-view-carousel: '\f318' !default;
$zmdi-var-view-column: '\f319' !default;
$zmdi-var-view-comfy: '\f31a' !default;
$zmdi-var-view-compact: '\f31b' !default;
$zmdi-var-view-dashboard: '\f31c' !default;
$zmdi-var-view-day: '\f31d' !default;
$zmdi-var-view-headline: '\f31e' !default;
$zmdi-var-view-list-alt: '\f31f' !default;
$zmdi-var-view-list: '\f320' !default;
$zmdi-var-view-module: '\f321' !default;
$zmdi-var-view-quilt: '\f322' !default;
$zmdi-var-view-stream: '\f323' !default;
$zmdi-var-view-subtitles: '\f324' !default;
$zmdi-var-view-toc: '\f325' !default;
$zmdi-var-view-web: '\f326' !default;
$zmdi-var-view-week: '\f327' !default;
$zmdi-var-widgets: '\f328' !default;
$zmdi-var-alarm-check: '\f329' !default;
$zmdi-var-alarm-off: '\f32a' !default;
$zmdi-var-alarm-plus: '\f32b' !default;
$zmdi-var-alarm-snooze: '\f32c' !default;
$zmdi-var-alarm: '\f32d' !default;
$zmdi-var-calendar-alt: '\f32e' !default;
$zmdi-var-calendar-check: '\f32f' !default;
$zmdi-var-calendar-close: '\f330' !default;
$zmdi-var-calendar-note: '\f331' !default;
$zmdi-var-calendar: '\f332' !default;
$zmdi-var-time-countdown: '\f333' !default;
$zmdi-var-time-interval: '\f334' !default;
$zmdi-var-time-restore-setting: '\f335' !default;
$zmdi-var-time-restore: '\f336' !default;
$zmdi-var-time: '\f337' !default;
$zmdi-var-timer-off: '\f338' !default;
$zmdi-var-timer: '\f339' !default;
$zmdi-var-android-alt: '\f33a' !default;
$zmdi-var-android: '\f33b' !default;
$zmdi-var-apple: '\f33c' !default;
$zmdi-var-behance: '\f33d' !default;
$zmdi-var-codepen: '\f33e' !default;
$zmdi-var-dribbble: '\f33f' !default;
$zmdi-var-dropbox: '\f340' !default;
$zmdi-var-evernote: '\f341' !default;
$zmdi-var-facebook-box: '\f342' !default;
$zmdi-var-facebook: '\f343' !default;
$zmdi-var-github-box: '\f344' !default;
$zmdi-var-github: '\f345' !default;
$zmdi-var-google-drive: '\f346' !default;
$zmdi-var-google-earth: '\f347' !default;
$zmdi-var-google-glass: '\f348' !default;
$zmdi-var-google-maps: '\f349' !default;
$zmdi-var-google-pages: '\f34a' !default;
$zmdi-var-google-play: '\f34b' !default;
$zmdi-var-google-plus-box: '\f34c' !default;
$zmdi-var-google-plus: '\f34d' !default;
$zmdi-var-google: '\f34e' !default;
$zmdi-var-instagram: '\f34f' !default;
$zmdi-var-language-css3: '\f350' !default;
$zmdi-var-language-html5: '\f351' !default;
$zmdi-var-language-javascript: '\f352' !default;
$zmdi-var-language-python-alt: '\f353' !default;
$zmdi-var-language-python: '\f354' !default;
$zmdi-var-lastfm: '\f355' !default;
$zmdi-var-linkedin-box: '\f356' !default;
$zmdi-var-paypal: '\f357' !default;
$zmdi-var-pinterest-box: '\f358' !default;
$zmdi-var-pocket: '\f359' !default;
$zmdi-var-polymer: '\f35a' !default;
$zmdi-var-share: '\f35b' !default;
$zmdi-var-stack-overflow: '\f35c' !default;
$zmdi-var-steam-square: '\f35d' !default;
$zmdi-var-steam: '\f35e' !default;
$zmdi-var-twitter-box: '\f35f' !default;
$zmdi-var-twitter: '\f360' !default;
$zmdi-var-vk: '\f361' !default;
$zmdi-var-wikipedia: '\f362' !default;
$zmdi-var-windows: '\f363' !default;
$zmdi-var-aspect-ratio-alt: '\f364' !default;
$zmdi-var-aspect-ratio: '\f365' !default;
$zmdi-var-blur-circular: '\f366' !default;
$zmdi-var-blur-linear: '\f367' !default;
$zmdi-var-blur-off: '\f368' !default;
$zmdi-var-blur: '\f369' !default;
$zmdi-var-brightness-2: '\f36a' !default;
$zmdi-var-brightness-3: '\f36b' !default;
$zmdi-var-brightness-4: '\f36c' !default;
$zmdi-var-brightness-5: '\f36d' !default;
$zmdi-var-brightness-6: '\f36e' !default;
$zmdi-var-brightness-7: '\f36f' !default;
$zmdi-var-brightness-auto: '\f370' !default;
$zmdi-var-brightness-setting: '\f371' !default;
$zmdi-var-broken-image: '\f372' !default;
$zmdi-var-center-focus-strong: '\f373' !default;
$zmdi-var-center-focus-weak: '\f374' !default;
$zmdi-var-compare: '\f375' !default;
$zmdi-var-crop-16-9: '\f376' !default;
$zmdi-var-crop-3-2: '\f377' !default;
$zmdi-var-crop-5-4: '\f378' !default;
$zmdi-var-crop-7-5: '\f379' !default;
$zmdi-var-crop-din: '\f37a' !default;
$zmdi-var-crop-free: '\f37b' !default;
$zmdi-var-crop-landscape: '\f37c' !default;
$zmdi-var-crop-portrait: '\f37d' !default;
$zmdi-var-crop-square: '\f37e' !default;
$zmdi-var-exposure-alt: '\f37f' !default;
$zmdi-var-exposure: '\f380' !default;
$zmdi-var-filter-b-and-w: '\f381' !default;
$zmdi-var-filter-center-focus: '\f382' !default;
$zmdi-var-filter-frames: '\f383' !default;
$zmdi-var-filter-tilt-shift: '\f384' !default;
$zmdi-var-gradient: '\f385' !default;
$zmdi-var-grain: '\f386' !default;
$zmdi-var-graphic-eq: '\f387' !default;
$zmdi-var-hdr-off: '\f388' !default;
$zmdi-var-hdr-strong: '\f389' !default;
$zmdi-var-hdr-weak: '\f38a' !default;
$zmdi-var-hdr: '\f38b' !default;
$zmdi-var-iridescent: '\f38c' !default;
$zmdi-var-leak-off: '\f38d' !default;
$zmdi-var-leak: '\f38e' !default;
$zmdi-var-looks: '\f38f' !default;
$zmdi-var-loupe: '\f390' !default;
$zmdi-var-panorama-horizontal: '\f391' !default;
$zmdi-var-panorama-vertical: '\f392' !default;
$zmdi-var-panorama-wide-angle: '\f393' !default;
$zmdi-var-photo-size-select-large: '\f394' !default;
$zmdi-var-photo-size-select-small: '\f395' !default;
$zmdi-var-picture-in-picture: '\f396' !default;
$zmdi-var-slideshow: '\f397' !default;
$zmdi-var-texture: '\f398' !default;
$zmdi-var-tonality: '\f399' !default;
$zmdi-var-vignette: '\f39a' !default;
$zmdi-var-wb-auto: '\f39b' !default;
$zmdi-var-eject-alt: '\f39c' !default;
$zmdi-var-eject: '\f39d' !default;
$zmdi-var-equalizer: '\f39e' !default;
$zmdi-var-fast-forward: '\f39f' !default;
$zmdi-var-fast-rewind: '\f3a0' !default;
$zmdi-var-forward-10: '\f3a1' !default;
$zmdi-var-forward-30: '\f3a2' !default;
$zmdi-var-forward-5: '\f3a3' !default;
$zmdi-var-hearing: '\f3a4' !default;
$zmdi-var-pause-circle-outline: '\f3a5' !default;
$zmdi-var-pause-circle: '\f3a6' !default;
$zmdi-var-pause: '\f3a7' !default;
$zmdi-var-play-circle-outline: '\f3a8' !default;
$zmdi-var-play-circle: '\f3a9' !default;
$zmdi-var-play: '\f3aa' !default;
$zmdi-var-playlist-audio: '\f3ab' !default;
$zmdi-var-playlist-plus: '\f3ac' !default;
$zmdi-var-repeat-one: '\f3ad' !default;
$zmdi-var-repeat: '\f3ae' !default;
$zmdi-var-replay-10: '\f3af' !default;
$zmdi-var-replay-30: '\f3b0' !default;
$zmdi-var-replay-5: '\f3b1' !default;
$zmdi-var-replay: '\f3b2' !default;
$zmdi-var-shuffle: '\f3b3' !default;
$zmdi-var-skip-next: '\f3b4' !default;
$zmdi-var-skip-previous: '\f3b5' !default;
$zmdi-var-stop: '\f3b6' !default;
$zmdi-var-surround-sound: '\f3b7' !default;
$zmdi-var-tune: '\f3b8' !default;
$zmdi-var-volume-down: '\f3b9' !default;
$zmdi-var-volume-mute: '\f3ba' !default;
$zmdi-var-volume-off: '\f3bb' !default;
$zmdi-var-volume-up: '\f3bc' !default;
$zmdi-var-n-1-square: '\f3bd' !default;
$zmdi-var-n-2-square: '\f3be' !default;
$zmdi-var-n-3-square: '\f3bf' !default;
$zmdi-var-n-4-square: '\f3c0' !default;
$zmdi-var-n-5-square: '\f3c1' !default;
$zmdi-var-n-6-square: '\f3c2' !default;
$zmdi-var-neg-1: '\f3c3' !default;
$zmdi-var-neg-2: '\f3c4' !default;
$zmdi-var-plus-1: '\f3c5' !default;
$zmdi-var-plus-2: '\f3c6' !default;
$zmdi-var-sec-10: '\f3c7' !default;
$zmdi-var-sec-3: '\f3c8' !default;
$zmdi-var-zero: '\f3c9' !default;
$zmdi-var-airline-seat-flat-angled: '\f3ca' !default;
$zmdi-var-airline-seat-flat: '\f3cb' !default;
$zmdi-var-airline-seat-individual-suite: '\f3cc' !default;
$zmdi-var-airline-seat-legroom-extra: '\f3cd' !default;
$zmdi-var-airline-seat-legroom-normal: '\f3ce' !default;
$zmdi-var-airline-seat-legroom-reduced: '\f3cf' !default;
$zmdi-var-airline-seat-recline-extra: '\f3d0' !default;
$zmdi-var-airline-seat-recline-normal: '\f3d1' !default;
$zmdi-var-airplay: '\f3d2' !default;
$zmdi-var-closed-caption: '\f3d3' !default;
$zmdi-var-confirmation-number: '\f3d4' !default;
$zmdi-var-developer-board: '\f3d5' !default;
$zmdi-var-disc-full: '\f3d6' !default;
$zmdi-var-explicit: '\f3d7' !default;
$zmdi-var-flight-land: '\f3d8' !default;
$zmdi-var-flight-takeoff: '\f3d9' !default;
$zmdi-var-flip-to-back: '\f3da' !default;
$zmdi-var-flip-to-front: '\f3db' !default;
$zmdi-var-group-work: '\f3dc' !default;
$zmdi-var-hd: '\f3dd' !default;
$zmdi-var-hq: '\f3de' !default;
$zmdi-var-markunread-mailbox: '\f3df' !default;
$zmdi-var-memory: '\f3e0' !default;
$zmdi-var-nfc: '\f3e1' !default;
$zmdi-var-play-for-work: '\f3e2' !default;
$zmdi-var-power-input: '\f3e3' !default;
$zmdi-var-present-to-all: '\f3e4' !default;
$zmdi-var-satellite: '\f3e5' !default;
$zmdi-var-tap-and-play: '\f3e6' !default;
$zmdi-var-vibration: '\f3e7' !default;
$zmdi-var-voicemail: '\f3e8' !default;
.#{$zmdi-icon-prefix}-3d-rotation {
  &:before {
    content: $zmdi-var-3d-rotation;
  }
}
.#{$zmdi-icon-prefix}-airplane-off {
  &:before {
    content: $zmdi-var-airplane-off;
  }
}
.#{$zmdi-icon-prefix}-airplane {
  &:before {
    content: $zmdi-var-airplane;
  }
}
.#{$zmdi-icon-prefix}-album {
  &:before {
    content: $zmdi-var-album;
  }
}
.#{$zmdi-icon-prefix}-archive {
  &:before {
    content: $zmdi-var-archive;
  }
}
.#{$zmdi-icon-prefix}-assignment-account {
  &:before {
    content: $zmdi-var-assignment-account;
  }
}
.#{$zmdi-icon-prefix}-assignment-alert {
  &:before {
    content: $zmdi-var-assignment-alert;
  }
}
.#{$zmdi-icon-prefix}-assignment-check {
  &:before {
    content: $zmdi-var-assignment-check;
  }
}
.#{$zmdi-icon-prefix}-assignment-o {
  &:before {
    content: $zmdi-var-assignment-o;
  }
}
.#{$zmdi-icon-prefix}-assignment-return {
  &:before {
    content: $zmdi-var-assignment-return;
  }
}
.#{$zmdi-icon-prefix}-assignment-returned {
  &:before {
    content: $zmdi-var-assignment-returned;
  }
}
.#{$zmdi-icon-prefix}-assignment {
  &:before {
    content: $zmdi-var-assignment;
  }
}
.#{$zmdi-icon-prefix}-attachment-alt {
  &:before {
    content: $zmdi-var-attachment-alt;
  }
}
.#{$zmdi-icon-prefix}-attachment {
  &:before {
    content: $zmdi-var-attachment;
  }
}
.#{$zmdi-icon-prefix}-audio {
  &:before {
    content: $zmdi-var-audio;
  }
}
.#{$zmdi-icon-prefix}-badge-check {
  &:before {
    content: $zmdi-var-badge-check;
  }
}
.#{$zmdi-icon-prefix}-balance-wallet {
  &:before {
    content: $zmdi-var-balance-wallet;
  }
}
.#{$zmdi-icon-prefix}-balance {
  &:before {
    content: $zmdi-var-balance;
  }
}
.#{$zmdi-icon-prefix}-battery-alert {
  &:before {
    content: $zmdi-var-battery-alert;
  }
}
.#{$zmdi-icon-prefix}-battery-flash {
  &:before {
    content: $zmdi-var-battery-flash;
  }
}
.#{$zmdi-icon-prefix}-battery-unknown {
  &:before {
    content: $zmdi-var-battery-unknown;
  }
}
.#{$zmdi-icon-prefix}-battery {
  &:before {
    content: $zmdi-var-battery;
  }
}
.#{$zmdi-icon-prefix}-bike {
  &:before {
    content: $zmdi-var-bike;
  }
}
.#{$zmdi-icon-prefix}-block-alt {
  &:before {
    content: $zmdi-var-block-alt;
  }
}
.#{$zmdi-icon-prefix}-block {
  &:before {
    content: $zmdi-var-block;
  }
}
.#{$zmdi-icon-prefix}-boat {
  &:before {
    content: $zmdi-var-boat;
  }
}
.#{$zmdi-icon-prefix}-book-image {
  &:before {
    content: $zmdi-var-book-image;
  }
}
.#{$zmdi-icon-prefix}-book {
  &:before {
    content: $zmdi-var-book;
  }
}
.#{$zmdi-icon-prefix}-bookmark-outline {
  &:before {
    content: $zmdi-var-bookmark-outline;
  }
}
.#{$zmdi-icon-prefix}-bookmark {
  &:before {
    content: $zmdi-var-bookmark;
  }
}
.#{$zmdi-icon-prefix}-brush {
  &:before {
    content: $zmdi-var-brush;
  }
}
.#{$zmdi-icon-prefix}-bug {
  &:before {
    content: $zmdi-var-bug;
  }
}
.#{$zmdi-icon-prefix}-bus {
  &:before {
    content: $zmdi-var-bus;
  }
}
.#{$zmdi-icon-prefix}-cake {
  &:before {
    content: $zmdi-var-cake;
  }
}
.#{$zmdi-icon-prefix}-car-taxi {
  &:before {
    content: $zmdi-var-car-taxi;
  }
}
.#{$zmdi-icon-prefix}-car-wash {
  &:before {
    content: $zmdi-var-car-wash;
  }
}
.#{$zmdi-icon-prefix}-car {
  &:before {
    content: $zmdi-var-car;
  }
}
.#{$zmdi-icon-prefix}-card-giftcard {
  &:before {
    content: $zmdi-var-card-giftcard;
  }
}
.#{$zmdi-icon-prefix}-card-membership {
  &:before {
    content: $zmdi-var-card-membership;
  }
}
.#{$zmdi-icon-prefix}-card-travel {
  &:before {
    content: $zmdi-var-card-travel;
  }
}
.#{$zmdi-icon-prefix}-card {
  &:before {
    content: $zmdi-var-card;
  }
}
.#{$zmdi-icon-prefix}-case-check {
  &:before {
    content: $zmdi-var-case-check;
  }
}
.#{$zmdi-icon-prefix}-case-download {
  &:before {
    content: $zmdi-var-case-download;
  }
}
.#{$zmdi-icon-prefix}-case-play {
  &:before {
    content: $zmdi-var-case-play;
  }
}
.#{$zmdi-icon-prefix}-case {
  &:before {
    content: $zmdi-var-case;
  }
}
.#{$zmdi-icon-prefix}-cast-connected {
  &:before {
    content: $zmdi-var-cast-connected;
  }
}
.#{$zmdi-icon-prefix}-cast {
  &:before {
    content: $zmdi-var-cast;
  }
}
.#{$zmdi-icon-prefix}-chart-donut {
  &:before {
    content: $zmdi-var-chart-donut;
  }
}
.#{$zmdi-icon-prefix}-chart {
  &:before {
    content: $zmdi-var-chart;
  }
}
.#{$zmdi-icon-prefix}-city-alt {
  &:before {
    content: $zmdi-var-city-alt;
  }
}
.#{$zmdi-icon-prefix}-city {
  &:before {
    content: $zmdi-var-city;
  }
}
.#{$zmdi-icon-prefix}-close-circle-o {
  &:before {
    content: $zmdi-var-close-circle-o;
  }
}
.#{$zmdi-icon-prefix}-close-circle {
  &:before {
    content: $zmdi-var-close-circle;
  }
}
.#{$zmdi-icon-prefix}-close {
  &:before {
    content: $zmdi-var-close;
  }
}
.#{$zmdi-icon-prefix}-cocktail {
  &:before {
    content: $zmdi-var-cocktail;
  }
}
.#{$zmdi-icon-prefix}-code-setting {
  &:before {
    content: $zmdi-var-code-setting;
  }
}
.#{$zmdi-icon-prefix}-code-smartphone {
  &:before {
    content: $zmdi-var-code-smartphone;
  }
}
.#{$zmdi-icon-prefix}-code {
  &:before {
    content: $zmdi-var-code;
  }
}
.#{$zmdi-icon-prefix}-coffee {
  &:before {
    content: $zmdi-var-coffee;
  }
}
.#{$zmdi-icon-prefix}-collection-bookmark {
  &:before {
    content: $zmdi-var-collection-bookmark;
  }
}
.#{$zmdi-icon-prefix}-collection-case-play {
  &:before {
    content: $zmdi-var-collection-case-play;
  }
}
.#{$zmdi-icon-prefix}-collection-folder-image {
  &:before {
    content: $zmdi-var-collection-folder-image;
  }
}
.#{$zmdi-icon-prefix}-collection-image-o {
  &:before {
    content: $zmdi-var-collection-image-o;
  }
}
.#{$zmdi-icon-prefix}-collection-image {
  &:before {
    content: $zmdi-var-collection-image;
  }
}
.#{$zmdi-icon-prefix}-collection-item-1 {
  &:before {
    content: $zmdi-var-collection-item-1;
  }
}
.#{$zmdi-icon-prefix}-collection-item-2 {
  &:before {
    content: $zmdi-var-collection-item-2;
  }
}
.#{$zmdi-icon-prefix}-collection-item-3 {
  &:before {
    content: $zmdi-var-collection-item-3;
  }
}
.#{$zmdi-icon-prefix}-collection-item-4 {
  &:before {
    content: $zmdi-var-collection-item-4;
  }
}
.#{$zmdi-icon-prefix}-collection-item-5 {
  &:before {
    content: $zmdi-var-collection-item-5;
  }
}
.#{$zmdi-icon-prefix}-collection-item-6 {
  &:before {
    content: $zmdi-var-collection-item-6;
  }
}
.#{$zmdi-icon-prefix}-collection-item-7 {
  &:before {
    content: $zmdi-var-collection-item-7;
  }
}
.#{$zmdi-icon-prefix}-collection-item-8 {
  &:before {
    content: $zmdi-var-collection-item-8;
  }
}
.#{$zmdi-icon-prefix}-collection-item-9-plus {
  &:before {
    content: $zmdi-var-collection-item-9-plus;
  }
}
.#{$zmdi-icon-prefix}-collection-item-9 {
  &:before {
    content: $zmdi-var-collection-item-9;
  }
}
.#{$zmdi-icon-prefix}-collection-item {
  &:before {
    content: $zmdi-var-collection-item;
  }
}
.#{$zmdi-icon-prefix}-collection-music {
  &:before {
    content: $zmdi-var-collection-music;
  }
}
.#{$zmdi-icon-prefix}-collection-pdf {
  &:before {
    content: $zmdi-var-collection-pdf;
  }
}
.#{$zmdi-icon-prefix}-collection-plus {
  &:before {
    content: $zmdi-var-collection-plus;
  }
}
.#{$zmdi-icon-prefix}-collection-speaker {
  &:before {
    content: $zmdi-var-collection-speaker;
  }
}
.#{$zmdi-icon-prefix}-collection-text {
  &:before {
    content: $zmdi-var-collection-text;
  }
}
.#{$zmdi-icon-prefix}-collection-video {
  &:before {
    content: $zmdi-var-collection-video;
  }
}
.#{$zmdi-icon-prefix}-compass {
  &:before {
    content: $zmdi-var-compass;
  }
}
.#{$zmdi-icon-prefix}-cutlery {
  &:before {
    content: $zmdi-var-cutlery;
  }
}
.#{$zmdi-icon-prefix}-delete {
  &:before {
    content: $zmdi-var-delete;
  }
}
.#{$zmdi-icon-prefix}-dialpad {
  &:before {
    content: $zmdi-var-dialpad;
  }
}
.#{$zmdi-icon-prefix}-dns {
  &:before {
    content: $zmdi-var-dns;
  }
}
.#{$zmdi-icon-prefix}-drink {
  &:before {
    content: $zmdi-var-drink;
  }
}
.#{$zmdi-icon-prefix}-edit {
  &:before {
    content: $zmdi-var-edit;
  }
}
.#{$zmdi-icon-prefix}-email-open {
  &:before {
    content: $zmdi-var-email-open;
  }
}
.#{$zmdi-icon-prefix}-email {
  &:before {
    content: $zmdi-var-email;
  }
}
.#{$zmdi-icon-prefix}-eye-off {
  &:before {
    content: $zmdi-var-eye-off;
  }
}
.#{$zmdi-icon-prefix}-eye {
  &:before {
    content: $zmdi-var-eye;
  }
}
.#{$zmdi-icon-prefix}-eyedropper {
  &:before {
    content: $zmdi-var-eyedropper;
  }
}
.#{$zmdi-icon-prefix}-favorite-outline {
  &:before {
    content: $zmdi-var-favorite-outline;
  }
}
.#{$zmdi-icon-prefix}-favorite {
  &:before {
    content: $zmdi-var-favorite;
  }
}
.#{$zmdi-icon-prefix}-filter-list {
  &:before {
    content: $zmdi-var-filter-list;
  }
}
.#{$zmdi-icon-prefix}-fire {
  &:before {
    content: $zmdi-var-fire;
  }
}
.#{$zmdi-icon-prefix}-flag {
  &:before {
    content: $zmdi-var-flag;
  }
}
.#{$zmdi-icon-prefix}-flare {
  &:before {
    content: $zmdi-var-flare;
  }
}
.#{$zmdi-icon-prefix}-flash-auto {
  &:before {
    content: $zmdi-var-flash-auto;
  }
}
.#{$zmdi-icon-prefix}-flash-off {
  &:before {
    content: $zmdi-var-flash-off;
  }
}
.#{$zmdi-icon-prefix}-flash {
  &:before {
    content: $zmdi-var-flash;
  }
}
.#{$zmdi-icon-prefix}-flip {
  &:before {
    content: $zmdi-var-flip;
  }
}
.#{$zmdi-icon-prefix}-flower-alt {
  &:before {
    content: $zmdi-var-flower-alt;
  }
}
.#{$zmdi-icon-prefix}-flower {
  &:before {
    content: $zmdi-var-flower;
  }
}
.#{$zmdi-icon-prefix}-font {
  &:before {
    content: $zmdi-var-font;
  }
}
.#{$zmdi-icon-prefix}-fullscreen-alt {
  &:before {
    content: $zmdi-var-fullscreen-alt;
  }
}
.#{$zmdi-icon-prefix}-fullscreen-exit {
  &:before {
    content: $zmdi-var-fullscreen-exit;
  }
}
.#{$zmdi-icon-prefix}-fullscreen {
  &:before {
    content: $zmdi-var-fullscreen;
  }
}
.#{$zmdi-icon-prefix}-functions {
  &:before {
    content: $zmdi-var-functions;
  }
}
.#{$zmdi-icon-prefix}-gas-station {
  &:before {
    content: $zmdi-var-gas-station;
  }
}
.#{$zmdi-icon-prefix}-gesture {
  &:before {
    content: $zmdi-var-gesture;
  }
}
.#{$zmdi-icon-prefix}-globe-alt {
  &:before {
    content: $zmdi-var-globe-alt;
  }
}
.#{$zmdi-icon-prefix}-globe-lock {
  &:before {
    content: $zmdi-var-globe-lock;
  }
}
.#{$zmdi-icon-prefix}-globe {
  &:before {
    content: $zmdi-var-globe;
  }
}
.#{$zmdi-icon-prefix}-graduation-cap {
  &:before {
    content: $zmdi-var-graduation-cap;
  }
}
.#{$zmdi-icon-prefix}-home {
  &:before {
    content: $zmdi-var-home;
  }
}
.#{$zmdi-icon-prefix}-hospital-alt {
  &:before {
    content: $zmdi-var-hospital-alt;
  }
}
.#{$zmdi-icon-prefix}-hospital {
  &:before {
    content: $zmdi-var-hospital;
  }
}
.#{$zmdi-icon-prefix}-hotel {
  &:before {
    content: $zmdi-var-hotel;
  }
}
.#{$zmdi-icon-prefix}-hourglass-alt {
  &:before {
    content: $zmdi-var-hourglass-alt;
  }
}
.#{$zmdi-icon-prefix}-hourglass-outline {
  &:before {
    content: $zmdi-var-hourglass-outline;
  }
}
.#{$zmdi-icon-prefix}-hourglass {
  &:before {
    content: $zmdi-var-hourglass;
  }
}
.#{$zmdi-icon-prefix}-http {
  &:before {
    content: $zmdi-var-http;
  }
}
.#{$zmdi-icon-prefix}-image-alt {
  &:before {
    content: $zmdi-var-image-alt;
  }
}
.#{$zmdi-icon-prefix}-image-o {
  &:before {
    content: $zmdi-var-image-o;
  }
}
.#{$zmdi-icon-prefix}-image {
  &:before {
    content: $zmdi-var-image;
  }
}
.#{$zmdi-icon-prefix}-inbox {
  &:before {
    content: $zmdi-var-inbox;
  }
}
.#{$zmdi-icon-prefix}-invert-colors-off {
  &:before {
    content: $zmdi-var-invert-colors-off;
  }
}
.#{$zmdi-icon-prefix}-invert-colors {
  &:before {
    content: $zmdi-var-invert-colors;
  }
}
.#{$zmdi-icon-prefix}-key {
  &:before {
    content: $zmdi-var-key;
  }
}
.#{$zmdi-icon-prefix}-label-alt-outline {
  &:before {
    content: $zmdi-var-label-alt-outline;
  }
}
.#{$zmdi-icon-prefix}-label-alt {
  &:before {
    content: $zmdi-var-label-alt;
  }
}
.#{$zmdi-icon-prefix}-label-heart {
  &:before {
    content: $zmdi-var-label-heart;
  }
}
.#{$zmdi-icon-prefix}-label {
  &:before {
    content: $zmdi-var-label;
  }
}
.#{$zmdi-icon-prefix}-labels {
  &:before {
    content: $zmdi-var-labels;
  }
}
.#{$zmdi-icon-prefix}-lamp {
  &:before {
    content: $zmdi-var-lamp;
  }
}
.#{$zmdi-icon-prefix}-landscape {
  &:before {
    content: $zmdi-var-landscape;
  }
}
.#{$zmdi-icon-prefix}-layers-off {
  &:before {
    content: $zmdi-var-layers-off;
  }
}
.#{$zmdi-icon-prefix}-layers {
  &:before {
    content: $zmdi-var-layers;
  }
}
.#{$zmdi-icon-prefix}-library {
  &:before {
    content: $zmdi-var-library;
  }
}
.#{$zmdi-icon-prefix}-link {
  &:before {
    content: $zmdi-var-link;
  }
}
.#{$zmdi-icon-prefix}-lock-open {
  &:before {
    content: $zmdi-var-lock-open;
  }
}
.#{$zmdi-icon-prefix}-lock-outline {
  &:before {
    content: $zmdi-var-lock-outline;
  }
}
.#{$zmdi-icon-prefix}-lock {
  &:before {
    content: $zmdi-var-lock;
  }
}
.#{$zmdi-icon-prefix}-mail-reply-all {
  &:before {
    content: $zmdi-var-mail-reply-all;
  }
}
.#{$zmdi-icon-prefix}-mail-reply {
  &:before {
    content: $zmdi-var-mail-reply;
  }
}
.#{$zmdi-icon-prefix}-mail-send {
  &:before {
    content: $zmdi-var-mail-send;
  }
}
.#{$zmdi-icon-prefix}-mall {
  &:before {
    content: $zmdi-var-mall;
  }
}
.#{$zmdi-icon-prefix}-map {
  &:before {
    content: $zmdi-var-map;
  }
}
.#{$zmdi-icon-prefix}-menu {
  &:before {
    content: $zmdi-var-menu;
  }
}
.#{$zmdi-icon-prefix}-money-box {
  &:before {
    content: $zmdi-var-money-box;
  }
}
.#{$zmdi-icon-prefix}-money-off {
  &:before {
    content: $zmdi-var-money-off;
  }
}
.#{$zmdi-icon-prefix}-money {
  &:before {
    content: $zmdi-var-money;
  }
}
.#{$zmdi-icon-prefix}-more-vert {
  &:before {
    content: $zmdi-var-more-vert;
  }
}
.#{$zmdi-icon-prefix}-more {
  &:before {
    content: $zmdi-var-more;
  }
}
.#{$zmdi-icon-prefix}-movie-alt {
  &:before {
    content: $zmdi-var-movie-alt;
  }
}
.#{$zmdi-icon-prefix}-movie {
  &:before {
    content: $zmdi-var-movie;
  }
}
.#{$zmdi-icon-prefix}-nature-people {
  &:before {
    content: $zmdi-var-nature-people;
  }
}
.#{$zmdi-icon-prefix}-nature {
  &:before {
    content: $zmdi-var-nature;
  }
}
.#{$zmdi-icon-prefix}-navigation {
  &:before {
    content: $zmdi-var-navigation;
  }
}
.#{$zmdi-icon-prefix}-open-in-browser {
  &:before {
    content: $zmdi-var-open-in-browser;
  }
}
.#{$zmdi-icon-prefix}-open-in-new {
  &:before {
    content: $zmdi-var-open-in-new;
  }
}
.#{$zmdi-icon-prefix}-palette {
  &:before {
    content: $zmdi-var-palette;
  }
}
.#{$zmdi-icon-prefix}-parking {
  &:before {
    content: $zmdi-var-parking;
  }
}
.#{$zmdi-icon-prefix}-pin-account {
  &:before {
    content: $zmdi-var-pin-account;
  }
}
.#{$zmdi-icon-prefix}-pin-assistant {
  &:before {
    content: $zmdi-var-pin-assistant;
  }
}
.#{$zmdi-icon-prefix}-pin-drop {
  &:before {
    content: $zmdi-var-pin-drop;
  }
}
.#{$zmdi-icon-prefix}-pin-help {
  &:before {
    content: $zmdi-var-pin-help;
  }
}
.#{$zmdi-icon-prefix}-pin-off {
  &:before {
    content: $zmdi-var-pin-off;
  }
}
.#{$zmdi-icon-prefix}-pin {
  &:before {
    content: $zmdi-var-pin;
  }
}
.#{$zmdi-icon-prefix}-pizza {
  &:before {
    content: $zmdi-var-pizza;
  }
}
.#{$zmdi-icon-prefix}-plaster {
  &:before {
    content: $zmdi-var-plaster;
  }
}
.#{$zmdi-icon-prefix}-power-setting {
  &:before {
    content: $zmdi-var-power-setting;
  }
}
.#{$zmdi-icon-prefix}-power {
  &:before {
    content: $zmdi-var-power;
  }
}
.#{$zmdi-icon-prefix}-print {
  &:before {
    content: $zmdi-var-print;
  }
}
.#{$zmdi-icon-prefix}-puzzle-piece {
  &:before {
    content: $zmdi-var-puzzle-piece;
  }
}
.#{$zmdi-icon-prefix}-quote {
  &:before {
    content: $zmdi-var-quote;
  }
}
.#{$zmdi-icon-prefix}-railway {
  &:before {
    content: $zmdi-var-railway;
  }
}
.#{$zmdi-icon-prefix}-receipt {
  &:before {
    content: $zmdi-var-receipt;
  }
}
.#{$zmdi-icon-prefix}-refresh-alt {
  &:before {
    content: $zmdi-var-refresh-alt;
  }
}
.#{$zmdi-icon-prefix}-refresh-sync-alert {
  &:before {
    content: $zmdi-var-refresh-sync-alert;
  }
}
.#{$zmdi-icon-prefix}-refresh-sync-off {
  &:before {
    content: $zmdi-var-refresh-sync-off;
  }
}
.#{$zmdi-icon-prefix}-refresh-sync {
  &:before {
    content: $zmdi-var-refresh-sync;
  }
}
.#{$zmdi-icon-prefix}-refresh {
  &:before {
    content: $zmdi-var-refresh;
  }
}
.#{$zmdi-icon-prefix}-roller {
  &:before {
    content: $zmdi-var-roller;
  }
}
.#{$zmdi-icon-prefix}-ruler {
  &:before {
    content: $zmdi-var-ruler;
  }
}
.#{$zmdi-icon-prefix}-scissors {
  &:before {
    content: $zmdi-var-scissors;
  }
}
.#{$zmdi-icon-prefix}-screen-rotation-lock {
  &:before {
    content: $zmdi-var-screen-rotation-lock;
  }
}
.#{$zmdi-icon-prefix}-screen-rotation {
  &:before {
    content: $zmdi-var-screen-rotation;
  }
}
.#{$zmdi-icon-prefix}-search-for {
  &:before {
    content: $zmdi-var-search-for;
  }
}
.#{$zmdi-icon-prefix}-search-in-file {
  &:before {
    content: $zmdi-var-search-in-file;
  }
}
.#{$zmdi-icon-prefix}-search-in-page {
  &:before {
    content: $zmdi-var-search-in-page;
  }
}
.#{$zmdi-icon-prefix}-search-replace {
  &:before {
    content: $zmdi-var-search-replace;
  }
}
.#{$zmdi-icon-prefix}-search {
  &:before {
    content: $zmdi-var-search;
  }
}
.#{$zmdi-icon-prefix}-seat {
  &:before {
    content: $zmdi-var-seat;
  }
}
.#{$zmdi-icon-prefix}-settings-square {
  &:before {
    content: $zmdi-var-settings-square;
  }
}
.#{$zmdi-icon-prefix}-settings {
  &:before {
    content: $zmdi-var-settings;
  }
}
.#{$zmdi-icon-prefix}-shield-check {
  &:before {
    content: $zmdi-var-shield-check;
  }
}
.#{$zmdi-icon-prefix}-shield-security {
  &:before {
    content: $zmdi-var-shield-security;
  }
}
.#{$zmdi-icon-prefix}-shopping-basket {
  &:before {
    content: $zmdi-var-shopping-basket;
  }
}
.#{$zmdi-icon-prefix}-shopping-cart-plus {
  &:before {
    content: $zmdi-var-shopping-cart-plus;
  }
}
.#{$zmdi-icon-prefix}-shopping-cart {
  &:before {
    content: $zmdi-var-shopping-cart;
  }
}
.#{$zmdi-icon-prefix}-sign-in {
  &:before {
    content: $zmdi-var-sign-in;
  }
}
.#{$zmdi-icon-prefix}-sort-amount-asc {
  &:before {
    content: $zmdi-var-sort-amount-asc;
  }
}
.#{$zmdi-icon-prefix}-sort-amount-desc {
  &:before {
    content: $zmdi-var-sort-amount-desc;
  }
}
.#{$zmdi-icon-prefix}-sort-asc {
  &:before {
    content: $zmdi-var-sort-asc;
  }
}
.#{$zmdi-icon-prefix}-sort-desc {
  &:before {
    content: $zmdi-var-sort-desc;
  }
}
.#{$zmdi-icon-prefix}-spellcheck {
  &:before {
    content: $zmdi-var-spellcheck;
  }
}
.#{$zmdi-icon-prefix}-storage {
  &:before {
    content: $zmdi-var-storage;
  }
}
.#{$zmdi-icon-prefix}-store-24 {
  &:before {
    content: $zmdi-var-store-24;
  }
}
.#{$zmdi-icon-prefix}-store {
  &:before {
    content: $zmdi-var-store;
  }
}
.#{$zmdi-icon-prefix}-subway {
  &:before {
    content: $zmdi-var-subway;
  }
}
.#{$zmdi-icon-prefix}-sun {
  &:before {
    content: $zmdi-var-sun;
  }
}
.#{$zmdi-icon-prefix}-tab-unselected {
  &:before {
    content: $zmdi-var-tab-unselected;
  }
}
.#{$zmdi-icon-prefix}-tab {
  &:before {
    content: $zmdi-var-tab;
  }
}
.#{$zmdi-icon-prefix}-tag-close {
  &:before {
    content: $zmdi-var-tag-close;
  }
}
.#{$zmdi-icon-prefix}-tag-more {
  &:before {
    content: $zmdi-var-tag-more;
  }
}
.#{$zmdi-icon-prefix}-tag {
  &:before {
    content: $zmdi-var-tag;
  }
}
.#{$zmdi-icon-prefix}-thumb-down {
  &:before {
    content: $zmdi-var-thumb-down;
  }
}
.#{$zmdi-icon-prefix}-thumb-up-down {
  &:before {
    content: $zmdi-var-thumb-up-down;
  }
}
.#{$zmdi-icon-prefix}-thumb-up {
  &:before {
    content: $zmdi-var-thumb-up;
  }
}
.#{$zmdi-icon-prefix}-ticket-star {
  &:before {
    content: $zmdi-var-ticket-star;
  }
}
.#{$zmdi-icon-prefix}-toll {
  &:before {
    content: $zmdi-var-toll;
  }
}
.#{$zmdi-icon-prefix}-toys {
  &:before {
    content: $zmdi-var-toys;
  }
}
.#{$zmdi-icon-prefix}-traffic {
  &:before {
    content: $zmdi-var-traffic;
  }
}
.#{$zmdi-icon-prefix}-translate {
  &:before {
    content: $zmdi-var-translate;
  }
}
.#{$zmdi-icon-prefix}-triangle-down {
  &:before {
    content: $zmdi-var-triangle-down;
  }
}
.#{$zmdi-icon-prefix}-triangle-up {
  &:before {
    content: $zmdi-var-triangle-up;
  }
}
.#{$zmdi-icon-prefix}-truck {
  &:before {
    content: $zmdi-var-truck;
  }
}
.#{$zmdi-icon-prefix}-turning-sign {
  &:before {
    content: $zmdi-var-turning-sign;
  }
}
.#{$zmdi-icon-prefix}-wallpaper {
  &:before {
    content: $zmdi-var-wallpaper;
  }
}
.#{$zmdi-icon-prefix}-washing-machine {
  &:before {
    content: $zmdi-var-washing-machine;
  }
}
.#{$zmdi-icon-prefix}-window-maximize {
  &:before {
    content: $zmdi-var-window-maximize;
  }
}
.#{$zmdi-icon-prefix}-window-minimize {
  &:before {
    content: $zmdi-var-window-minimize;
  }
}
.#{$zmdi-icon-prefix}-window-restore {
  &:before {
    content: $zmdi-var-window-restore;
  }
}
.#{$zmdi-icon-prefix}-wrench {
  &:before {
    content: $zmdi-var-wrench;
  }
}
.#{$zmdi-icon-prefix}-zoom-in {
  &:before {
    content: $zmdi-var-zoom-in;
  }
}
.#{$zmdi-icon-prefix}-zoom-out {
  &:before {
    content: $zmdi-var-zoom-out;
  }
}
.#{$zmdi-icon-prefix}-alert-circle-o {
  &:before {
    content: $zmdi-var-alert-circle-o;
  }
}
.#{$zmdi-icon-prefix}-alert-circle {
  &:before {
    content: $zmdi-var-alert-circle;
  }
}
.#{$zmdi-icon-prefix}-alert-octagon {
  &:before {
    content: $zmdi-var-alert-octagon;
  }
}
.#{$zmdi-icon-prefix}-alert-polygon {
  &:before {
    content: $zmdi-var-alert-polygon;
  }
}
.#{$zmdi-icon-prefix}-alert-triangle {
  &:before {
    content: $zmdi-var-alert-triangle;
  }
}
.#{$zmdi-icon-prefix}-help-outline {
  &:before {
    content: $zmdi-var-help-outline;
  }
}
.#{$zmdi-icon-prefix}-help {
  &:before {
    content: $zmdi-var-help;
  }
}
.#{$zmdi-icon-prefix}-info-outline {
  &:before {
    content: $zmdi-var-info-outline;
  }
}
.#{$zmdi-icon-prefix}-info {
  &:before {
    content: $zmdi-var-info;
  }
}
.#{$zmdi-icon-prefix}-notifications-active {
  &:before {
    content: $zmdi-var-notifications-active;
  }
}
.#{$zmdi-icon-prefix}-notifications-add {
  &:before {
    content: $zmdi-var-notifications-add;
  }
}
.#{$zmdi-icon-prefix}-notifications-none {
  &:before {
    content: $zmdi-var-notifications-none;
  }
}
.#{$zmdi-icon-prefix}-notifications-off {
  &:before {
    content: $zmdi-var-notifications-off;
  }
}
.#{$zmdi-icon-prefix}-notifications-paused {
  &:before {
    content: $zmdi-var-notifications-paused;
  }
}
.#{$zmdi-icon-prefix}-notifications {
  &:before {
    content: $zmdi-var-notifications;
  }
}
.#{$zmdi-icon-prefix}-account-add {
  &:before {
    content: $zmdi-var-account-add;
  }
}
.#{$zmdi-icon-prefix}-account-box-mail {
  &:before {
    content: $zmdi-var-account-box-mail;
  }
}
.#{$zmdi-icon-prefix}-account-box-o {
  &:before {
    content: $zmdi-var-account-box-o;
  }
}
.#{$zmdi-icon-prefix}-account-box-phone {
  &:before {
    content: $zmdi-var-account-box-phone;
  }
}
.#{$zmdi-icon-prefix}-account-box {
  &:before {
    content: $zmdi-var-account-box;
  }
}
.#{$zmdi-icon-prefix}-account-calendar {
  &:before {
    content: $zmdi-var-account-calendar;
  }
}
.#{$zmdi-icon-prefix}-account-circle {
  &:before {
    content: $zmdi-var-account-circle;
  }
}
.#{$zmdi-icon-prefix}-account-o {
  &:before {
    content: $zmdi-var-account-o;
  }
}
.#{$zmdi-icon-prefix}-account {
  &:before {
    content: $zmdi-var-account;
  }
}
.#{$zmdi-icon-prefix}-accounts-add {
  &:before {
    content: $zmdi-var-accounts-add;
  }
}
.#{$zmdi-icon-prefix}-accounts-alt {
  &:before {
    content: $zmdi-var-accounts-alt;
  }
}
.#{$zmdi-icon-prefix}-accounts-list-alt {
  &:before {
    content: $zmdi-var-accounts-list-alt;
  }
}
.#{$zmdi-icon-prefix}-accounts-list {
  &:before {
    content: $zmdi-var-accounts-list;
  }
}
.#{$zmdi-icon-prefix}-accounts-outline {
  &:before {
    content: $zmdi-var-accounts-outline;
  }
}
.#{$zmdi-icon-prefix}-accounts {
  &:before {
    content: $zmdi-var-accounts;
  }
}
.#{$zmdi-icon-prefix}-face {
  &:before {
    content: $zmdi-var-face;
  }
}
.#{$zmdi-icon-prefix}-female {
  &:before {
    content: $zmdi-var-female;
  }
}
.#{$zmdi-icon-prefix}-male-alt {
  &:before {
    content: $zmdi-var-male-alt;
  }
}
.#{$zmdi-icon-prefix}-male-female {
  &:before {
    content: $zmdi-var-male-female;
  }
}
.#{$zmdi-icon-prefix}-male {
  &:before {
    content: $zmdi-var-male;
  }
}
.#{$zmdi-icon-prefix}-mood-bad {
  &:before {
    content: $zmdi-var-mood-bad;
  }
}
.#{$zmdi-icon-prefix}-mood {
  &:before {
    content: $zmdi-var-mood;
  }
}
.#{$zmdi-icon-prefix}-run {
  &:before {
    content: $zmdi-var-run;
  }
}
.#{$zmdi-icon-prefix}-walk {
  &:before {
    content: $zmdi-var-walk;
  }
}
.#{$zmdi-icon-prefix}-cloud-box {
  &:before {
    content: $zmdi-var-cloud-box;
  }
}
.#{$zmdi-icon-prefix}-cloud-circle {
  &:before {
    content: $zmdi-var-cloud-circle;
  }
}
.#{$zmdi-icon-prefix}-cloud-done {
  &:before {
    content: $zmdi-var-cloud-done;
  }
}
.#{$zmdi-icon-prefix}-cloud-download {
  &:before {
    content: $zmdi-var-cloud-download;
  }
}
.#{$zmdi-icon-prefix}-cloud-off {
  &:before {
    content: $zmdi-var-cloud-off;
  }
}
.#{$zmdi-icon-prefix}-cloud-outline-alt {
  &:before {
    content: $zmdi-var-cloud-outline-alt;
  }
}
.#{$zmdi-icon-prefix}-cloud-outline {
  &:before {
    content: $zmdi-var-cloud-outline;
  }
}
.#{$zmdi-icon-prefix}-cloud-upload {
  &:before {
    content: $zmdi-var-cloud-upload;
  }
}
.#{$zmdi-icon-prefix}-cloud {
  &:before {
    content: $zmdi-var-cloud;
  }
}
.#{$zmdi-icon-prefix}-download {
  &:before {
    content: $zmdi-var-download;
  }
}
.#{$zmdi-icon-prefix}-file-plus {
  &:before {
    content: $zmdi-var-file-plus;
  }
}
.#{$zmdi-icon-prefix}-file-text {
  &:before {
    content: $zmdi-var-file-text;
  }
}
.#{$zmdi-icon-prefix}-file {
  &:before {
    content: $zmdi-var-file;
  }
}
.#{$zmdi-icon-prefix}-folder-outline {
  &:before {
    content: $zmdi-var-folder-outline;
  }
}
.#{$zmdi-icon-prefix}-folder-person {
  &:before {
    content: $zmdi-var-folder-person;
  }
}
.#{$zmdi-icon-prefix}-folder-star-alt {
  &:before {
    content: $zmdi-var-folder-star-alt;
  }
}
.#{$zmdi-icon-prefix}-folder-star {
  &:before {
    content: $zmdi-var-folder-star;
  }
}
.#{$zmdi-icon-prefix}-folder {
  &:before {
    content: $zmdi-var-folder;
  }
}
.#{$zmdi-icon-prefix}-gif {
  &:before {
    content: $zmdi-var-gif;
  }
}
.#{$zmdi-icon-prefix}-upload {
  &:before {
    content: $zmdi-var-upload;
  }
}
.#{$zmdi-icon-prefix}-border-all {
  &:before {
    content: $zmdi-var-border-all;
  }
}
.#{$zmdi-icon-prefix}-border-bottom {
  &:before {
    content: $zmdi-var-border-bottom;
  }
}
.#{$zmdi-icon-prefix}-border-clear {
  &:before {
    content: $zmdi-var-border-clear;
  }
}
.#{$zmdi-icon-prefix}-border-color {
  &:before {
    content: $zmdi-var-border-color;
  }
}
.#{$zmdi-icon-prefix}-border-horizontal {
  &:before {
    content: $zmdi-var-border-horizontal;
  }
}
.#{$zmdi-icon-prefix}-border-inner {
  &:before {
    content: $zmdi-var-border-inner;
  }
}
.#{$zmdi-icon-prefix}-border-left {
  &:before {
    content: $zmdi-var-border-left;
  }
}
.#{$zmdi-icon-prefix}-border-outer {
  &:before {
    content: $zmdi-var-border-outer;
  }
}
.#{$zmdi-icon-prefix}-border-right {
  &:before {
    content: $zmdi-var-border-right;
  }
}
.#{$zmdi-icon-prefix}-border-style {
  &:before {
    content: $zmdi-var-border-style;
  }
}
.#{$zmdi-icon-prefix}-border-top {
  &:before {
    content: $zmdi-var-border-top;
  }
}
.#{$zmdi-icon-prefix}-border-vertical {
  &:before {
    content: $zmdi-var-border-vertical;
  }
}
.#{$zmdi-icon-prefix}-copy {
  &:before {
    content: $zmdi-var-copy;
  }
}
.#{$zmdi-icon-prefix}-crop {
  &:before {
    content: $zmdi-var-crop;
  }
}
.#{$zmdi-icon-prefix}-format-align-center {
  &:before {
    content: $zmdi-var-format-align-center;
  }
}
.#{$zmdi-icon-prefix}-format-align-justify {
  &:before {
    content: $zmdi-var-format-align-justify;
  }
}
.#{$zmdi-icon-prefix}-format-align-left {
  &:before {
    content: $zmdi-var-format-align-left;
  }
}
.#{$zmdi-icon-prefix}-format-align-right {
  &:before {
    content: $zmdi-var-format-align-right;
  }
}
.#{$zmdi-icon-prefix}-format-bold {
  &:before {
    content: $zmdi-var-format-bold;
  }
}
.#{$zmdi-icon-prefix}-format-clear-all {
  &:before {
    content: $zmdi-var-format-clear-all;
  }
}
.#{$zmdi-icon-prefix}-format-clear {
  &:before {
    content: $zmdi-var-format-clear;
  }
}
.#{$zmdi-icon-prefix}-format-color-fill {
  &:before {
    content: $zmdi-var-format-color-fill;
  }
}
.#{$zmdi-icon-prefix}-format-color-reset {
  &:before {
    content: $zmdi-var-format-color-reset;
  }
}
.#{$zmdi-icon-prefix}-format-color-text {
  &:before {
    content: $zmdi-var-format-color-text;
  }
}
.#{$zmdi-icon-prefix}-format-indent-decrease {
  &:before {
    content: $zmdi-var-format-indent-decrease;
  }
}
.#{$zmdi-icon-prefix}-format-indent-increase {
  &:before {
    content: $zmdi-var-format-indent-increase;
  }
}
.#{$zmdi-icon-prefix}-format-italic {
  &:before {
    content: $zmdi-var-format-italic;
  }
}
.#{$zmdi-icon-prefix}-format-line-spacing {
  &:before {
    content: $zmdi-var-format-line-spacing;
  }
}
.#{$zmdi-icon-prefix}-format-list-bulleted {
  &:before {
    content: $zmdi-var-format-list-bulleted;
  }
}
.#{$zmdi-icon-prefix}-format-list-numbered {
  &:before {
    content: $zmdi-var-format-list-numbered;
  }
}
.#{$zmdi-icon-prefix}-format-ltr {
  &:before {
    content: $zmdi-var-format-ltr;
  }
}
.#{$zmdi-icon-prefix}-format-rtl {
  &:before {
    content: $zmdi-var-format-rtl;
  }
}
.#{$zmdi-icon-prefix}-format-size {
  &:before {
    content: $zmdi-var-format-size;
  }
}
.#{$zmdi-icon-prefix}-format-strikethrough-s {
  &:before {
    content: $zmdi-var-format-strikethrough-s;
  }
}
.#{$zmdi-icon-prefix}-format-strikethrough {
  &:before {
    content: $zmdi-var-format-strikethrough;
  }
}
.#{$zmdi-icon-prefix}-format-subject {
  &:before {
    content: $zmdi-var-format-subject;
  }
}
.#{$zmdi-icon-prefix}-format-underlined {
  &:before {
    content: $zmdi-var-format-underlined;
  }
}
.#{$zmdi-icon-prefix}-format-valign-bottom {
  &:before {
    content: $zmdi-var-format-valign-bottom;
  }
}
.#{$zmdi-icon-prefix}-format-valign-center {
  &:before {
    content: $zmdi-var-format-valign-center;
  }
}
.#{$zmdi-icon-prefix}-format-valign-top {
  &:before {
    content: $zmdi-var-format-valign-top;
  }
}
.#{$zmdi-icon-prefix}-redo {
  &:before {
    content: $zmdi-var-redo;
  }
}
.#{$zmdi-icon-prefix}-select-all {
  &:before {
    content: $zmdi-var-select-all;
  }
}
.#{$zmdi-icon-prefix}-space-bar {
  &:before {
    content: $zmdi-var-space-bar;
  }
}
.#{$zmdi-icon-prefix}-text-format {
  &:before {
    content: $zmdi-var-text-format;
  }
}
.#{$zmdi-icon-prefix}-transform {
  &:before {
    content: $zmdi-var-transform;
  }
}
.#{$zmdi-icon-prefix}-undo {
  &:before {
    content: $zmdi-var-undo;
  }
}
.#{$zmdi-icon-prefix}-wrap-text {
  &:before {
    content: $zmdi-var-wrap-text;
  }
}
.#{$zmdi-icon-prefix}-comment-alert {
  &:before {
    content: $zmdi-var-comment-alert;
  }
}
.#{$zmdi-icon-prefix}-comment-alt-text {
  &:before {
    content: $zmdi-var-comment-alt-text;
  }
}
.#{$zmdi-icon-prefix}-comment-alt {
  &:before {
    content: $zmdi-var-comment-alt;
  }
}
.#{$zmdi-icon-prefix}-comment-edit {
  &:before {
    content: $zmdi-var-comment-edit;
  }
}
.#{$zmdi-icon-prefix}-comment-image {
  &:before {
    content: $zmdi-var-comment-image;
  }
}
.#{$zmdi-icon-prefix}-comment-list {
  &:before {
    content: $zmdi-var-comment-list;
  }
}
.#{$zmdi-icon-prefix}-comment-more {
  &:before {
    content: $zmdi-var-comment-more;
  }
}
.#{$zmdi-icon-prefix}-comment-outline {
  &:before {
    content: $zmdi-var-comment-outline;
  }
}
.#{$zmdi-icon-prefix}-comment-text-alt {
  &:before {
    content: $zmdi-var-comment-text-alt;
  }
}
.#{$zmdi-icon-prefix}-comment-text {
  &:before {
    content: $zmdi-var-comment-text;
  }
}
.#{$zmdi-icon-prefix}-comment-video {
  &:before {
    content: $zmdi-var-comment-video;
  }
}
.#{$zmdi-icon-prefix}-comment {
  &:before {
    content: $zmdi-var-comment;
  }
}
.#{$zmdi-icon-prefix}-comments {
  &:before {
    content: $zmdi-var-comments;
  }
}
.#{$zmdi-icon-prefix}-check-all {
  &:before {
    content: $zmdi-var-check-all;
  }
}
.#{$zmdi-icon-prefix}-check-circle-u {
  &:before {
    content: $zmdi-var-check-circle-u;
  }
}
.#{$zmdi-icon-prefix}-check-circle {
  &:before {
    content: $zmdi-var-check-circle;
  }
}
.#{$zmdi-icon-prefix}-check-square {
  &:before {
    content: $zmdi-var-check-square;
  }
}
.#{$zmdi-icon-prefix}-check {
  &:before {
    content: $zmdi-var-check;
  }
}
.#{$zmdi-icon-prefix}-circle-o {
  &:before {
    content: $zmdi-var-circle-o;
  }
}
.#{$zmdi-icon-prefix}-circle {
  &:before {
    content: $zmdi-var-circle;
  }
}
.#{$zmdi-icon-prefix}-dot-circle-alt {
  &:before {
    content: $zmdi-var-dot-circle-alt;
  }
}
.#{$zmdi-icon-prefix}-dot-circle {
  &:before {
    content: $zmdi-var-dot-circle;
  }
}
.#{$zmdi-icon-prefix}-minus-circle-outline {
  &:before {
    content: $zmdi-var-minus-circle-outline;
  }
}
.#{$zmdi-icon-prefix}-minus-circle {
  &:before {
    content: $zmdi-var-minus-circle;
  }
}
.#{$zmdi-icon-prefix}-minus-square {
  &:before {
    content: $zmdi-var-minus-square;
  }
}
.#{$zmdi-icon-prefix}-minus {
  &:before {
    content: $zmdi-var-minus;
  }
}
.#{$zmdi-icon-prefix}-plus-circle-o-duplicate {
  &:before {
    content: $zmdi-var-plus-circle-o-duplicate;
  }
}
.#{$zmdi-icon-prefix}-plus-circle-o {
  &:before {
    content: $zmdi-var-plus-circle-o;
  }
}
.#{$zmdi-icon-prefix}-plus-circle {
  &:before {
    content: $zmdi-var-plus-circle;
  }
}
.#{$zmdi-icon-prefix}-plus-square {
  &:before {
    content: $zmdi-var-plus-square;
  }
}
.#{$zmdi-icon-prefix}-plus {
  &:before {
    content: $zmdi-var-plus;
  }
}
.#{$zmdi-icon-prefix}-square-o {
  &:before {
    content: $zmdi-var-square-o;
  }
}
.#{$zmdi-icon-prefix}-star-circle {
  &:before {
    content: $zmdi-var-star-circle;
  }
}
.#{$zmdi-icon-prefix}-star-half {
  &:before {
    content: $zmdi-var-star-half;
  }
}
.#{$zmdi-icon-prefix}-star-outline {
  &:before {
    content: $zmdi-var-star-outline;
  }
}
.#{$zmdi-icon-prefix}-star {
  &:before {
    content: $zmdi-var-star;
  }
}
.#{$zmdi-icon-prefix}-bluetooth-connected {
  &:before {
    content: $zmdi-var-bluetooth-connected;
  }
}
.#{$zmdi-icon-prefix}-bluetooth-off {
  &:before {
    content: $zmdi-var-bluetooth-off;
  }
}
.#{$zmdi-icon-prefix}-bluetooth-search {
  &:before {
    content: $zmdi-var-bluetooth-search;
  }
}
.#{$zmdi-icon-prefix}-bluetooth-setting {
  &:before {
    content: $zmdi-var-bluetooth-setting;
  }
}
.#{$zmdi-icon-prefix}-bluetooth {
  &:before {
    content: $zmdi-var-bluetooth;
  }
}
.#{$zmdi-icon-prefix}-camera-add {
  &:before {
    content: $zmdi-var-camera-add;
  }
}
.#{$zmdi-icon-prefix}-camera-alt {
  &:before {
    content: $zmdi-var-camera-alt;
  }
}
.#{$zmdi-icon-prefix}-camera-bw {
  &:before {
    content: $zmdi-var-camera-bw;
  }
}
.#{$zmdi-icon-prefix}-camera-front {
  &:before {
    content: $zmdi-var-camera-front;
  }
}
.#{$zmdi-icon-prefix}-camera-mic {
  &:before {
    content: $zmdi-var-camera-mic;
  }
}
.#{$zmdi-icon-prefix}-camera-party-mode {
  &:before {
    content: $zmdi-var-camera-party-mode;
  }
}
.#{$zmdi-icon-prefix}-camera-rear {
  &:before {
    content: $zmdi-var-camera-rear;
  }
}
.#{$zmdi-icon-prefix}-camera-roll {
  &:before {
    content: $zmdi-var-camera-roll;
  }
}
.#{$zmdi-icon-prefix}-camera-switch {
  &:before {
    content: $zmdi-var-camera-switch;
  }
}
.#{$zmdi-icon-prefix}-camera {
  &:before {
    content: $zmdi-var-camera;
  }
}
.#{$zmdi-icon-prefix}-card-alert {
  &:before {
    content: $zmdi-var-card-alert;
  }
}
.#{$zmdi-icon-prefix}-card-off {
  &:before {
    content: $zmdi-var-card-off;
  }
}
.#{$zmdi-icon-prefix}-card-sd {
  &:before {
    content: $zmdi-var-card-sd;
  }
}
.#{$zmdi-icon-prefix}-card-sim {
  &:before {
    content: $zmdi-var-card-sim;
  }
}
.#{$zmdi-icon-prefix}-desktop-mac {
  &:before {
    content: $zmdi-var-desktop-mac;
  }
}
.#{$zmdi-icon-prefix}-desktop-windows {
  &:before {
    content: $zmdi-var-desktop-windows;
  }
}
.#{$zmdi-icon-prefix}-device-hub {
  &:before {
    content: $zmdi-var-device-hub;
  }
}
.#{$zmdi-icon-prefix}-devices-off {
  &:before {
    content: $zmdi-var-devices-off;
  }
}
.#{$zmdi-icon-prefix}-devices {
  &:before {
    content: $zmdi-var-devices;
  }
}
.#{$zmdi-icon-prefix}-dock {
  &:before {
    content: $zmdi-var-dock;
  }
}
.#{$zmdi-icon-prefix}-floppy {
  &:before {
    content: $zmdi-var-floppy;
  }
}
.#{$zmdi-icon-prefix}-gamepad {
  &:before {
    content: $zmdi-var-gamepad;
  }
}
.#{$zmdi-icon-prefix}-gps-dot {
  &:before {
    content: $zmdi-var-gps-dot;
  }
}
.#{$zmdi-icon-prefix}-gps-off {
  &:before {
    content: $zmdi-var-gps-off;
  }
}
.#{$zmdi-icon-prefix}-gps {
  &:before {
    content: $zmdi-var-gps;
  }
}
.#{$zmdi-icon-prefix}-headset-mic {
  &:before {
    content: $zmdi-var-headset-mic;
  }
}
.#{$zmdi-icon-prefix}-headset {
  &:before {
    content: $zmdi-var-headset;
  }
}
.#{$zmdi-icon-prefix}-input-antenna {
  &:before {
    content: $zmdi-var-input-antenna;
  }
}
.#{$zmdi-icon-prefix}-input-composite {
  &:before {
    content: $zmdi-var-input-composite;
  }
}
.#{$zmdi-icon-prefix}-input-hdmi {
  &:before {
    content: $zmdi-var-input-hdmi;
  }
}
.#{$zmdi-icon-prefix}-input-power {
  &:before {
    content: $zmdi-var-input-power;
  }
}
.#{$zmdi-icon-prefix}-input-svideo {
  &:before {
    content: $zmdi-var-input-svideo;
  }
}
.#{$zmdi-icon-prefix}-keyboard-hide {
  &:before {
    content: $zmdi-var-keyboard-hide;
  }
}
.#{$zmdi-icon-prefix}-keyboard {
  &:before {
    content: $zmdi-var-keyboard;
  }
}
.#{$zmdi-icon-prefix}-laptop-chromebook {
  &:before {
    content: $zmdi-var-laptop-chromebook;
  }
}
.#{$zmdi-icon-prefix}-laptop-mac {
  &:before {
    content: $zmdi-var-laptop-mac;
  }
}
.#{$zmdi-icon-prefix}-laptop {
  &:before {
    content: $zmdi-var-laptop;
  }
}
.#{$zmdi-icon-prefix}-mic-off {
  &:before {
    content: $zmdi-var-mic-off;
  }
}
.#{$zmdi-icon-prefix}-mic-outline {
  &:before {
    content: $zmdi-var-mic-outline;
  }
}
.#{$zmdi-icon-prefix}-mic-setting {
  &:before {
    content: $zmdi-var-mic-setting;
  }
}
.#{$zmdi-icon-prefix}-mic {
  &:before {
    content: $zmdi-var-mic;
  }
}
.#{$zmdi-icon-prefix}-mouse {
  &:before {
    content: $zmdi-var-mouse;
  }
}
.#{$zmdi-icon-prefix}-network-alert {
  &:before {
    content: $zmdi-var-network-alert;
  }
}
.#{$zmdi-icon-prefix}-network-locked {
  &:before {
    content: $zmdi-var-network-locked;
  }
}
.#{$zmdi-icon-prefix}-network-off {
  &:before {
    content: $zmdi-var-network-off;
  }
}
.#{$zmdi-icon-prefix}-network-outline {
  &:before {
    content: $zmdi-var-network-outline;
  }
}
.#{$zmdi-icon-prefix}-network-setting {
  &:before {
    content: $zmdi-var-network-setting;
  }
}
.#{$zmdi-icon-prefix}-network {
  &:before {
    content: $zmdi-var-network;
  }
}
.#{$zmdi-icon-prefix}-phone-bluetooth {
  &:before {
    content: $zmdi-var-phone-bluetooth;
  }
}
.#{$zmdi-icon-prefix}-phone-end {
  &:before {
    content: $zmdi-var-phone-end;
  }
}
.#{$zmdi-icon-prefix}-phone-forwarded {
  &:before {
    content: $zmdi-var-phone-forwarded;
  }
}
.#{$zmdi-icon-prefix}-phone-in-talk {
  &:before {
    content: $zmdi-var-phone-in-talk;
  }
}
.#{$zmdi-icon-prefix}-phone-locked {
  &:before {
    content: $zmdi-var-phone-locked;
  }
}
.#{$zmdi-icon-prefix}-phone-missed {
  &:before {
    content: $zmdi-var-phone-missed;
  }
}
.#{$zmdi-icon-prefix}-phone-msg {
  &:before {
    content: $zmdi-var-phone-msg;
  }
}
.#{$zmdi-icon-prefix}-phone-paused {
  &:before {
    content: $zmdi-var-phone-paused;
  }
}
.#{$zmdi-icon-prefix}-phone-ring {
  &:before {
    content: $zmdi-var-phone-ring;
  }
}
.#{$zmdi-icon-prefix}-phone-setting {
  &:before {
    content: $zmdi-var-phone-setting;
  }
}
.#{$zmdi-icon-prefix}-phone-sip {
  &:before {
    content: $zmdi-var-phone-sip;
  }
}
.#{$zmdi-icon-prefix}-phone {
  &:before {
    content: $zmdi-var-phone;
  }
}
.#{$zmdi-icon-prefix}-portable-wifi-changes {
  &:before {
    content: $zmdi-var-portable-wifi-changes;
  }
}
.#{$zmdi-icon-prefix}-portable-wifi-off {
  &:before {
    content: $zmdi-var-portable-wifi-off;
  }
}
.#{$zmdi-icon-prefix}-portable-wifi {
  &:before {
    content: $zmdi-var-portable-wifi;
  }
}
.#{$zmdi-icon-prefix}-radio {
  &:before {
    content: $zmdi-var-radio;
  }
}
.#{$zmdi-icon-prefix}-reader {
  &:before {
    content: $zmdi-var-reader;
  }
}
.#{$zmdi-icon-prefix}-remote-control-alt {
  &:before {
    content: $zmdi-var-remote-control-alt;
  }
}
.#{$zmdi-icon-prefix}-remote-control {
  &:before {
    content: $zmdi-var-remote-control;
  }
}
.#{$zmdi-icon-prefix}-router {
  &:before {
    content: $zmdi-var-router;
  }
}
.#{$zmdi-icon-prefix}-scanner {
  &:before {
    content: $zmdi-var-scanner;
  }
}
.#{$zmdi-icon-prefix}-smartphone-android {
  &:before {
    content: $zmdi-var-smartphone-android;
  }
}
.#{$zmdi-icon-prefix}-smartphone-download {
  &:before {
    content: $zmdi-var-smartphone-download;
  }
}
.#{$zmdi-icon-prefix}-smartphone-erase {
  &:before {
    content: $zmdi-var-smartphone-erase;
  }
}
.#{$zmdi-icon-prefix}-smartphone-info {
  &:before {
    content: $zmdi-var-smartphone-info;
  }
}
.#{$zmdi-icon-prefix}-smartphone-iphone {
  &:before {
    content: $zmdi-var-smartphone-iphone;
  }
}
.#{$zmdi-icon-prefix}-smartphone-landscape-lock {
  &:before {
    content: $zmdi-var-smartphone-landscape-lock;
  }
}
.#{$zmdi-icon-prefix}-smartphone-landscape {
  &:before {
    content: $zmdi-var-smartphone-landscape;
  }
}
.#{$zmdi-icon-prefix}-smartphone-lock {
  &:before {
    content: $zmdi-var-smartphone-lock;
  }
}
.#{$zmdi-icon-prefix}-smartphone-portrait-lock {
  &:before {
    content: $zmdi-var-smartphone-portrait-lock;
  }
}
.#{$zmdi-icon-prefix}-smartphone-ring {
  &:before {
    content: $zmdi-var-smartphone-ring;
  }
}
.#{$zmdi-icon-prefix}-smartphone-setting {
  &:before {
    content: $zmdi-var-smartphone-setting;
  }
}
.#{$zmdi-icon-prefix}-smartphone-setup {
  &:before {
    content: $zmdi-var-smartphone-setup;
  }
}
.#{$zmdi-icon-prefix}-smartphone {
  &:before {
    content: $zmdi-var-smartphone;
  }
}
.#{$zmdi-icon-prefix}-speaker {
  &:before {
    content: $zmdi-var-speaker;
  }
}
.#{$zmdi-icon-prefix}-tablet-android {
  &:before {
    content: $zmdi-var-tablet-android;
  }
}
.#{$zmdi-icon-prefix}-tablet-mac {
  &:before {
    content: $zmdi-var-tablet-mac;
  }
}
.#{$zmdi-icon-prefix}-tablet {
  &:before {
    content: $zmdi-var-tablet;
  }
}
.#{$zmdi-icon-prefix}-tv-alt-play {
  &:before {
    content: $zmdi-var-tv-alt-play;
  }
}
.#{$zmdi-icon-prefix}-tv-list {
  &:before {
    content: $zmdi-var-tv-list;
  }
}
.#{$zmdi-icon-prefix}-tv-play {
  &:before {
    content: $zmdi-var-tv-play;
  }
}
.#{$zmdi-icon-prefix}-tv {
  &:before {
    content: $zmdi-var-tv;
  }
}
.#{$zmdi-icon-prefix}-usb {
  &:before {
    content: $zmdi-var-usb;
  }
}
.#{$zmdi-icon-prefix}-videocam-off {
  &:before {
    content: $zmdi-var-videocam-off;
  }
}
.#{$zmdi-icon-prefix}-videocam-switch {
  &:before {
    content: $zmdi-var-videocam-switch;
  }
}
.#{$zmdi-icon-prefix}-videocam {
  &:before {
    content: $zmdi-var-videocam;
  }
}
.#{$zmdi-icon-prefix}-watch {
  &:before {
    content: $zmdi-var-watch;
  }
}
.#{$zmdi-icon-prefix}-wifi-alt-2 {
  &:before {
    content: $zmdi-var-wifi-alt-2;
  }
}
.#{$zmdi-icon-prefix}-wifi-alt {
  &:before {
    content: $zmdi-var-wifi-alt;
  }
}
.#{$zmdi-icon-prefix}-wifi-info {
  &:before {
    content: $zmdi-var-wifi-info;
  }
}
.#{$zmdi-icon-prefix}-wifi-lock {
  &:before {
    content: $zmdi-var-wifi-lock;
  }
}
.#{$zmdi-icon-prefix}-wifi-off {
  &:before {
    content: $zmdi-var-wifi-off;
  }
}
.#{$zmdi-icon-prefix}-wifi-outline {
  &:before {
    content: $zmdi-var-wifi-outline;
  }
}
.#{$zmdi-icon-prefix}-wifi {
  &:before {
    content: $zmdi-var-wifi;
  }
}
.#{$zmdi-icon-prefix}-arrow-left-bottom {
  &:before {
    content: $zmdi-var-arrow-left-bottom;
  }
}
.#{$zmdi-icon-prefix}-arrow-left {
  &:before {
    content: $zmdi-var-arrow-left;
  }
}
.#{$zmdi-icon-prefix}-arrow-merge {
  &:before {
    content: $zmdi-var-arrow-merge;
  }
}
.#{$zmdi-icon-prefix}-arrow-missed {
  &:before {
    content: $zmdi-var-arrow-missed;
  }
}
.#{$zmdi-icon-prefix}-arrow-right-top {
  &:before {
    content: $zmdi-var-arrow-right-top;
  }
}
.#{$zmdi-icon-prefix}-arrow-right {
  &:before {
    content: $zmdi-var-arrow-right;
  }
}
.#{$zmdi-icon-prefix}-arrow-split {
  &:before {
    content: $zmdi-var-arrow-split;
  }
}
.#{$zmdi-icon-prefix}-arrows {
  &:before {
    content: $zmdi-var-arrows;
  }
}
.#{$zmdi-icon-prefix}-caret-down-circle {
  &:before {
    content: $zmdi-var-caret-down-circle;
  }
}
.#{$zmdi-icon-prefix}-caret-down {
  &:before {
    content: $zmdi-var-caret-down;
  }
}
.#{$zmdi-icon-prefix}-caret-left-circle {
  &:before {
    content: $zmdi-var-caret-left-circle;
  }
}
.#{$zmdi-icon-prefix}-caret-left {
  &:before {
    content: $zmdi-var-caret-left;
  }
}
.#{$zmdi-icon-prefix}-caret-right-circle {
  &:before {
    content: $zmdi-var-caret-right-circle;
  }
}
.#{$zmdi-icon-prefix}-caret-right {
  &:before {
    content: $zmdi-var-caret-right;
  }
}
.#{$zmdi-icon-prefix}-caret-up-circle {
  &:before {
    content: $zmdi-var-caret-up-circle;
  }
}
.#{$zmdi-icon-prefix}-caret-up {
  &:before {
    content: $zmdi-var-caret-up;
  }
}
.#{$zmdi-icon-prefix}-chevron-down {
  &:before {
    content: $zmdi-var-chevron-down;
  }
}
.#{$zmdi-icon-prefix}-chevron-left {
  &:before {
    content: $zmdi-var-chevron-left;
  }
}
.#{$zmdi-icon-prefix}-chevron-right {
  &:before {
    content: $zmdi-var-chevron-right;
  }
}
.#{$zmdi-icon-prefix}-chevron-up {
  &:before {
    content: $zmdi-var-chevron-up;
  }
}
.#{$zmdi-icon-prefix}-forward {
  &:before {
    content: $zmdi-var-forward;
  }
}
.#{$zmdi-icon-prefix}-long-arrow-down {
  &:before {
    content: $zmdi-var-long-arrow-down;
  }
}
.#{$zmdi-icon-prefix}-long-arrow-left {
  &:before {
    content: $zmdi-var-long-arrow-left;
  }
}
.#{$zmdi-icon-prefix}-long-arrow-return {
  &:before {
    content: $zmdi-var-long-arrow-return;
  }
}
.#{$zmdi-icon-prefix}-long-arrow-right {
  &:before {
    content: $zmdi-var-long-arrow-right;
  }
}
.#{$zmdi-icon-prefix}-long-arrow-tab {
  &:before {
    content: $zmdi-var-long-arrow-tab;
  }
}
.#{$zmdi-icon-prefix}-long-arrow-up {
  &:before {
    content: $zmdi-var-long-arrow-up;
  }
}
.#{$zmdi-icon-prefix}-rotate-ccw {
  &:before {
    content: $zmdi-var-rotate-ccw;
  }
}
.#{$zmdi-icon-prefix}-rotate-cw {
  &:before {
    content: $zmdi-var-rotate-cw;
  }
}
.#{$zmdi-icon-prefix}-rotate-left {
  &:before {
    content: $zmdi-var-rotate-left;
  }
}
.#{$zmdi-icon-prefix}-rotate-right {
  &:before {
    content: $zmdi-var-rotate-right;
  }
}
.#{$zmdi-icon-prefix}-square-down {
  &:before {
    content: $zmdi-var-square-down;
  }
}
.#{$zmdi-icon-prefix}-square-right {
  &:before {
    content: $zmdi-var-square-right;
  }
}
.#{$zmdi-icon-prefix}-swap-alt {
  &:before {
    content: $zmdi-var-swap-alt;
  }
}
.#{$zmdi-icon-prefix}-swap-vertical-circle {
  &:before {
    content: $zmdi-var-swap-vertical-circle;
  }
}
.#{$zmdi-icon-prefix}-swap-vertical {
  &:before {
    content: $zmdi-var-swap-vertical;
  }
}
.#{$zmdi-icon-prefix}-swap {
  &:before {
    content: $zmdi-var-swap;
  }
}
.#{$zmdi-icon-prefix}-trending-down {
  &:before {
    content: $zmdi-var-trending-down;
  }
}
.#{$zmdi-icon-prefix}-trending-flat {
  &:before {
    content: $zmdi-var-trending-flat;
  }
}
.#{$zmdi-icon-prefix}-trending-up {
  &:before {
    content: $zmdi-var-trending-up;
  }
}
.#{$zmdi-icon-prefix}-unfold-less {
  &:before {
    content: $zmdi-var-unfold-less;
  }
}
.#{$zmdi-icon-prefix}-unfold-more {
  &:before {
    content: $zmdi-var-unfold-more;
  }
}
.#{$zmdi-icon-prefix}-apps {
  &:before {
    content: $zmdi-var-apps;
  }
}
.#{$zmdi-icon-prefix}-grid-off {
  &:before {
    content: $zmdi-var-grid-off;
  }
}
.#{$zmdi-icon-prefix}-grid {
  &:before {
    content: $zmdi-var-grid;
  }
}
.#{$zmdi-icon-prefix}-view-agenda {
  &:before {
    content: $zmdi-var-view-agenda;
  }
}
.#{$zmdi-icon-prefix}-view-array {
  &:before {
    content: $zmdi-var-view-array;
  }
}
.#{$zmdi-icon-prefix}-view-carousel {
  &:before {
    content: $zmdi-var-view-carousel;
  }
}
.#{$zmdi-icon-prefix}-view-column {
  &:before {
    content: $zmdi-var-view-column;
  }
}
.#{$zmdi-icon-prefix}-view-comfy {
  &:before {
    content: $zmdi-var-view-comfy;
  }
}
.#{$zmdi-icon-prefix}-view-compact {
  &:before {
    content: $zmdi-var-view-compact;
  }
}
.#{$zmdi-icon-prefix}-view-dashboard {
  &:before {
    content: $zmdi-var-view-dashboard;
  }
}
.#{$zmdi-icon-prefix}-view-day {
  &:before {
    content: $zmdi-var-view-day;
  }
}
.#{$zmdi-icon-prefix}-view-headline {
  &:before {
    content: $zmdi-var-view-headline;
  }
}
.#{$zmdi-icon-prefix}-view-list-alt {
  &:before {
    content: $zmdi-var-view-list-alt;
  }
}
.#{$zmdi-icon-prefix}-view-list {
  &:before {
    content: $zmdi-var-view-list;
  }
}
.#{$zmdi-icon-prefix}-view-module {
  &:before {
    content: $zmdi-var-view-module;
  }
}
.#{$zmdi-icon-prefix}-view-quilt {
  &:before {
    content: $zmdi-var-view-quilt;
  }
}
.#{$zmdi-icon-prefix}-view-stream {
  &:before {
    content: $zmdi-var-view-stream;
  }
}
.#{$zmdi-icon-prefix}-view-subtitles {
  &:before {
    content: $zmdi-var-view-subtitles;
  }
}
.#{$zmdi-icon-prefix}-view-toc {
  &:before {
    content: $zmdi-var-view-toc;
  }
}
.#{$zmdi-icon-prefix}-view-web {
  &:before {
    content: $zmdi-var-view-web;
  }
}
.#{$zmdi-icon-prefix}-view-week {
  &:before {
    content: $zmdi-var-view-week;
  }
}
.#{$zmdi-icon-prefix}-widgets {
  &:before {
    content: $zmdi-var-widgets;
  }
}
.#{$zmdi-icon-prefix}-alarm-check {
  &:before {
    content: $zmdi-var-alarm-check;
  }
}
.#{$zmdi-icon-prefix}-alarm-off {
  &:before {
    content: $zmdi-var-alarm-off;
  }
}
.#{$zmdi-icon-prefix}-alarm-plus {
  &:before {
    content: $zmdi-var-alarm-plus;
  }
}
.#{$zmdi-icon-prefix}-alarm-snooze {
  &:before {
    content: $zmdi-var-alarm-snooze;
  }
}
.#{$zmdi-icon-prefix}-alarm {
  &:before {
    content: $zmdi-var-alarm;
  }
}
.#{$zmdi-icon-prefix}-calendar-alt {
  &:before {
    content: $zmdi-var-calendar-alt;
  }
}
.#{$zmdi-icon-prefix}-calendar-check {
  &:before {
    content: $zmdi-var-calendar-check;
  }
}
.#{$zmdi-icon-prefix}-calendar-close {
  &:before {
    content: $zmdi-var-calendar-close;
  }
}
.#{$zmdi-icon-prefix}-calendar-note {
  &:before {
    content: $zmdi-var-calendar-note;
  }
}
.#{$zmdi-icon-prefix}-calendar {
  &:before {
    content: $zmdi-var-calendar;
  }
}
.#{$zmdi-icon-prefix}-time-countdown {
  &:before {
    content: $zmdi-var-time-countdown;
  }
}
.#{$zmdi-icon-prefix}-time-interval {
  &:before {
    content: $zmdi-var-time-interval;
  }
}
.#{$zmdi-icon-prefix}-time-restore-setting {
  &:before {
    content: $zmdi-var-time-restore-setting;
  }
}
.#{$zmdi-icon-prefix}-time-restore {
  &:before {
    content: $zmdi-var-time-restore;
  }
}
.#{$zmdi-icon-prefix}-time {
  &:before {
    content: $zmdi-var-time;
  }
}
.#{$zmdi-icon-prefix}-timer-off {
  &:before {
    content: $zmdi-var-timer-off;
  }
}
.#{$zmdi-icon-prefix}-timer {
  &:before {
    content: $zmdi-var-timer;
  }
}
.#{$zmdi-icon-prefix}-android-alt {
  &:before {
    content: $zmdi-var-android-alt;
  }
}
.#{$zmdi-icon-prefix}-android {
  &:before {
    content: $zmdi-var-android;
  }
}
.#{$zmdi-icon-prefix}-apple {
  &:before {
    content: $zmdi-var-apple;
  }
}
.#{$zmdi-icon-prefix}-behance {
  &:before {
    content: $zmdi-var-behance;
  }
}
.#{$zmdi-icon-prefix}-codepen {
  &:before {
    content: $zmdi-var-codepen;
  }
}
.#{$zmdi-icon-prefix}-dribbble {
  &:before {
    content: $zmdi-var-dribbble;
  }
}
.#{$zmdi-icon-prefix}-dropbox {
  &:before {
    content: $zmdi-var-dropbox;
  }
}
.#{$zmdi-icon-prefix}-evernote {
  &:before {
    content: $zmdi-var-evernote;
  }
}
.#{$zmdi-icon-prefix}-facebook-box {
  &:before {
    content: $zmdi-var-facebook-box;
  }
}
.#{$zmdi-icon-prefix}-facebook {
  &:before {
    content: $zmdi-var-facebook;
  }
}
.#{$zmdi-icon-prefix}-github-box {
  &:before {
    content: $zmdi-var-github-box;
  }
}
.#{$zmdi-icon-prefix}-github {
  &:before {
    content: $zmdi-var-github;
  }
}
.#{$zmdi-icon-prefix}-google-drive {
  &:before {
    content: $zmdi-var-google-drive;
  }
}
.#{$zmdi-icon-prefix}-google-earth {
  &:before {
    content: $zmdi-var-google-earth;
  }
}
.#{$zmdi-icon-prefix}-google-glass {
  &:before {
    content: $zmdi-var-google-glass;
  }
}
.#{$zmdi-icon-prefix}-google-maps {
  &:before {
    content: $zmdi-var-google-maps;
  }
}
.#{$zmdi-icon-prefix}-google-pages {
  &:before {
    content: $zmdi-var-google-pages;
  }
}
.#{$zmdi-icon-prefix}-google-play {
  &:before {
    content: $zmdi-var-google-play;
  }
}
.#{$zmdi-icon-prefix}-google-plus-box {
  &:before {
    content: $zmdi-var-google-plus-box;
  }
}
.#{$zmdi-icon-prefix}-google-plus {
  &:before {
    content: $zmdi-var-google-plus;
  }
}
.#{$zmdi-icon-prefix}-google {
  &:before {
    content: $zmdi-var-google;
  }
}
.#{$zmdi-icon-prefix}-instagram {
  &:before {
    content: $zmdi-var-instagram;
  }
}
.#{$zmdi-icon-prefix}-language-css3 {
  &:before {
    content: $zmdi-var-language-css3;
  }
}
.#{$zmdi-icon-prefix}-language-html5 {
  &:before {
    content: $zmdi-var-language-html5;
  }
}
.#{$zmdi-icon-prefix}-language-javascript {
  &:before {
    content: $zmdi-var-language-javascript;
  }
}
.#{$zmdi-icon-prefix}-language-python-alt {
  &:before {
    content: $zmdi-var-language-python-alt;
  }
}
.#{$zmdi-icon-prefix}-language-python {
  &:before {
    content: $zmdi-var-language-python;
  }
}
.#{$zmdi-icon-prefix}-lastfm {
  &:before {
    content: $zmdi-var-lastfm;
  }
}
.#{$zmdi-icon-prefix}-linkedin-box {
  &:before {
    content: $zmdi-var-linkedin-box;
  }
}
.#{$zmdi-icon-prefix}-paypal {
  &:before {
    content: $zmdi-var-paypal;
  }
}
.#{$zmdi-icon-prefix}-pinterest-box {
  &:before {
    content: $zmdi-var-pinterest-box;
  }
}
.#{$zmdi-icon-prefix}-pocket {
  &:before {
    content: $zmdi-var-pocket;
  }
}
.#{$zmdi-icon-prefix}-polymer {
  &:before {
    content: $zmdi-var-polymer;
  }
}
.#{$zmdi-icon-prefix}-share {
  &:before {
    content: $zmdi-var-share;
  }
}
.#{$zmdi-icon-prefix}-stack-overflow {
  &:before {
    content: $zmdi-var-stack-overflow;
  }
}
.#{$zmdi-icon-prefix}-steam-square {
  &:before {
    content: $zmdi-var-steam-square;
  }
}
.#{$zmdi-icon-prefix}-steam {
  &:before {
    content: $zmdi-var-steam;
  }
}
.#{$zmdi-icon-prefix}-twitter-box {
  &:before {
    content: $zmdi-var-twitter-box;
  }
}
.#{$zmdi-icon-prefix}-twitter {
  &:before {
    content: $zmdi-var-twitter;
  }
}
.#{$zmdi-icon-prefix}-vk {
  &:before {
    content: $zmdi-var-vk;
  }
}
.#{$zmdi-icon-prefix}-wikipedia {
  &:before {
    content: $zmdi-var-wikipedia;
  }
}
.#{$zmdi-icon-prefix}-windows {
  &:before {
    content: $zmdi-var-windows;
  }
}
.#{$zmdi-icon-prefix}-aspect-ratio-alt {
  &:before {
    content: $zmdi-var-aspect-ratio-alt;
  }
}
.#{$zmdi-icon-prefix}-aspect-ratio {
  &:before {
    content: $zmdi-var-aspect-ratio;
  }
}
.#{$zmdi-icon-prefix}-blur-circular {
  &:before {
    content: $zmdi-var-blur-circular;
  }
}
.#{$zmdi-icon-prefix}-blur-linear {
  &:before {
    content: $zmdi-var-blur-linear;
  }
}
.#{$zmdi-icon-prefix}-blur-off {
  &:before {
    content: $zmdi-var-blur-off;
  }
}
.#{$zmdi-icon-prefix}-blur {
  &:before {
    content: $zmdi-var-blur;
  }
}
.#{$zmdi-icon-prefix}-brightness-2 {
  &:before {
    content: $zmdi-var-brightness-2;
  }
}
.#{$zmdi-icon-prefix}-brightness-3 {
  &:before {
    content: $zmdi-var-brightness-3;
  }
}
.#{$zmdi-icon-prefix}-brightness-4 {
  &:before {
    content: $zmdi-var-brightness-4;
  }
}
.#{$zmdi-icon-prefix}-brightness-5 {
  &:before {
    content: $zmdi-var-brightness-5;
  }
}
.#{$zmdi-icon-prefix}-brightness-6 {
  &:before {
    content: $zmdi-var-brightness-6;
  }
}
.#{$zmdi-icon-prefix}-brightness-7 {
  &:before {
    content: $zmdi-var-brightness-7;
  }
}
.#{$zmdi-icon-prefix}-brightness-auto {
  &:before {
    content: $zmdi-var-brightness-auto;
  }
}
.#{$zmdi-icon-prefix}-brightness-setting {
  &:before {
    content: $zmdi-var-brightness-setting;
  }
}
.#{$zmdi-icon-prefix}-broken-image {
  &:before {
    content: $zmdi-var-broken-image;
  }
}
.#{$zmdi-icon-prefix}-center-focus-strong {
  &:before {
    content: $zmdi-var-center-focus-strong;
  }
}
.#{$zmdi-icon-prefix}-center-focus-weak {
  &:before {
    content: $zmdi-var-center-focus-weak;
  }
}
.#{$zmdi-icon-prefix}-compare {
  &:before {
    content: $zmdi-var-compare;
  }
}
.#{$zmdi-icon-prefix}-crop-16-9 {
  &:before {
    content: $zmdi-var-crop-16-9;
  }
}
.#{$zmdi-icon-prefix}-crop-3-2 {
  &:before {
    content: $zmdi-var-crop-3-2;
  }
}
.#{$zmdi-icon-prefix}-crop-5-4 {
  &:before {
    content: $zmdi-var-crop-5-4;
  }
}
.#{$zmdi-icon-prefix}-crop-7-5 {
  &:before {
    content: $zmdi-var-crop-7-5;
  }
}
.#{$zmdi-icon-prefix}-crop-din {
  &:before {
    content: $zmdi-var-crop-din;
  }
}
.#{$zmdi-icon-prefix}-crop-free {
  &:before {
    content: $zmdi-var-crop-free;
  }
}
.#{$zmdi-icon-prefix}-crop-landscape {
  &:before {
    content: $zmdi-var-crop-landscape;
  }
}
.#{$zmdi-icon-prefix}-crop-portrait {
  &:before {
    content: $zmdi-var-crop-portrait;
  }
}
.#{$zmdi-icon-prefix}-crop-square {
  &:before {
    content: $zmdi-var-crop-square;
  }
}
.#{$zmdi-icon-prefix}-exposure-alt {
  &:before {
    content: $zmdi-var-exposure-alt;
  }
}
.#{$zmdi-icon-prefix}-exposure {
  &:before {
    content: $zmdi-var-exposure;
  }
}
.#{$zmdi-icon-prefix}-filter-b-and-w {
  &:before {
    content: $zmdi-var-filter-b-and-w;
  }
}
.#{$zmdi-icon-prefix}-filter-center-focus {
  &:before {
    content: $zmdi-var-filter-center-focus;
  }
}
.#{$zmdi-icon-prefix}-filter-frames {
  &:before {
    content: $zmdi-var-filter-frames;
  }
}
.#{$zmdi-icon-prefix}-filter-tilt-shift {
  &:before {
    content: $zmdi-var-filter-tilt-shift;
  }
}
.#{$zmdi-icon-prefix}-gradient {
  &:before {
    content: $zmdi-var-gradient;
  }
}
.#{$zmdi-icon-prefix}-grain {
  &:before {
    content: $zmdi-var-grain;
  }
}
.#{$zmdi-icon-prefix}-graphic-eq {
  &:before {
    content: $zmdi-var-graphic-eq;
  }
}
.#{$zmdi-icon-prefix}-hdr-off {
  &:before {
    content: $zmdi-var-hdr-off;
  }
}
.#{$zmdi-icon-prefix}-hdr-strong {
  &:before {
    content: $zmdi-var-hdr-strong;
  }
}
.#{$zmdi-icon-prefix}-hdr-weak {
  &:before {
    content: $zmdi-var-hdr-weak;
  }
}
.#{$zmdi-icon-prefix}-hdr {
  &:before {
    content: $zmdi-var-hdr;
  }
}
.#{$zmdi-icon-prefix}-iridescent {
  &:before {
    content: $zmdi-var-iridescent;
  }
}
.#{$zmdi-icon-prefix}-leak-off {
  &:before {
    content: $zmdi-var-leak-off;
  }
}
.#{$zmdi-icon-prefix}-leak {
  &:before {
    content: $zmdi-var-leak;
  }
}
.#{$zmdi-icon-prefix}-looks {
  &:before {
    content: $zmdi-var-looks;
  }
}
.#{$zmdi-icon-prefix}-loupe {
  &:before {
    content: $zmdi-var-loupe;
  }
}
.#{$zmdi-icon-prefix}-panorama-horizontal {
  &:before {
    content: $zmdi-var-panorama-horizontal;
  }
}
.#{$zmdi-icon-prefix}-panorama-vertical {
  &:before {
    content: $zmdi-var-panorama-vertical;
  }
}
.#{$zmdi-icon-prefix}-panorama-wide-angle {
  &:before {
    content: $zmdi-var-panorama-wide-angle;
  }
}
.#{$zmdi-icon-prefix}-photo-size-select-large {
  &:before {
    content: $zmdi-var-photo-size-select-large;
  }
}
.#{$zmdi-icon-prefix}-photo-size-select-small {
  &:before {
    content: $zmdi-var-photo-size-select-small;
  }
}
.#{$zmdi-icon-prefix}-picture-in-picture {
  &:before {
    content: $zmdi-var-picture-in-picture;
  }
}
.#{$zmdi-icon-prefix}-slideshow {
  &:before {
    content: $zmdi-var-slideshow;
  }
}
.#{$zmdi-icon-prefix}-texture {
  &:before {
    content: $zmdi-var-texture;
  }
}
.#{$zmdi-icon-prefix}-tonality {
  &:before {
    content: $zmdi-var-tonality;
  }
}
.#{$zmdi-icon-prefix}-vignette {
  &:before {
    content: $zmdi-var-vignette;
  }
}
.#{$zmdi-icon-prefix}-wb-auto {
  &:before {
    content: $zmdi-var-wb-auto;
  }
}
.#{$zmdi-icon-prefix}-eject-alt {
  &:before {
    content: $zmdi-var-eject-alt;
  }
}
.#{$zmdi-icon-prefix}-eject {
  &:before {
    content: $zmdi-var-eject;
  }
}
.#{$zmdi-icon-prefix}-equalizer {
  &:before {
    content: $zmdi-var-equalizer;
  }
}
.#{$zmdi-icon-prefix}-fast-forward {
  &:before {
    content: $zmdi-var-fast-forward;
  }
}
.#{$zmdi-icon-prefix}-fast-rewind {
  &:before {
    content: $zmdi-var-fast-rewind;
  }
}
.#{$zmdi-icon-prefix}-forward-10 {
  &:before {
    content: $zmdi-var-forward-10;
  }
}
.#{$zmdi-icon-prefix}-forward-30 {
  &:before {
    content: $zmdi-var-forward-30;
  }
}
.#{$zmdi-icon-prefix}-forward-5 {
  &:before {
    content: $zmdi-var-forward-5;
  }
}
.#{$zmdi-icon-prefix}-hearing {
  &:before {
    content: $zmdi-var-hearing;
  }
}
.#{$zmdi-icon-prefix}-pause-circle-outline {
  &:before {
    content: $zmdi-var-pause-circle-outline;
  }
}
.#{$zmdi-icon-prefix}-pause-circle {
  &:before {
    content: $zmdi-var-pause-circle;
  }
}
.#{$zmdi-icon-prefix}-pause {
  &:before {
    content: $zmdi-var-pause;
  }
}
.#{$zmdi-icon-prefix}-play-circle-outline {
  &:before {
    content: $zmdi-var-play-circle-outline;
  }
}
.#{$zmdi-icon-prefix}-play-circle {
  &:before {
    content: $zmdi-var-play-circle;
  }
}
.#{$zmdi-icon-prefix}-play {
  &:before {
    content: $zmdi-var-play;
  }
}
.#{$zmdi-icon-prefix}-playlist-audio {
  &:before {
    content: $zmdi-var-playlist-audio;
  }
}
.#{$zmdi-icon-prefix}-playlist-plus {
  &:before {
    content: $zmdi-var-playlist-plus;
  }
}
.#{$zmdi-icon-prefix}-repeat-one {
  &:before {
    content: $zmdi-var-repeat-one;
  }
}
.#{$zmdi-icon-prefix}-repeat {
  &:before {
    content: $zmdi-var-repeat;
  }
}
.#{$zmdi-icon-prefix}-replay-10 {
  &:before {
    content: $zmdi-var-replay-10;
  }
}
.#{$zmdi-icon-prefix}-replay-30 {
  &:before {
    content: $zmdi-var-replay-30;
  }
}
.#{$zmdi-icon-prefix}-replay-5 {
  &:before {
    content: $zmdi-var-replay-5;
  }
}
.#{$zmdi-icon-prefix}-replay {
  &:before {
    content: $zmdi-var-replay;
  }
}
.#{$zmdi-icon-prefix}-shuffle {
  &:before {
    content: $zmdi-var-shuffle;
  }
}
.#{$zmdi-icon-prefix}-skip-next {
  &:before {
    content: $zmdi-var-skip-next;
  }
}
.#{$zmdi-icon-prefix}-skip-previous {
  &:before {
    content: $zmdi-var-skip-previous;
  }
}
.#{$zmdi-icon-prefix}-stop {
  &:before {
    content: $zmdi-var-stop;
  }
}
.#{$zmdi-icon-prefix}-surround-sound {
  &:before {
    content: $zmdi-var-surround-sound;
  }
}
.#{$zmdi-icon-prefix}-tune {
  &:before {
    content: $zmdi-var-tune;
  }
}
.#{$zmdi-icon-prefix}-volume-down {
  &:before {
    content: $zmdi-var-volume-down;
  }
}
.#{$zmdi-icon-prefix}-volume-mute {
  &:before {
    content: $zmdi-var-volume-mute;
  }
}
.#{$zmdi-icon-prefix}-volume-off {
  &:before {
    content: $zmdi-var-volume-off;
  }
}
.#{$zmdi-icon-prefix}-volume-up {
  &:before {
    content: $zmdi-var-volume-up;
  }
}
.#{$zmdi-icon-prefix}-n-1-square {
  &:before {
    content: $zmdi-var-n-1-square;
  }
}
.#{$zmdi-icon-prefix}-n-2-square {
  &:before {
    content: $zmdi-var-n-2-square;
  }
}
.#{$zmdi-icon-prefix}-n-3-square {
  &:before {
    content: $zmdi-var-n-3-square;
  }
}
.#{$zmdi-icon-prefix}-n-4-square {
  &:before {
    content: $zmdi-var-n-4-square;
  }
}
.#{$zmdi-icon-prefix}-n-5-square {
  &:before {
    content: $zmdi-var-n-5-square;
  }
}
.#{$zmdi-icon-prefix}-n-6-square {
  &:before {
    content: $zmdi-var-n-6-square;
  }
}
.#{$zmdi-icon-prefix}-neg-1 {
  &:before {
    content: $zmdi-var-neg-1;
  }
}
.#{$zmdi-icon-prefix}-neg-2 {
  &:before {
    content: $zmdi-var-neg-2;
  }
}
.#{$zmdi-icon-prefix}-plus-1 {
  &:before {
    content: $zmdi-var-plus-1;
  }
}
.#{$zmdi-icon-prefix}-plus-2 {
  &:before {
    content: $zmdi-var-plus-2;
  }
}
.#{$zmdi-icon-prefix}-sec-10 {
  &:before {
    content: $zmdi-var-sec-10;
  }
}
.#{$zmdi-icon-prefix}-sec-3 {
  &:before {
    content: $zmdi-var-sec-3;
  }
}
.#{$zmdi-icon-prefix}-zero {
  &:before {
    content: $zmdi-var-zero;
  }
}
.#{$zmdi-icon-prefix}-airline-seat-flat-angled {
  &:before {
    content: $zmdi-var-airline-seat-flat-angled;
  }
}
.#{$zmdi-icon-prefix}-airline-seat-flat {
  &:before {
    content: $zmdi-var-airline-seat-flat;
  }
}
.#{$zmdi-icon-prefix}-airline-seat-individual-suite {
  &:before {
    content: $zmdi-var-airline-seat-individual-suite;
  }
}
.#{$zmdi-icon-prefix}-airline-seat-legroom-extra {
  &:before {
    content: $zmdi-var-airline-seat-legroom-extra;
  }
}
.#{$zmdi-icon-prefix}-airline-seat-legroom-normal {
  &:before {
    content: $zmdi-var-airline-seat-legroom-normal;
  }
}
.#{$zmdi-icon-prefix}-airline-seat-legroom-reduced {
  &:before {
    content: $zmdi-var-airline-seat-legroom-reduced;
  }
}
.#{$zmdi-icon-prefix}-airline-seat-recline-extra {
  &:before {
    content: $zmdi-var-airline-seat-recline-extra;
  }
}
.#{$zmdi-icon-prefix}-airline-seat-recline-normal {
  &:before {
    content: $zmdi-var-airline-seat-recline-normal;
  }
}
.#{$zmdi-icon-prefix}-airplay {
  &:before {
    content: $zmdi-var-airplay;
  }
}
.#{$zmdi-icon-prefix}-closed-caption {
  &:before {
    content: $zmdi-var-closed-caption;
  }
}
.#{$zmdi-icon-prefix}-confirmation-number {
  &:before {
    content: $zmdi-var-confirmation-number;
  }
}
.#{$zmdi-icon-prefix}-developer-board {
  &:before {
    content: $zmdi-var-developer-board;
  }
}
.#{$zmdi-icon-prefix}-disc-full {
  &:before {
    content: $zmdi-var-disc-full;
  }
}
.#{$zmdi-icon-prefix}-explicit {
  &:before {
    content: $zmdi-var-explicit;
  }
}
.#{$zmdi-icon-prefix}-flight-land {
  &:before {
    content: $zmdi-var-flight-land;
  }
}
.#{$zmdi-icon-prefix}-flight-takeoff {
  &:before {
    content: $zmdi-var-flight-takeoff;
  }
}
.#{$zmdi-icon-prefix}-flip-to-back {
  &:before {
    content: $zmdi-var-flip-to-back;
  }
}
.#{$zmdi-icon-prefix}-flip-to-front {
  &:before {
    content: $zmdi-var-flip-to-front;
  }
}
.#{$zmdi-icon-prefix}-group-work {
  &:before {
    content: $zmdi-var-group-work;
  }
}
.#{$zmdi-icon-prefix}-hd {
  &:before {
    content: $zmdi-var-hd;
  }
}
.#{$zmdi-icon-prefix}-hq {
  &:before {
    content: $zmdi-var-hq;
  }
}
.#{$zmdi-icon-prefix}-markunread-mailbox {
  &:before {
    content: $zmdi-var-markunread-mailbox;
  }
}
.#{$zmdi-icon-prefix}-memory {
  &:before {
    content: $zmdi-var-memory;
  }
}
.#{$zmdi-icon-prefix}-nfc {
  &:before {
    content: $zmdi-var-nfc;
  }
}
.#{$zmdi-icon-prefix}-play-for-work {
  &:before {
    content: $zmdi-var-play-for-work;
  }
}
.#{$zmdi-icon-prefix}-power-input {
  &:before {
    content: $zmdi-var-power-input;
  }
}
.#{$zmdi-icon-prefix}-present-to-all {
  &:before {
    content: $zmdi-var-present-to-all;
  }
}
.#{$zmdi-icon-prefix}-satellite {
  &:before {
    content: $zmdi-var-satellite;
  }
}
.#{$zmdi-icon-prefix}-tap-and-play {
  &:before {
    content: $zmdi-var-tap-and-play;
  }
}
.#{$zmdi-icon-prefix}-vibration {
  &:before {
    content: $zmdi-var-vibration;
  }
}
.#{$zmdi-icon-prefix}-voicemail {
  &:before {
    content: $zmdi-var-voicemail;
  }
}
// Legacy code for backward capability with 2.0.x version
$md-iconset-3d-rotation: '\f101' !default;
$md-iconset-airplane-off: '\f102' !default;
$md-iconset-airplane: '\f103' !default;
$md-iconset-album: '\f104' !default;
$md-iconset-archive: '\f105' !default;
$md-iconset-assignment-account: '\f106' !default;
$md-iconset-assignment-alert: '\f107' !default;
$md-iconset-assignment-check: '\f108' !default;
$md-iconset-assignment-o: '\f109' !default;
$md-iconset-assignment-return: '\f10a' !default;
$md-iconset-assignment-returned: '\f10b' !default;
$md-iconset-assignment: '\f10c' !default;
$md-iconset-attachment-alt: '\f10d' !default;
$md-iconset-attachment: '\f10e' !default;
$md-iconset-audio: '\f10f' !default;
$md-iconset-badge-check: '\f110' !default;
$md-iconset-balance-wallet: '\f111' !default;
$md-iconset-balance: '\f112' !default;
$md-iconset-battery-alert: '\f113' !default;
$md-iconset-battery-flash: '\f114' !default;
$md-iconset-battery-unknown: '\f115' !default;
$md-iconset-battery: '\f116' !default;
$md-iconset-bike: '\f117' !default;
$md-iconset-block-alt: '\f118' !default;
$md-iconset-block: '\f119' !default;
$md-iconset-boat: '\f11a' !default;
$md-iconset-book-image: '\f11b' !default;
$md-iconset-book: '\f11c' !default;
$md-iconset-bookmark-outline: '\f11d' !default;
$md-iconset-bookmark: '\f11e' !default;
$md-iconset-brush: '\f11f' !default;
$md-iconset-bug: '\f120' !default;
$md-iconset-bus: '\f121' !default;
$md-iconset-cake: '\f122' !default;
$md-iconset-car-taxi: '\f123' !default;
$md-iconset-car-wash: '\f124' !default;
$md-iconset-car: '\f125' !default;
$md-iconset-card-giftcard: '\f126' !default;
$md-iconset-card-membership: '\f127' !default;
$md-iconset-card-travel: '\f128' !default;
$md-iconset-card: '\f129' !default;
$md-iconset-case-check: '\f12a' !default;
$md-iconset-case-download: '\f12b' !default;
$md-iconset-case-play: '\f12c' !default;
$md-iconset-case: '\f12d' !default;
$md-iconset-cast-connected: '\f12e' !default;
$md-iconset-cast: '\f12f' !default;
$md-iconset-chart-donut: '\f130' !default;
$md-iconset-chart: '\f131' !default;
$md-iconset-city-alt: '\f132' !default;
$md-iconset-city: '\f133' !default;
$md-iconset-close-circle-o: '\f134' !default;
$md-iconset-close-circle: '\f135' !default;
$md-iconset-close: '\f136' !default;
$md-iconset-cocktail: '\f137' !default;
$md-iconset-code-setting: '\f138' !default;
$md-iconset-code-smartphone: '\f139' !default;
$md-iconset-code: '\f13a' !default;
$md-iconset-coffee: '\f13b' !default;
$md-iconset-collection-bookmark: '\f13c' !default;
$md-iconset-collection-case-play: '\f13d' !default;
$md-iconset-collection-folder-image: '\f13e' !default;
$md-iconset-collection-image-o: '\f13f' !default;
$md-iconset-collection-image: '\f140' !default;
$md-iconset-collection-item-1: '\f141' !default;
$md-iconset-collection-item-2: '\f142' !default;
$md-iconset-collection-item-3: '\f143' !default;
$md-iconset-collection-item-4: '\f144' !default;
$md-iconset-collection-item-5: '\f145' !default;
$md-iconset-collection-item-6: '\f146' !default;
$md-iconset-collection-item-7: '\f147' !default;
$md-iconset-collection-item-8: '\f148' !default;
$md-iconset-collection-item-9-plus: '\f149' !default;
$md-iconset-collection-item-9: '\f14a' !default;
$md-iconset-collection-item: '\f14b' !default;
$md-iconset-collection-music: '\f14c' !default;
$md-iconset-collection-pdf: '\f14d' !default;
$md-iconset-collection-plus: '\f14e' !default;
$md-iconset-collection-speaker: '\f14f' !default;
$md-iconset-collection-text: '\f150' !default;
$md-iconset-collection-video: '\f151' !default;
$md-iconset-compass: '\f152' !default;
$md-iconset-cutlery: '\f153' !default;
$md-iconset-delete: '\f154' !default;
$md-iconset-dialpad: '\f155' !default;
$md-iconset-dns: '\f156' !default;
$md-iconset-drink: '\f157' !default;
$md-iconset-edit: '\f158' !default;
$md-iconset-email-open: '\f159' !default;
$md-iconset-email: '\f15a' !default;
$md-iconset-eye-off: '\f15b' !default;
$md-iconset-eye: '\f15c' !default;
$md-iconset-eyedropper: '\f15d' !default;
$md-iconset-favorite-outline: '\f15e' !default;
$md-iconset-favorite: '\f15f' !default;
$md-iconset-filter-list: '\f160' !default;
$md-iconset-fire: '\f161' !default;
$md-iconset-flag: '\f162' !default;
$md-iconset-flare: '\f163' !default;
$md-iconset-flash-auto: '\f164' !default;
$md-iconset-flash-off: '\f165' !default;
$md-iconset-flash: '\f166' !default;
$md-iconset-flip: '\f167' !default;
$md-iconset-flower-alt: '\f168' !default;
$md-iconset-flower: '\f169' !default;
$md-iconset-font: '\f16a' !default;
$md-iconset-fullscreen-alt: '\f16b' !default;
$md-iconset-fullscreen-exit: '\f16c' !default;
$md-iconset-fullscreen: '\f16d' !default;
$md-iconset-functions: '\f16e' !default;
$md-iconset-gas-station: '\f16f' !default;
$md-iconset-gesture: '\f170' !default;
$md-iconset-globe-alt: '\f171' !default;
$md-iconset-globe-lock: '\f172' !default;
$md-iconset-globe: '\f173' !default;
$md-iconset-graduation-cap: '\f174' !default;
$md-iconset-home: '\f175' !default;
$md-iconset-hospital-alt: '\f176' !default;
$md-iconset-hospital: '\f177' !default;
$md-iconset-hotel: '\f178' !default;
$md-iconset-hourglass-alt: '\f179' !default;
$md-iconset-hourglass-outline: '\f17a' !default;
$md-iconset-hourglass: '\f17b' !default;
$md-iconset-http: '\f17c' !default;
$md-iconset-image-alt: '\f17d' !default;
$md-iconset-image-o: '\f17e' !default;
$md-iconset-image: '\f17f' !default;
$md-iconset-inbox: '\f180' !default;
$md-iconset-invert-colors-off: '\f181' !default;
$md-iconset-invert-colors: '\f182' !default;
$md-iconset-key: '\f183' !default;
$md-iconset-label-alt-outline: '\f184' !default;
$md-iconset-label-alt: '\f185' !default;
$md-iconset-label-heart: '\f186' !default;
$md-iconset-label: '\f187' !default;
$md-iconset-labels: '\f188' !default;
$md-iconset-lamp: '\f189' !default;
$md-iconset-landscape: '\f18a' !default;
$md-iconset-layers-off: '\f18b' !default;
$md-iconset-layers: '\f18c' !default;
$md-iconset-library: '\f18d' !default;
$md-iconset-link: '\f18e' !default;
$md-iconset-lock-open: '\f18f' !default;
$md-iconset-lock-outline: '\f190' !default;
$md-iconset-lock: '\f191' !default;
$md-iconset-mail-reply-all: '\f192' !default;
$md-iconset-mail-reply: '\f193' !default;
$md-iconset-mail-send: '\f194' !default;
$md-iconset-mall: '\f195' !default;
$md-iconset-map: '\f196' !default;
$md-iconset-menu: '\f197' !default;
$md-iconset-money-box: '\f198' !default;
$md-iconset-money-off: '\f199' !default;
$md-iconset-money: '\f19a' !default;
$md-iconset-more-vert: '\f19b' !default;
$md-iconset-more: '\f19c' !default;
$md-iconset-movie-alt: '\f19d' !default;
$md-iconset-movie: '\f19e' !default;
$md-iconset-nature-people: '\f19f' !default;
$md-iconset-nature: '\f1a0' !default;
$md-iconset-navigation: '\f1a1' !default;
$md-iconset-open-in-browser: '\f1a2' !default;
$md-iconset-open-in-new: '\f1a3' !default;
$md-iconset-palette: '\f1a4' !default;
$md-iconset-parking: '\f1a5' !default;
$md-iconset-pin-account: '\f1a6' !default;
$md-iconset-pin-assistant: '\f1a7' !default;
$md-iconset-pin-drop: '\f1a8' !default;
$md-iconset-pin-help: '\f1a9' !default;
$md-iconset-pin-off: '\f1aa' !default;
$md-iconset-pin: '\f1ab' !default;
$md-iconset-pizza: '\f1ac' !default;
$md-iconset-plaster: '\f1ad' !default;
$md-iconset-power-setting: '\f1ae' !default;
$md-iconset-power: '\f1af' !default;
$md-iconset-print: '\f1b0' !default;
$md-iconset-puzzle-piece: '\f1b1' !default;
$md-iconset-quote: '\f1b2' !default;
$md-iconset-railway: '\f1b3' !default;
$md-iconset-receipt: '\f1b4' !default;
$md-iconset-refresh-alt: '\f1b5' !default;
$md-iconset-refresh-sync-alert: '\f1b6' !default;
$md-iconset-refresh-sync-off: '\f1b7' !default;
$md-iconset-refresh-sync: '\f1b8' !default;
$md-iconset-refresh: '\f1b9' !default;
$md-iconset-roller: '\f1ba' !default;
$md-iconset-ruler: '\f1bb' !default;
$md-iconset-scissors: '\f1bc' !default;
$md-iconset-screen-rotation-lock: '\f1bd' !default;
$md-iconset-screen-rotation: '\f1be' !default;
$md-iconset-search-for: '\f1bf' !default;
$md-iconset-search-in-file: '\f1c0' !default;
$md-iconset-search-in-page: '\f1c1' !default;
$md-iconset-search-replace: '\f1c2' !default;
$md-iconset-search: '\f1c3' !default;
$md-iconset-seat: '\f1c4' !default;
$md-iconset-settings-square: '\f1c5' !default;
$md-iconset-settings: '\f1c6' !default;
$md-iconset-shield-check: '\f1c7' !default;
$md-iconset-shield-security: '\f1c8' !default;
$md-iconset-shopping-basket: '\f1c9' !default;
$md-iconset-shopping-cart-plus: '\f1ca' !default;
$md-iconset-shopping-cart: '\f1cb' !default;
$md-iconset-sign-in: '\f1cc' !default;
$md-iconset-sort-amount-asc: '\f1cd' !default;
$md-iconset-sort-amount-desc: '\f1ce' !default;
$md-iconset-sort-asc: '\f1cf' !default;
$md-iconset-sort-desc: '\f1d0' !default;
$md-iconset-spellcheck: '\f1d1' !default;
$md-iconset-storage: '\f1d2' !default;
$md-iconset-store-24: '\f1d3' !default;
$md-iconset-store: '\f1d4' !default;
$md-iconset-subway: '\f1d5' !default;
$md-iconset-sun: '\f1d6' !default;
$md-iconset-tab-unselected: '\f1d7' !default;
$md-iconset-tab: '\f1d8' !default;
$md-iconset-tag-close: '\f1d9' !default;
$md-iconset-tag-more: '\f1da' !default;
$md-iconset-tag: '\f1db' !default;
$md-iconset-thumb-down: '\f1dc' !default;
$md-iconset-thumb-up-down: '\f1dd' !default;
$md-iconset-thumb-up: '\f1de' !default;
$md-iconset-ticket-star: '\f1df' !default;
$md-iconset-toll: '\f1e0' !default;
$md-iconset-toys: '\f1e1' !default;
$md-iconset-traffic: '\f1e2' !default;
$md-iconset-translate: '\f1e3' !default;
$md-iconset-triangle-down: '\f1e4' !default;
$md-iconset-triangle-up: '\f1e5' !default;
$md-iconset-truck: '\f1e6' !default;
$md-iconset-turning-sign: '\f1e7' !default;
$md-iconset-wallpaper: '\f1e8' !default;
$md-iconset-washing-machine: '\f1e9' !default;
$md-iconset-window-maximize: '\f1ea' !default;
$md-iconset-window-minimize: '\f1eb' !default;
$md-iconset-window-restore: '\f1ec' !default;
$md-iconset-wrench: '\f1ed' !default;
$md-iconset-zoom-in: '\f1ee' !default;
$md-iconset-zoom-out: '\f1ef' !default;
$md-iconset-alert-circle-o: '\f1f0' !default;
$md-iconset-alert-circle: '\f1f1' !default;
$md-iconset-alert-octagon: '\f1f2' !default;
$md-iconset-alert-polygon: '\f1f3' !default;
$md-iconset-alert-triangle: '\f1f4' !default;
$md-iconset-help-outline: '\f1f5' !default;
$md-iconset-help: '\f1f6' !default;
$md-iconset-info-outline: '\f1f7' !default;
$md-iconset-info: '\f1f8' !default;
$md-iconset-notifications-active: '\f1f9' !default;
$md-iconset-notifications-add: '\f1fa' !default;
$md-iconset-notifications-none: '\f1fb' !default;
$md-iconset-notifications-off: '\f1fc' !default;
$md-iconset-notifications-paused: '\f1fd' !default;
$md-iconset-notifications: '\f1fe' !default;
$md-iconset-account-add: '\f1ff' !default;
$md-iconset-account-box-mail: '\f200' !default;
$md-iconset-account-box-o: '\f201' !default;
$md-iconset-account-box-phone: '\f202' !default;
$md-iconset-account-box: '\f203' !default;
$md-iconset-account-calendar: '\f204' !default;
$md-iconset-account-circle: '\f205' !default;
$md-iconset-account-o: '\f206' !default;
$md-iconset-account: '\f207' !default;
$md-iconset-accounts-add: '\f208' !default;
$md-iconset-accounts-alt: '\f209' !default;
$md-iconset-accounts-list-alt: '\f20a' !default;
$md-iconset-accounts-list: '\f20b' !default;
$md-iconset-accounts-outline: '\f20c' !default;
$md-iconset-accounts: '\f20d' !default;
$md-iconset-face: '\f20e' !default;
$md-iconset-female: '\f20f' !default;
$md-iconset-male-alt: '\f210' !default;
$md-iconset-male-female: '\f211' !default;
$md-iconset-male: '\f212' !default;
$md-iconset-mood-bad: '\f213' !default;
$md-iconset-mood: '\f214' !default;
$md-iconset-run: '\f215' !default;
$md-iconset-walk: '\f216' !default;
$md-iconset-cloud-box: '\f217' !default;
$md-iconset-cloud-circle: '\f218' !default;
$md-iconset-cloud-done: '\f219' !default;
$md-iconset-cloud-download: '\f21a' !default;
$md-iconset-cloud-off: '\f21b' !default;
$md-iconset-cloud-outline-alt: '\f21c' !default;
$md-iconset-cloud-outline: '\f21d' !default;
$md-iconset-cloud-upload: '\f21e' !default;
$md-iconset-cloud: '\f21f' !default;
$md-iconset-download: '\f220' !default;
$md-iconset-file-plus: '\f221' !default;
$md-iconset-file-text: '\f222' !default;
$md-iconset-file: '\f223' !default;
$md-iconset-folder-outline: '\f224' !default;
$md-iconset-folder-person: '\f225' !default;
$md-iconset-folder-star-alt: '\f226' !default;
$md-iconset-folder-star: '\f227' !default;
$md-iconset-folder: '\f228' !default;
$md-iconset-gif: '\f229' !default;
$md-iconset-upload: '\f22a' !default;
$md-iconset-border-all: '\f22b' !default;
$md-iconset-border-bottom: '\f22c' !default;
$md-iconset-border-clear: '\f22d' !default;
$md-iconset-border-color: '\f22e' !default;
$md-iconset-border-horizontal: '\f22f' !default;
$md-iconset-border-inner: '\f230' !default;
$md-iconset-border-left: '\f231' !default;
$md-iconset-border-outer: '\f232' !default;
$md-iconset-border-right: '\f233' !default;
$md-iconset-border-style: '\f234' !default;
$md-iconset-border-top: '\f235' !default;
$md-iconset-border-vertical: '\f236' !default;
$md-iconset-copy: '\f237' !default;
$md-iconset-crop: '\f238' !default;
$md-iconset-format-align-center: '\f239' !default;
$md-iconset-format-align-justify: '\f23a' !default;
$md-iconset-format-align-left: '\f23b' !default;
$md-iconset-format-align-right: '\f23c' !default;
$md-iconset-format-bold: '\f23d' !default;
$md-iconset-format-clear-all: '\f23e' !default;
$md-iconset-format-clear: '\f23f' !default;
$md-iconset-format-color-fill: '\f240' !default;
$md-iconset-format-color-reset: '\f241' !default;
$md-iconset-format-color-text: '\f242' !default;
$md-iconset-format-indent-decrease: '\f243' !default;
$md-iconset-format-indent-increase: '\f244' !default;
$md-iconset-format-italic: '\f245' !default;
$md-iconset-format-line-spacing: '\f246' !default;
$md-iconset-format-list-bulleted: '\f247' !default;
$md-iconset-format-list-numbered: '\f248' !default;
$md-iconset-format-ltr: '\f249' !default;
$md-iconset-format-rtl: '\f24a' !default;
$md-iconset-format-size: '\f24b' !default;
$md-iconset-format-strikethrough-s: '\f24c' !default;
$md-iconset-format-strikethrough: '\f24d' !default;
$md-iconset-format-subject: '\f24e' !default;
$md-iconset-format-underlined: '\f24f' !default;
$md-iconset-format-valign-bottom: '\f250' !default;
$md-iconset-format-valign-center: '\f251' !default;
$md-iconset-format-valign-top: '\f252' !default;
$md-iconset-redo: '\f253' !default;
$md-iconset-select-all: '\f254' !default;
$md-iconset-space-bar: '\f255' !default;
$md-iconset-text-format: '\f256' !default;
$md-iconset-transform: '\f257' !default;
$md-iconset-undo: '\f258' !default;
$md-iconset-wrap-text: '\f259' !default;
$md-iconset-comment-alert: '\f25a' !default;
$md-iconset-comment-alt-text: '\f25b' !default;
$md-iconset-comment-alt: '\f25c' !default;
$md-iconset-comment-edit: '\f25d' !default;
$md-iconset-comment-image: '\f25e' !default;
$md-iconset-comment-list: '\f25f' !default;
$md-iconset-comment-more: '\f260' !default;
$md-iconset-comment-outline: '\f261' !default;
$md-iconset-comment-text-alt: '\f262' !default;
$md-iconset-comment-text: '\f263' !default;
$md-iconset-comment-video: '\f264' !default;
$md-iconset-comment: '\f265' !default;
$md-iconset-comments: '\f266' !default;
$md-iconset-check-all: '\f267' !default;
$md-iconset-check-circle-u: '\f268' !default;
$md-iconset-check-circle: '\f269' !default;
$md-iconset-check-square: '\f26a' !default;
$md-iconset-check: '\f26b' !default;
$md-iconset-circle-o: '\f26c' !default;
$md-iconset-circle: '\f26d' !default;
$md-iconset-dot-circle-alt: '\f26e' !default;
$md-iconset-dot-circle: '\f26f' !default;
$md-iconset-minus-circle-outline: '\f270' !default;
$md-iconset-minus-circle: '\f271' !default;
$md-iconset-minus-square: '\f272' !default;
$md-iconset-minus: '\f273' !default;
$md-iconset-plus-circle-o-duplicate: '\f274' !default;
$md-iconset-plus-circle-o: '\f275' !default;
$md-iconset-plus-circle: '\f276' !default;
$md-iconset-plus-square: '\f277' !default;
$md-iconset-plus: '\f278' !default;
$md-iconset-square-o: '\f279' !default;
$md-iconset-star-circle: '\f27a' !default;
$md-iconset-star-half: '\f27b' !default;
$md-iconset-star-outline: '\f27c' !default;
$md-iconset-star: '\f27d' !default;
$md-iconset-bluetooth-connected: '\f27e' !default;
$md-iconset-bluetooth-off: '\f27f' !default;
$md-iconset-bluetooth-search: '\f280' !default;
$md-iconset-bluetooth-setting: '\f281' !default;
$md-iconset-bluetooth: '\f282' !default;
$md-iconset-camera-add: '\f283' !default;
$md-iconset-camera-alt: '\f284' !default;
$md-iconset-camera-bw: '\f285' !default;
$md-iconset-camera-front: '\f286' !default;
$md-iconset-camera-mic: '\f287' !default;
$md-iconset-camera-party-mode: '\f288' !default;
$md-iconset-camera-rear: '\f289' !default;
$md-iconset-camera-roll: '\f28a' !default;
$md-iconset-camera-switch: '\f28b' !default;
$md-iconset-camera: '\f28c' !default;
$md-iconset-card-alert: '\f28d' !default;
$md-iconset-card-off: '\f28e' !default;
$md-iconset-card-sd: '\f28f' !default;
$md-iconset-card-sim: '\f290' !default;
$md-iconset-desktop-mac: '\f291' !default;
$md-iconset-desktop-windows: '\f292' !default;
$md-iconset-device-hub: '\f293' !default;
$md-iconset-devices-off: '\f294' !default;
$md-iconset-devices: '\f295' !default;
$md-iconset-dock: '\f296' !default;
$md-iconset-floppy: '\f297' !default;
$md-iconset-gamepad: '\f298' !default;
$md-iconset-gps-dot: '\f299' !default;
$md-iconset-gps-off: '\f29a' !default;
$md-iconset-gps: '\f29b' !default;
$md-iconset-headset-mic: '\f29c' !default;
$md-iconset-headset: '\f29d' !default;
$md-iconset-input-antenna: '\f29e' !default;
$md-iconset-input-composite: '\f29f' !default;
$md-iconset-input-hdmi: '\f2a0' !default;
$md-iconset-input-power: '\f2a1' !default;
$md-iconset-input-svideo: '\f2a2' !default;
$md-iconset-keyboard-hide: '\f2a3' !default;
$md-iconset-keyboard: '\f2a4' !default;
$md-iconset-laptop-chromebook: '\f2a5' !default;
$md-iconset-laptop-mac: '\f2a6' !default;
$md-iconset-laptop: '\f2a7' !default;
$md-iconset-mic-off: '\f2a8' !default;
$md-iconset-mic-outline: '\f2a9' !default;
$md-iconset-mic-setting: '\f2aa' !default;
$md-iconset-mic: '\f2ab' !default;
$md-iconset-mouse: '\f2ac' !default;
$md-iconset-network-alert: '\f2ad' !default;
$md-iconset-network-locked: '\f2ae' !default;
$md-iconset-network-off: '\f2af' !default;
$md-iconset-network-outline: '\f2b0' !default;
$md-iconset-network-setting: '\f2b1' !default;
$md-iconset-network: '\f2b2' !default;
$md-iconset-phone-bluetooth: '\f2b3' !default;
$md-iconset-phone-end: '\f2b4' !default;
$md-iconset-phone-forwarded: '\f2b5' !default;
$md-iconset-phone-in-talk: '\f2b6' !default;
$md-iconset-phone-locked: '\f2b7' !default;
$md-iconset-phone-missed: '\f2b8' !default;
$md-iconset-phone-msg: '\f2b9' !default;
$md-iconset-phone-paused: '\f2ba' !default;
$md-iconset-phone-ring: '\f2bb' !default;
$md-iconset-phone-setting: '\f2bc' !default;
$md-iconset-phone-sip: '\f2bd' !default;
$md-iconset-phone: '\f2be' !default;
$md-iconset-portable-wifi-changes: '\f2bf' !default;
$md-iconset-portable-wifi-off: '\f2c0' !default;
$md-iconset-portable-wifi: '\f2c1' !default;
$md-iconset-radio: '\f2c2' !default;
$md-iconset-reader: '\f2c3' !default;
$md-iconset-remote-control-alt: '\f2c4' !default;
$md-iconset-remote-control: '\f2c5' !default;
$md-iconset-router: '\f2c6' !default;
$md-iconset-scanner: '\f2c7' !default;
$md-iconset-smartphone-android: '\f2c8' !default;
$md-iconset-smartphone-download: '\f2c9' !default;
$md-iconset-smartphone-erase: '\f2ca' !default;
$md-iconset-smartphone-info: '\f2cb' !default;
$md-iconset-smartphone-iphone: '\f2cc' !default;
$md-iconset-smartphone-landscape-lock: '\f2cd' !default;
$md-iconset-smartphone-landscape: '\f2ce' !default;
$md-iconset-smartphone-lock: '\f2cf' !default;
$md-iconset-smartphone-portrait-lock: '\f2d0' !default;
$md-iconset-smartphone-ring: '\f2d1' !default;
$md-iconset-smartphone-setting: '\f2d2' !default;
$md-iconset-smartphone-setup: '\f2d3' !default;
$md-iconset-smartphone: '\f2d4' !default;
$md-iconset-speaker: '\f2d5' !default;
$md-iconset-tablet-android: '\f2d6' !default;
$md-iconset-tablet-mac: '\f2d7' !default;
$md-iconset-tablet: '\f2d8' !default;
$md-iconset-tv-alt-play: '\f2d9' !default;
$md-iconset-tv-list: '\f2da' !default;
$md-iconset-tv-play: '\f2db' !default;
$md-iconset-tv: '\f2dc' !default;
$md-iconset-usb: '\f2dd' !default;
$md-iconset-videocam-off: '\f2de' !default;
$md-iconset-videocam-switch: '\f2df' !default;
$md-iconset-videocam: '\f2e0' !default;
$md-iconset-watch: '\f2e1' !default;
$md-iconset-wifi-alt-2: '\f2e2' !default;
$md-iconset-wifi-alt: '\f2e3' !default;
$md-iconset-wifi-info: '\f2e4' !default;
$md-iconset-wifi-lock: '\f2e5' !default;
$md-iconset-wifi-off: '\f2e6' !default;
$md-iconset-wifi-outline: '\f2e7' !default;
$md-iconset-wifi: '\f2e8' !default;
$md-iconset-arrow-left-bottom: '\f2e9' !default;
$md-iconset-arrow-left: '\f2ea' !default;
$md-iconset-arrow-merge: '\f2eb' !default;
$md-iconset-arrow-missed: '\f2ec' !default;
$md-iconset-arrow-right-top: '\f2ed' !default;
$md-iconset-arrow-right: '\f2ee' !default;
$md-iconset-arrow-split: '\f2ef' !default;
$md-iconset-arrows: '\f2f0' !default;
$md-iconset-caret-down-circle: '\f2f1' !default;
$md-iconset-caret-down: '\f2f2' !default;
$md-iconset-caret-left-circle: '\f2f3' !default;
$md-iconset-caret-left: '\f2f4' !default;
$md-iconset-caret-right-circle: '\f2f5' !default;
$md-iconset-caret-right: '\f2f6' !default;
$md-iconset-caret-up-circle: '\f2f7' !default;
$md-iconset-caret-up: '\f2f8' !default;
$md-iconset-chevron-down: '\f2f9' !default;
$md-iconset-chevron-left: '\f2fa' !default;
$md-iconset-chevron-right: '\f2fb' !default;
$md-iconset-chevron-up: '\f2fc' !default;
$md-iconset-forward: '\f2fd' !default;
$md-iconset-long-arrow-down: '\f2fe' !default;
$md-iconset-long-arrow-left: '\f2ff' !default;
$md-iconset-long-arrow-return: '\f300' !default;
$md-iconset-long-arrow-right: '\f301' !default;
$md-iconset-long-arrow-tab: '\f302' !default;
$md-iconset-long-arrow-up: '\f303' !default;
$md-iconset-rotate-ccw: '\f304' !default;
$md-iconset-rotate-cw: '\f305' !default;
$md-iconset-rotate-left: '\f306' !default;
$md-iconset-rotate-right: '\f307' !default;
$md-iconset-square-down: '\f308' !default;
$md-iconset-square-right: '\f309' !default;
$md-iconset-swap-alt: '\f30a' !default;
$md-iconset-swap-vertical-circle: '\f30b' !default;
$md-iconset-swap-vertical: '\f30c' !default;
$md-iconset-swap: '\f30d' !default;
$md-iconset-trending-down: '\f30e' !default;
$md-iconset-trending-flat: '\f30f' !default;
$md-iconset-trending-up: '\f310' !default;
$md-iconset-unfold-less: '\f311' !default;
$md-iconset-unfold-more: '\f312' !default;
$md-iconset-apps: '\f313' !default;
$md-iconset-grid-off: '\f314' !default;
$md-iconset-grid: '\f315' !default;
$md-iconset-view-agenda: '\f316' !default;
$md-iconset-view-array: '\f317' !default;
$md-iconset-view-carousel: '\f318' !default;
$md-iconset-view-column: '\f319' !default;
$md-iconset-view-comfy: '\f31a' !default;
$md-iconset-view-compact: '\f31b' !default;
$md-iconset-view-dashboard: '\f31c' !default;
$md-iconset-view-day: '\f31d' !default;
$md-iconset-view-headline: '\f31e' !default;
$md-iconset-view-list-alt: '\f31f' !default;
$md-iconset-view-list: '\f320' !default;
$md-iconset-view-module: '\f321' !default;
$md-iconset-view-quilt: '\f322' !default;
$md-iconset-view-stream: '\f323' !default;
$md-iconset-view-subtitles: '\f324' !default;
$md-iconset-view-toc: '\f325' !default;
$md-iconset-view-web: '\f326' !default;
$md-iconset-view-week: '\f327' !default;
$md-iconset-widgets: '\f328' !default;
$md-iconset-alarm-check: '\f329' !default;
$md-iconset-alarm-off: '\f32a' !default;
$md-iconset-alarm-plus: '\f32b' !default;
$md-iconset-alarm-snooze: '\f32c' !default;
$md-iconset-alarm: '\f32d' !default;
$md-iconset-calendar-alt: '\f32e' !default;
$md-iconset-calendar-check: '\f32f' !default;
$md-iconset-calendar-close: '\f330' !default;
$md-iconset-calendar-note: '\f331' !default;
$md-iconset-calendar: '\f332' !default;
$md-iconset-time-countdown: '\f333' !default;
$md-iconset-time-interval: '\f334' !default;
$md-iconset-time-restore-setting: '\f335' !default;
$md-iconset-time-restore: '\f336' !default;
$md-iconset-time: '\f337' !default;
$md-iconset-timer-off: '\f338' !default;
$md-iconset-timer: '\f339' !default;
$md-iconset-android-alt: '\f33a' !default;
$md-iconset-android: '\f33b' !default;
$md-iconset-apple: '\f33c' !default;
$md-iconset-behance: '\f33d' !default;
$md-iconset-codepen: '\f33e' !default;
$md-iconset-dribbble: '\f33f' !default;
$md-iconset-dropbox: '\f340' !default;
$md-iconset-evernote: '\f341' !default;
$md-iconset-facebook-box: '\f342' !default;
$md-iconset-facebook: '\f343' !default;
$md-iconset-github-box: '\f344' !default;
$md-iconset-github: '\f345' !default;
$md-iconset-google-drive: '\f346' !default;
$md-iconset-google-earth: '\f347' !default;
$md-iconset-google-glass: '\f348' !default;
$md-iconset-google-maps: '\f349' !default;
$md-iconset-google-pages: '\f34a' !default;
$md-iconset-google-play: '\f34b' !default;
$md-iconset-google-plus-box: '\f34c' !default;
$md-iconset-google-plus: '\f34d' !default;
$md-iconset-google: '\f34e' !default;
$md-iconset-instagram: '\f34f' !default;
$md-iconset-language-css3: '\f350' !default;
$md-iconset-language-html5: '\f351' !default;
$md-iconset-language-javascript: '\f352' !default;
$md-iconset-language-python-alt: '\f353' !default;
$md-iconset-language-python: '\f354' !default;
$md-iconset-lastfm: '\f355' !default;
$md-iconset-linkedin-box: '\f356' !default;
$md-iconset-paypal: '\f357' !default;
$md-iconset-pinterest-box: '\f358' !default;
$md-iconset-pocket: '\f359' !default;
$md-iconset-polymer: '\f35a' !default;
$md-iconset-share: '\f35b' !default;
$md-iconset-stack-overflow: '\f35c' !default;
$md-iconset-steam-square: '\f35d' !default;
$md-iconset-steam: '\f35e' !default;
$md-iconset-twitter-box: '\f35f' !default;
$md-iconset-twitter: '\f360' !default;
$md-iconset-vk: '\f361' !default;
$md-iconset-wikipedia: '\f362' !default;
$md-iconset-windows: '\f363' !default;
$md-iconset-aspect-ratio-alt: '\f364' !default;
$md-iconset-aspect-ratio: '\f365' !default;
$md-iconset-blur-circular: '\f366' !default;
$md-iconset-blur-linear: '\f367' !default;
$md-iconset-blur-off: '\f368' !default;
$md-iconset-blur: '\f369' !default;
$md-iconset-brightness-2: '\f36a' !default;
$md-iconset-brightness-3: '\f36b' !default;
$md-iconset-brightness-4: '\f36c' !default;
$md-iconset-brightness-5: '\f36d' !default;
$md-iconset-brightness-6: '\f36e' !default;
$md-iconset-brightness-7: '\f36f' !default;
$md-iconset-brightness-auto: '\f370' !default;
$md-iconset-brightness-setting: '\f371' !default;
$md-iconset-broken-image: '\f372' !default;
$md-iconset-center-focus-strong: '\f373' !default;
$md-iconset-center-focus-weak: '\f374' !default;
$md-iconset-compare: '\f375' !default;
$md-iconset-crop-16-9: '\f376' !default;
$md-iconset-crop-3-2: '\f377' !default;
$md-iconset-crop-5-4: '\f378' !default;
$md-iconset-crop-7-5: '\f379' !default;
$md-iconset-crop-din: '\f37a' !default;
$md-iconset-crop-free: '\f37b' !default;
$md-iconset-crop-landscape: '\f37c' !default;
$md-iconset-crop-portrait: '\f37d' !default;
$md-iconset-crop-square: '\f37e' !default;
$md-iconset-exposure-alt: '\f37f' !default;
$md-iconset-exposure: '\f380' !default;
$md-iconset-filter-b-and-w: '\f381' !default;
$md-iconset-filter-center-focus: '\f382' !default;
$md-iconset-filter-frames: '\f383' !default;
$md-iconset-filter-tilt-shift: '\f384' !default;
$md-iconset-gradient: '\f385' !default;
$md-iconset-grain: '\f386' !default;
$md-iconset-graphic-eq: '\f387' !default;
$md-iconset-hdr-off: '\f388' !default;
$md-iconset-hdr-strong: '\f389' !default;
$md-iconset-hdr-weak: '\f38a' !default;
$md-iconset-hdr: '\f38b' !default;
$md-iconset-iridescent: '\f38c' !default;
$md-iconset-leak-off: '\f38d' !default;
$md-iconset-leak: '\f38e' !default;
$md-iconset-looks: '\f38f' !default;
$md-iconset-loupe: '\f390' !default;
$md-iconset-panorama-horizontal: '\f391' !default;
$md-iconset-panorama-vertical: '\f392' !default;
$md-iconset-panorama-wide-angle: '\f393' !default;
$md-iconset-photo-size-select-large: '\f394' !default;
$md-iconset-photo-size-select-small: '\f395' !default;
$md-iconset-picture-in-picture: '\f396' !default;
$md-iconset-slideshow: '\f397' !default;
$md-iconset-texture: '\f398' !default;
$md-iconset-tonality: '\f399' !default;
$md-iconset-vignette: '\f39a' !default;
$md-iconset-wb-auto: '\f39b' !default;
$md-iconset-eject-alt: '\f39c' !default;
$md-iconset-eject: '\f39d' !default;
$md-iconset-equalizer: '\f39e' !default;
$md-iconset-fast-forward: '\f39f' !default;
$md-iconset-fast-rewind: '\f3a0' !default;
$md-iconset-forward-10: '\f3a1' !default;
$md-iconset-forward-30: '\f3a2' !default;
$md-iconset-forward-5: '\f3a3' !default;
$md-iconset-hearing: '\f3a4' !default;
$md-iconset-pause-circle-outline: '\f3a5' !default;
$md-iconset-pause-circle: '\f3a6' !default;
$md-iconset-pause: '\f3a7' !default;
$md-iconset-play-circle-outline: '\f3a8' !default;
$md-iconset-play-circle: '\f3a9' !default;
$md-iconset-play: '\f3aa' !default;
$md-iconset-playlist-audio: '\f3ab' !default;
$md-iconset-playlist-plus: '\f3ac' !default;
$md-iconset-repeat-one: '\f3ad' !default;
$md-iconset-repeat: '\f3ae' !default;
$md-iconset-replay-10: '\f3af' !default;
$md-iconset-replay-30: '\f3b0' !default;
$md-iconset-replay-5: '\f3b1' !default;
$md-iconset-replay: '\f3b2' !default;
$md-iconset-shuffle: '\f3b3' !default;
$md-iconset-skip-next: '\f3b4' !default;
$md-iconset-skip-previous: '\f3b5' !default;
$md-iconset-stop: '\f3b6' !default;
$md-iconset-surround-sound: '\f3b7' !default;
$md-iconset-tune: '\f3b8' !default;
$md-iconset-volume-down: '\f3b9' !default;
$md-iconset-volume-mute: '\f3ba' !default;
$md-iconset-volume-off: '\f3bb' !default;
$md-iconset-volume-up: '\f3bc' !default;
$md-iconset-n-1-square: '\f3bd' !default;
$md-iconset-n-2-square: '\f3be' !default;
$md-iconset-n-3-square: '\f3bf' !default;
$md-iconset-n-4-square: '\f3c0' !default;
$md-iconset-n-5-square: '\f3c1' !default;
$md-iconset-n-6-square: '\f3c2' !default;
$md-iconset-neg-1: '\f3c3' !default;
$md-iconset-neg-2: '\f3c4' !default;
$md-iconset-plus-1: '\f3c5' !default;
$md-iconset-plus-2: '\f3c6' !default;
$md-iconset-sec-10: '\f3c7' !default;
$md-iconset-sec-3: '\f3c8' !default;
$md-iconset-zero: '\f3c9' !default;
$md-iconset-airline-seat-flat-angled: '\f3ca' !default;
$md-iconset-airline-seat-flat: '\f3cb' !default;
$md-iconset-airline-seat-individual-suite: '\f3cc' !default;
$md-iconset-airline-seat-legroom-extra: '\f3cd' !default;
$md-iconset-airline-seat-legroom-normal: '\f3ce' !default;
$md-iconset-airline-seat-legroom-reduced: '\f3cf' !default;
$md-iconset-airline-seat-recline-extra: '\f3d0' !default;
$md-iconset-airline-seat-recline-normal: '\f3d1' !default;
$md-iconset-airplay: '\f3d2' !default;
$md-iconset-closed-caption: '\f3d3' !default;
$md-iconset-confirmation-number: '\f3d4' !default;
$md-iconset-developer-board: '\f3d5' !default;
$md-iconset-disc-full: '\f3d6' !default;
$md-iconset-explicit: '\f3d7' !default;
$md-iconset-flight-land: '\f3d8' !default;
$md-iconset-flight-takeoff: '\f3d9' !default;
$md-iconset-flip-to-back: '\f3da' !default;
$md-iconset-flip-to-front: '\f3db' !default;
$md-iconset-group-work: '\f3dc' !default;
$md-iconset-hd: '\f3dd' !default;
$md-iconset-hq: '\f3de' !default;
$md-iconset-markunread-mailbox: '\f3df' !default;
$md-iconset-memory: '\f3e0' !default;
$md-iconset-nfc: '\f3e1' !default;
$md-iconset-play-for-work: '\f3e2' !default;
$md-iconset-power-input: '\f3e3' !default;
$md-iconset-present-to-all: '\f3e4' !default;
$md-iconset-satellite: '\f3e5' !default;
$md-iconset-tap-and-play: '\f3e6' !default;
$md-iconset-vibration: '\f3e7' !default;
$md-iconset-voicemail: '\f3e8' !default;
.#{$md-icon-prefix}-3d-rotation {
  &:before {
    content: $md-iconset-3d-rotation;
  }
}
.#{$md-icon-prefix}-airplane-off {
  &:before {
    content: $md-iconset-airplane-off;
  }
}
.#{$md-icon-prefix}-airplane {
  &:before {
    content: $md-iconset-airplane;
  }
}
.#{$md-icon-prefix}-album {
  &:before {
    content: $md-iconset-album;
  }
}
.#{$md-icon-prefix}-archive {
  &:before {
    content: $md-iconset-archive;
  }
}
.#{$md-icon-prefix}-assignment-account {
  &:before {
    content: $md-iconset-assignment-account;
  }
}
.#{$md-icon-prefix}-assignment-alert {
  &:before {
    content: $md-iconset-assignment-alert;
  }
}
.#{$md-icon-prefix}-assignment-check {
  &:before {
    content: $md-iconset-assignment-check;
  }
}
.#{$md-icon-prefix}-assignment-o {
  &:before {
    content: $md-iconset-assignment-o;
  }
}
.#{$md-icon-prefix}-assignment-return {
  &:before {
    content: $md-iconset-assignment-return;
  }
}
.#{$md-icon-prefix}-assignment-returned {
  &:before {
    content: $md-iconset-assignment-returned;
  }
}
.#{$md-icon-prefix}-assignment {
  &:before {
    content: $md-iconset-assignment;
  }
}
.#{$md-icon-prefix}-attachment-alt {
  &:before {
    content: $md-iconset-attachment-alt;
  }
}
.#{$md-icon-prefix}-attachment {
  &:before {
    content: $md-iconset-attachment;
  }
}
.#{$md-icon-prefix}-audio {
  &:before {
    content: $md-iconset-audio;
  }
}
.#{$md-icon-prefix}-badge-check {
  &:before {
    content: $md-iconset-badge-check;
  }
}
.#{$md-icon-prefix}-balance-wallet {
  &:before {
    content: $md-iconset-balance-wallet;
  }
}
.#{$md-icon-prefix}-balance {
  &:before {
    content: $md-iconset-balance;
  }
}
.#{$md-icon-prefix}-battery-alert {
  &:before {
    content: $md-iconset-battery-alert;
  }
}
.#{$md-icon-prefix}-battery-flash {
  &:before {
    content: $md-iconset-battery-flash;
  }
}
.#{$md-icon-prefix}-battery-unknown {
  &:before {
    content: $md-iconset-battery-unknown;
  }
}
.#{$md-icon-prefix}-battery {
  &:before {
    content: $md-iconset-battery;
  }
}
.#{$md-icon-prefix}-bike {
  &:before {
    content: $md-iconset-bike;
  }
}
.#{$md-icon-prefix}-block-alt {
  &:before {
    content: $md-iconset-block-alt;
  }
}
.#{$md-icon-prefix}-block {
  &:before {
    content: $md-iconset-block;
  }
}
.#{$md-icon-prefix}-boat {
  &:before {
    content: $md-iconset-boat;
  }
}
.#{$md-icon-prefix}-book-image {
  &:before {
    content: $md-iconset-book-image;
  }
}
.#{$md-icon-prefix}-book {
  &:before {
    content: $md-iconset-book;
  }
}
.#{$md-icon-prefix}-bookmark-outline {
  &:before {
    content: $md-iconset-bookmark-outline;
  }
}
.#{$md-icon-prefix}-bookmark {
  &:before {
    content: $md-iconset-bookmark;
  }
}
.#{$md-icon-prefix}-brush {
  &:before {
    content: $md-iconset-brush;
  }
}
.#{$md-icon-prefix}-bug {
  &:before {
    content: $md-iconset-bug;
  }
}
.#{$md-icon-prefix}-bus {
  &:before {
    content: $md-iconset-bus;
  }
}
.#{$md-icon-prefix}-cake {
  &:before {
    content: $md-iconset-cake;
  }
}
.#{$md-icon-prefix}-car-taxi {
  &:before {
    content: $md-iconset-car-taxi;
  }
}
.#{$md-icon-prefix}-car-wash {
  &:before {
    content: $md-iconset-car-wash;
  }
}
.#{$md-icon-prefix}-car {
  &:before {
    content: $md-iconset-car;
  }
}
.#{$md-icon-prefix}-card-giftcard {
  &:before {
    content: $md-iconset-card-giftcard;
  }
}
.#{$md-icon-prefix}-card-membership {
  &:before {
    content: $md-iconset-card-membership;
  }
}
.#{$md-icon-prefix}-card-travel {
  &:before {
    content: $md-iconset-card-travel;
  }
}
.#{$md-icon-prefix}-card {
  &:before {
    content: $md-iconset-card;
  }
}
.#{$md-icon-prefix}-case-check {
  &:before {
    content: $md-iconset-case-check;
  }
}
.#{$md-icon-prefix}-case-download {
  &:before {
    content: $md-iconset-case-download;
  }
}
.#{$md-icon-prefix}-case-play {
  &:before {
    content: $md-iconset-case-play;
  }
}
.#{$md-icon-prefix}-case {
  &:before {
    content: $md-iconset-case;
  }
}
.#{$md-icon-prefix}-cast-connected {
  &:before {
    content: $md-iconset-cast-connected;
  }
}
.#{$md-icon-prefix}-cast {
  &:before {
    content: $md-iconset-cast;
  }
}
.#{$md-icon-prefix}-chart-donut {
  &:before {
    content: $md-iconset-chart-donut;
  }
}
.#{$md-icon-prefix}-chart {
  &:before {
    content: $md-iconset-chart;
  }
}
.#{$md-icon-prefix}-city-alt {
  &:before {
    content: $md-iconset-city-alt;
  }
}
.#{$md-icon-prefix}-city {
  &:before {
    content: $md-iconset-city;
  }
}
.#{$md-icon-prefix}-close-circle-o {
  &:before {
    content: $md-iconset-close-circle-o;
  }
}
.#{$md-icon-prefix}-close-circle {
  &:before {
    content: $md-iconset-close-circle;
  }
}
.#{$md-icon-prefix}-close {
  &:before {
    content: $md-iconset-close;
  }
}
.#{$md-icon-prefix}-cocktail {
  &:before {
    content: $md-iconset-cocktail;
  }
}
.#{$md-icon-prefix}-code-setting {
  &:before {
    content: $md-iconset-code-setting;
  }
}
.#{$md-icon-prefix}-code-smartphone {
  &:before {
    content: $md-iconset-code-smartphone;
  }
}
.#{$md-icon-prefix}-code {
  &:before {
    content: $md-iconset-code;
  }
}
.#{$md-icon-prefix}-coffee {
  &:before {
    content: $md-iconset-coffee;
  }
}
.#{$md-icon-prefix}-collection-bookmark {
  &:before {
    content: $md-iconset-collection-bookmark;
  }
}
.#{$md-icon-prefix}-collection-case-play {
  &:before {
    content: $md-iconset-collection-case-play;
  }
}
.#{$md-icon-prefix}-collection-folder-image {
  &:before {
    content: $md-iconset-collection-folder-image;
  }
}
.#{$md-icon-prefix}-collection-image-o {
  &:before {
    content: $md-iconset-collection-image-o;
  }
}
.#{$md-icon-prefix}-collection-image {
  &:before {
    content: $md-iconset-collection-image;
  }
}
.#{$md-icon-prefix}-collection-item-1 {
  &:before {
    content: $md-iconset-collection-item-1;
  }
}
.#{$md-icon-prefix}-collection-item-2 {
  &:before {
    content: $md-iconset-collection-item-2;
  }
}
.#{$md-icon-prefix}-collection-item-3 {
  &:before {
    content: $md-iconset-collection-item-3;
  }
}
.#{$md-icon-prefix}-collection-item-4 {
  &:before {
    content: $md-iconset-collection-item-4;
  }
}
.#{$md-icon-prefix}-collection-item-5 {
  &:before {
    content: $md-iconset-collection-item-5;
  }
}
.#{$md-icon-prefix}-collection-item-6 {
  &:before {
    content: $md-iconset-collection-item-6;
  }
}
.#{$md-icon-prefix}-collection-item-7 {
  &:before {
    content: $md-iconset-collection-item-7;
  }
}
.#{$md-icon-prefix}-collection-item-8 {
  &:before {
    content: $md-iconset-collection-item-8;
  }
}
.#{$md-icon-prefix}-collection-item-9-plus {
  &:before {
    content: $md-iconset-collection-item-9-plus;
  }
}
.#{$md-icon-prefix}-collection-item-9 {
  &:before {
    content: $md-iconset-collection-item-9;
  }
}
.#{$md-icon-prefix}-collection-item {
  &:before {
    content: $md-iconset-collection-item;
  }
}
.#{$md-icon-prefix}-collection-music {
  &:before {
    content: $md-iconset-collection-music;
  }
}
.#{$md-icon-prefix}-collection-pdf {
  &:before {
    content: $md-iconset-collection-pdf;
  }
}
.#{$md-icon-prefix}-collection-plus {
  &:before {
    content: $md-iconset-collection-plus;
  }
}
.#{$md-icon-prefix}-collection-speaker {
  &:before {
    content: $md-iconset-collection-speaker;
  }
}
.#{$md-icon-prefix}-collection-text {
  &:before {
    content: $md-iconset-collection-text;
  }
}
.#{$md-icon-prefix}-collection-video {
  &:before {
    content: $md-iconset-collection-video;
  }
}
.#{$md-icon-prefix}-compass {
  &:before {
    content: $md-iconset-compass;
  }
}
.#{$md-icon-prefix}-cutlery {
  &:before {
    content: $md-iconset-cutlery;
  }
}
.#{$md-icon-prefix}-delete {
  &:before {
    content: $md-iconset-delete;
  }
}
.#{$md-icon-prefix}-dialpad {
  &:before {
    content: $md-iconset-dialpad;
  }
}
.#{$md-icon-prefix}-dns {
  &:before {
    content: $md-iconset-dns;
  }
}
.#{$md-icon-prefix}-drink {
  &:before {
    content: $md-iconset-drink;
  }
}
.#{$md-icon-prefix}-edit {
  &:before {
    content: $md-iconset-edit;
  }
}
.#{$md-icon-prefix}-email-open {
  &:before {
    content: $md-iconset-email-open;
  }
}
.#{$md-icon-prefix}-email {
  &:before {
    content: $md-iconset-email;
  }
}
.#{$md-icon-prefix}-eye-off {
  &:before {
    content: $md-iconset-eye-off;
  }
}
.#{$md-icon-prefix}-eye {
  &:before {
    content: $md-iconset-eye;
  }
}
.#{$md-icon-prefix}-eyedropper {
  &:before {
    content: $md-iconset-eyedropper;
  }
}
.#{$md-icon-prefix}-favorite-outline {
  &:before {
    content: $md-iconset-favorite-outline;
  }
}
.#{$md-icon-prefix}-favorite {
  &:before {
    content: $md-iconset-favorite;
  }
}
.#{$md-icon-prefix}-filter-list {
  &:before {
    content: $md-iconset-filter-list;
  }
}
.#{$md-icon-prefix}-fire {
  &:before {
    content: $md-iconset-fire;
  }
}
.#{$md-icon-prefix}-flag {
  &:before {
    content: $md-iconset-flag;
  }
}
.#{$md-icon-prefix}-flare {
  &:before {
    content: $md-iconset-flare;
  }
}
.#{$md-icon-prefix}-flash-auto {
  &:before {
    content: $md-iconset-flash-auto;
  }
}
.#{$md-icon-prefix}-flash-off {
  &:before {
    content: $md-iconset-flash-off;
  }
}
.#{$md-icon-prefix}-flash {
  &:before {
    content: $md-iconset-flash;
  }
}
.#{$md-icon-prefix}-flip {
  &:before {
    content: $md-iconset-flip;
  }
}
.#{$md-icon-prefix}-flower-alt {
  &:before {
    content: $md-iconset-flower-alt;
  }
}
.#{$md-icon-prefix}-flower {
  &:before {
    content: $md-iconset-flower;
  }
}
.#{$md-icon-prefix}-font {
  &:before {
    content: $md-iconset-font;
  }
}
.#{$md-icon-prefix}-fullscreen-alt {
  &:before {
    content: $md-iconset-fullscreen-alt;
  }
}
.#{$md-icon-prefix}-fullscreen-exit {
  &:before {
    content: $md-iconset-fullscreen-exit;
  }
}
.#{$md-icon-prefix}-fullscreen {
  &:before {
    content: $md-iconset-fullscreen;
  }
}
.#{$md-icon-prefix}-functions {
  &:before {
    content: $md-iconset-functions;
  }
}
.#{$md-icon-prefix}-gas-station {
  &:before {
    content: $md-iconset-gas-station;
  }
}
.#{$md-icon-prefix}-gesture {
  &:before {
    content: $md-iconset-gesture;
  }
}
.#{$md-icon-prefix}-globe-alt {
  &:before {
    content: $md-iconset-globe-alt;
  }
}
.#{$md-icon-prefix}-globe-lock {
  &:before {
    content: $md-iconset-globe-lock;
  }
}
.#{$md-icon-prefix}-globe {
  &:before {
    content: $md-iconset-globe;
  }
}
.#{$md-icon-prefix}-graduation-cap {
  &:before {
    content: $md-iconset-graduation-cap;
  }
}
.#{$md-icon-prefix}-home {
  &:before {
    content: $md-iconset-home;
  }
}
.#{$md-icon-prefix}-hospital-alt {
  &:before {
    content: $md-iconset-hospital-alt;
  }
}
.#{$md-icon-prefix}-hospital {
  &:before {
    content: $md-iconset-hospital;
  }
}
.#{$md-icon-prefix}-hotel {
  &:before {
    content: $md-iconset-hotel;
  }
}
.#{$md-icon-prefix}-hourglass-alt {
  &:before {
    content: $md-iconset-hourglass-alt;
  }
}
.#{$md-icon-prefix}-hourglass-outline {
  &:before {
    content: $md-iconset-hourglass-outline;
  }
}
.#{$md-icon-prefix}-hourglass {
  &:before {
    content: $md-iconset-hourglass;
  }
}
.#{$md-icon-prefix}-http {
  &:before {
    content: $md-iconset-http;
  }
}
.#{$md-icon-prefix}-image-alt {
  &:before {
    content: $md-iconset-image-alt;
  }
}
.#{$md-icon-prefix}-image-o {
  &:before {
    content: $md-iconset-image-o;
  }
}
.#{$md-icon-prefix}-image {
  &:before {
    content: $md-iconset-image;
  }
}
.#{$md-icon-prefix}-inbox {
  &:before {
    content: $md-iconset-inbox;
  }
}
.#{$md-icon-prefix}-invert-colors-off {
  &:before {
    content: $md-iconset-invert-colors-off;
  }
}
.#{$md-icon-prefix}-invert-colors {
  &:before {
    content: $md-iconset-invert-colors;
  }
}
.#{$md-icon-prefix}-key {
  &:before {
    content: $md-iconset-key;
  }
}
.#{$md-icon-prefix}-label-alt-outline {
  &:before {
    content: $md-iconset-label-alt-outline;
  }
}
.#{$md-icon-prefix}-label-alt {
  &:before {
    content: $md-iconset-label-alt;
  }
}
.#{$md-icon-prefix}-label-heart {
  &:before {
    content: $md-iconset-label-heart;
  }
}
.#{$md-icon-prefix}-label {
  &:before {
    content: $md-iconset-label;
  }
}
.#{$md-icon-prefix}-labels {
  &:before {
    content: $md-iconset-labels;
  }
}
.#{$md-icon-prefix}-lamp {
  &:before {
    content: $md-iconset-lamp;
  }
}
.#{$md-icon-prefix}-landscape {
  &:before {
    content: $md-iconset-landscape;
  }
}
.#{$md-icon-prefix}-layers-off {
  &:before {
    content: $md-iconset-layers-off;
  }
}
.#{$md-icon-prefix}-layers {
  &:before {
    content: $md-iconset-layers;
  }
}
.#{$md-icon-prefix}-library {
  &:before {
    content: $md-iconset-library;
  }
}
.#{$md-icon-prefix}-link {
  &:before {
    content: $md-iconset-link;
  }
}
.#{$md-icon-prefix}-lock-open {
  &:before {
    content: $md-iconset-lock-open;
  }
}
.#{$md-icon-prefix}-lock-outline {
  &:before {
    content: $md-iconset-lock-outline;
  }
}
.#{$md-icon-prefix}-lock {
  &:before {
    content: $md-iconset-lock;
  }
}
.#{$md-icon-prefix}-mail-reply-all {
  &:before {
    content: $md-iconset-mail-reply-all;
  }
}
.#{$md-icon-prefix}-mail-reply {
  &:before {
    content: $md-iconset-mail-reply;
  }
}
.#{$md-icon-prefix}-mail-send {
  &:before {
    content: $md-iconset-mail-send;
  }
}
.#{$md-icon-prefix}-mall {
  &:before {
    content: $md-iconset-mall;
  }
}
.#{$md-icon-prefix}-map {
  &:before {
    content: $md-iconset-map;
  }
}
.#{$md-icon-prefix}-menu {
  &:before {
    content: $md-iconset-menu;
  }
}
.#{$md-icon-prefix}-money-box {
  &:before {
    content: $md-iconset-money-box;
  }
}
.#{$md-icon-prefix}-money-off {
  &:before {
    content: $md-iconset-money-off;
  }
}
.#{$md-icon-prefix}-money {
  &:before {
    content: $md-iconset-money;
  }
}
.#{$md-icon-prefix}-more-vert {
  &:before {
    content: $md-iconset-more-vert;
  }
}
.#{$md-icon-prefix}-more {
  &:before {
    content: $md-iconset-more;
  }
}
.#{$md-icon-prefix}-movie-alt {
  &:before {
    content: $md-iconset-movie-alt;
  }
}
.#{$md-icon-prefix}-movie {
  &:before {
    content: $md-iconset-movie;
  }
}
.#{$md-icon-prefix}-nature-people {
  &:before {
    content: $md-iconset-nature-people;
  }
}
.#{$md-icon-prefix}-nature {
  &:before {
    content: $md-iconset-nature;
  }
}
.#{$md-icon-prefix}-navigation {
  &:before {
    content: $md-iconset-navigation;
  }
}
.#{$md-icon-prefix}-open-in-browser {
  &:before {
    content: $md-iconset-open-in-browser;
  }
}
.#{$md-icon-prefix}-open-in-new {
  &:before {
    content: $md-iconset-open-in-new;
  }
}
.#{$md-icon-prefix}-palette {
  &:before {
    content: $md-iconset-palette;
  }
}
.#{$md-icon-prefix}-parking {
  &:before {
    content: $md-iconset-parking;
  }
}
.#{$md-icon-prefix}-pin-account {
  &:before {
    content: $md-iconset-pin-account;
  }
}
.#{$md-icon-prefix}-pin-assistant {
  &:before {
    content: $md-iconset-pin-assistant;
  }
}
.#{$md-icon-prefix}-pin-drop {
  &:before {
    content: $md-iconset-pin-drop;
  }
}
.#{$md-icon-prefix}-pin-help {
  &:before {
    content: $md-iconset-pin-help;
  }
}
.#{$md-icon-prefix}-pin-off {
  &:before {
    content: $md-iconset-pin-off;
  }
}
.#{$md-icon-prefix}-pin {
  &:before {
    content: $md-iconset-pin;
  }
}
.#{$md-icon-prefix}-pizza {
  &:before {
    content: $md-iconset-pizza;
  }
}
.#{$md-icon-prefix}-plaster {
  &:before {
    content: $md-iconset-plaster;
  }
}
.#{$md-icon-prefix}-power-setting {
  &:before {
    content: $md-iconset-power-setting;
  }
}
.#{$md-icon-prefix}-power {
  &:before {
    content: $md-iconset-power;
  }
}
.#{$md-icon-prefix}-print {
  &:before {
    content: $md-iconset-print;
  }
}
.#{$md-icon-prefix}-puzzle-piece {
  &:before {
    content: $md-iconset-puzzle-piece;
  }
}
.#{$md-icon-prefix}-quote {
  &:before {
    content: $md-iconset-quote;
  }
}
.#{$md-icon-prefix}-railway {
  &:before {
    content: $md-iconset-railway;
  }
}
.#{$md-icon-prefix}-receipt {
  &:before {
    content: $md-iconset-receipt;
  }
}
.#{$md-icon-prefix}-refresh-alt {
  &:before {
    content: $md-iconset-refresh-alt;
  }
}
.#{$md-icon-prefix}-refresh-sync-alert {
  &:before {
    content: $md-iconset-refresh-sync-alert;
  }
}
.#{$md-icon-prefix}-refresh-sync-off {
  &:before {
    content: $md-iconset-refresh-sync-off;
  }
}
.#{$md-icon-prefix}-refresh-sync {
  &:before {
    content: $md-iconset-refresh-sync;
  }
}
.#{$md-icon-prefix}-refresh {
  &:before {
    content: $md-iconset-refresh;
  }
}
.#{$md-icon-prefix}-roller {
  &:before {
    content: $md-iconset-roller;
  }
}
.#{$md-icon-prefix}-ruler {
  &:before {
    content: $md-iconset-ruler;
  }
}
.#{$md-icon-prefix}-scissors {
  &:before {
    content: $md-iconset-scissors;
  }
}
.#{$md-icon-prefix}-screen-rotation-lock {
  &:before {
    content: $md-iconset-screen-rotation-lock;
  }
}
.#{$md-icon-prefix}-screen-rotation {
  &:before {
    content: $md-iconset-screen-rotation;
  }
}
.#{$md-icon-prefix}-search-for {
  &:before {
    content: $md-iconset-search-for;
  }
}
.#{$md-icon-prefix}-search-in-file {
  &:before {
    content: $md-iconset-search-in-file;
  }
}
.#{$md-icon-prefix}-search-in-page {
  &:before {
    content: $md-iconset-search-in-page;
  }
}
.#{$md-icon-prefix}-search-replace {
  &:before {
    content: $md-iconset-search-replace;
  }
}
.#{$md-icon-prefix}-search {
  &:before {
    content: $md-iconset-search;
  }
}
.#{$md-icon-prefix}-seat {
  &:before {
    content: $md-iconset-seat;
  }
}
.#{$md-icon-prefix}-settings-square {
  &:before {
    content: $md-iconset-settings-square;
  }
}
.#{$md-icon-prefix}-settings {
  &:before {
    content: $md-iconset-settings;
  }
}
.#{$md-icon-prefix}-shield-check {
  &:before {
    content: $md-iconset-shield-check;
  }
}
.#{$md-icon-prefix}-shield-security {
  &:before {
    content: $md-iconset-shield-security;
  }
}
.#{$md-icon-prefix}-shopping-basket {
  &:before {
    content: $md-iconset-shopping-basket;
  }
}
.#{$md-icon-prefix}-shopping-cart-plus {
  &:before {
    content: $md-iconset-shopping-cart-plus;
  }
}
.#{$md-icon-prefix}-shopping-cart {
  &:before {
    content: $md-iconset-shopping-cart;
  }
}
.#{$md-icon-prefix}-sign-in {
  &:before {
    content: $md-iconset-sign-in;
  }
}
.#{$md-icon-prefix}-sort-amount-asc {
  &:before {
    content: $md-iconset-sort-amount-asc;
  }
}
.#{$md-icon-prefix}-sort-amount-desc {
  &:before {
    content: $md-iconset-sort-amount-desc;
  }
}
.#{$md-icon-prefix}-sort-asc {
  &:before {
    content: $md-iconset-sort-asc;
  }
}
.#{$md-icon-prefix}-sort-desc {
  &:before {
    content: $md-iconset-sort-desc;
  }
}
.#{$md-icon-prefix}-spellcheck {
  &:before {
    content: $md-iconset-spellcheck;
  }
}
.#{$md-icon-prefix}-storage {
  &:before {
    content: $md-iconset-storage;
  }
}
.#{$md-icon-prefix}-store-24 {
  &:before {
    content: $md-iconset-store-24;
  }
}
.#{$md-icon-prefix}-store {
  &:before {
    content: $md-iconset-store;
  }
}
.#{$md-icon-prefix}-subway {
  &:before {
    content: $md-iconset-subway;
  }
}
.#{$md-icon-prefix}-sun {
  &:before {
    content: $md-iconset-sun;
  }
}
.#{$md-icon-prefix}-tab-unselected {
  &:before {
    content: $md-iconset-tab-unselected;
  }
}
.#{$md-icon-prefix}-tab {
  &:before {
    content: $md-iconset-tab;
  }
}
.#{$md-icon-prefix}-tag-close {
  &:before {
    content: $md-iconset-tag-close;
  }
}
.#{$md-icon-prefix}-tag-more {
  &:before {
    content: $md-iconset-tag-more;
  }
}
.#{$md-icon-prefix}-tag {
  &:before {
    content: $md-iconset-tag;
  }
}
.#{$md-icon-prefix}-thumb-down {
  &:before {
    content: $md-iconset-thumb-down;
  }
}
.#{$md-icon-prefix}-thumb-up-down {
  &:before {
    content: $md-iconset-thumb-up-down;
  }
}
.#{$md-icon-prefix}-thumb-up {
  &:before {
    content: $md-iconset-thumb-up;
  }
}
.#{$md-icon-prefix}-ticket-star {
  &:before {
    content: $md-iconset-ticket-star;
  }
}
.#{$md-icon-prefix}-toll {
  &:before {
    content: $md-iconset-toll;
  }
}
.#{$md-icon-prefix}-toys {
  &:before {
    content: $md-iconset-toys;
  }
}
.#{$md-icon-prefix}-traffic {
  &:before {
    content: $md-iconset-traffic;
  }
}
.#{$md-icon-prefix}-translate {
  &:before {
    content: $md-iconset-translate;
  }
}
.#{$md-icon-prefix}-triangle-down {
  &:before {
    content: $md-iconset-triangle-down;
  }
}
.#{$md-icon-prefix}-triangle-up {
  &:before {
    content: $md-iconset-triangle-up;
  }
}
.#{$md-icon-prefix}-truck {
  &:before {
    content: $md-iconset-truck;
  }
}
.#{$md-icon-prefix}-turning-sign {
  &:before {
    content: $md-iconset-turning-sign;
  }
}
.#{$md-icon-prefix}-wallpaper {
  &:before {
    content: $md-iconset-wallpaper;
  }
}
.#{$md-icon-prefix}-washing-machine {
  &:before {
    content: $md-iconset-washing-machine;
  }
}
.#{$md-icon-prefix}-window-maximize {
  &:before {
    content: $md-iconset-window-maximize;
  }
}
.#{$md-icon-prefix}-window-minimize {
  &:before {
    content: $md-iconset-window-minimize;
  }
}
.#{$md-icon-prefix}-window-restore {
  &:before {
    content: $md-iconset-window-restore;
  }
}
.#{$md-icon-prefix}-wrench {
  &:before {
    content: $md-iconset-wrench;
  }
}
.#{$md-icon-prefix}-zoom-in {
  &:before {
    content: $md-iconset-zoom-in;
  }
}
.#{$md-icon-prefix}-zoom-out {
  &:before {
    content: $md-iconset-zoom-out;
  }
}
.#{$md-icon-prefix}-alert-circle-o {
  &:before {
    content: $md-iconset-alert-circle-o;
  }
}
.#{$md-icon-prefix}-alert-circle {
  &:before {
    content: $md-iconset-alert-circle;
  }
}
.#{$md-icon-prefix}-alert-octagon {
  &:before {
    content: $md-iconset-alert-octagon;
  }
}
.#{$md-icon-prefix}-alert-polygon {
  &:before {
    content: $md-iconset-alert-polygon;
  }
}
.#{$md-icon-prefix}-alert-triangle {
  &:before {
    content: $md-iconset-alert-triangle;
  }
}
.#{$md-icon-prefix}-help-outline {
  &:before {
    content: $md-iconset-help-outline;
  }
}
.#{$md-icon-prefix}-help {
  &:before {
    content: $md-iconset-help;
  }
}
.#{$md-icon-prefix}-info-outline {
  &:before {
    content: $md-iconset-info-outline;
  }
}
.#{$md-icon-prefix}-info {
  &:before {
    content: $md-iconset-info;
  }
}
.#{$md-icon-prefix}-notifications-active {
  &:before {
    content: $md-iconset-notifications-active;
  }
}
.#{$md-icon-prefix}-notifications-add {
  &:before {
    content: $md-iconset-notifications-add;
  }
}
.#{$md-icon-prefix}-notifications-none {
  &:before {
    content: $md-iconset-notifications-none;
  }
}
.#{$md-icon-prefix}-notifications-off {
  &:before {
    content: $md-iconset-notifications-off;
  }
}
.#{$md-icon-prefix}-notifications-paused {
  &:before {
    content: $md-iconset-notifications-paused;
  }
}
.#{$md-icon-prefix}-notifications {
  &:before {
    content: $md-iconset-notifications;
  }
}
.#{$md-icon-prefix}-account-add {
  &:before {
    content: $md-iconset-account-add;
  }
}
.#{$md-icon-prefix}-account-box-mail {
  &:before {
    content: $md-iconset-account-box-mail;
  }
}
.#{$md-icon-prefix}-account-box-o {
  &:before {
    content: $md-iconset-account-box-o;
  }
}
.#{$md-icon-prefix}-account-box-phone {
  &:before {
    content: $md-iconset-account-box-phone;
  }
}
.#{$md-icon-prefix}-account-box {
  &:before {
    content: $md-iconset-account-box;
  }
}
.#{$md-icon-prefix}-account-calendar {
  &:before {
    content: $md-iconset-account-calendar;
  }
}
.#{$md-icon-prefix}-account-circle {
  &:before {
    content: $md-iconset-account-circle;
  }
}
.#{$md-icon-prefix}-account-o {
  &:before {
    content: $md-iconset-account-o;
  }
}
.#{$md-icon-prefix}-account {
  &:before {
    content: $md-iconset-account;
  }
}
.#{$md-icon-prefix}-accounts-add {
  &:before {
    content: $md-iconset-accounts-add;
  }
}
.#{$md-icon-prefix}-accounts-alt {
  &:before {
    content: $md-iconset-accounts-alt;
  }
}
.#{$md-icon-prefix}-accounts-list-alt {
  &:before {
    content: $md-iconset-accounts-list-alt;
  }
}
.#{$md-icon-prefix}-accounts-list {
  &:before {
    content: $md-iconset-accounts-list;
  }
}
.#{$md-icon-prefix}-accounts-outline {
  &:before {
    content: $md-iconset-accounts-outline;
  }
}
.#{$md-icon-prefix}-accounts {
  &:before {
    content: $md-iconset-accounts;
  }
}
.#{$md-icon-prefix}-face {
  &:before {
    content: $md-iconset-face;
  }
}
.#{$md-icon-prefix}-female {
  &:before {
    content: $md-iconset-female;
  }
}
.#{$md-icon-prefix}-male-alt {
  &:before {
    content: $md-iconset-male-alt;
  }
}
.#{$md-icon-prefix}-male-female {
  &:before {
    content: $md-iconset-male-female;
  }
}
.#{$md-icon-prefix}-male {
  &:before {
    content: $md-iconset-male;
  }
}
.#{$md-icon-prefix}-mood-bad {
  &:before {
    content: $md-iconset-mood-bad;
  }
}
.#{$md-icon-prefix}-mood {
  &:before {
    content: $md-iconset-mood;
  }
}
.#{$md-icon-prefix}-run {
  &:before {
    content: $md-iconset-run;
  }
}
.#{$md-icon-prefix}-walk {
  &:before {
    content: $md-iconset-walk;
  }
}
.#{$md-icon-prefix}-cloud-box {
  &:before {
    content: $md-iconset-cloud-box;
  }
}
.#{$md-icon-prefix}-cloud-circle {
  &:before {
    content: $md-iconset-cloud-circle;
  }
}
.#{$md-icon-prefix}-cloud-done {
  &:before {
    content: $md-iconset-cloud-done;
  }
}
.#{$md-icon-prefix}-cloud-download {
  &:before {
    content: $md-iconset-cloud-download;
  }
}
.#{$md-icon-prefix}-cloud-off {
  &:before {
    content: $md-iconset-cloud-off;
  }
}
.#{$md-icon-prefix}-cloud-outline-alt {
  &:before {
    content: $md-iconset-cloud-outline-alt;
  }
}
.#{$md-icon-prefix}-cloud-outline {
  &:before {
    content: $md-iconset-cloud-outline;
  }
}
.#{$md-icon-prefix}-cloud-upload {
  &:before {
    content: $md-iconset-cloud-upload;
  }
}
.#{$md-icon-prefix}-cloud {
  &:before {
    content: $md-iconset-cloud;
  }
}
.#{$md-icon-prefix}-download {
  &:before {
    content: $md-iconset-download;
  }
}
.#{$md-icon-prefix}-file-plus {
  &:before {
    content: $md-iconset-file-plus;
  }
}
.#{$md-icon-prefix}-file-text {
  &:before {
    content: $md-iconset-file-text;
  }
}
.#{$md-icon-prefix}-file {
  &:before {
    content: $md-iconset-file;
  }
}
.#{$md-icon-prefix}-folder-outline {
  &:before {
    content: $md-iconset-folder-outline;
  }
}
.#{$md-icon-prefix}-folder-person {
  &:before {
    content: $md-iconset-folder-person;
  }
}
.#{$md-icon-prefix}-folder-star-alt {
  &:before {
    content: $md-iconset-folder-star-alt;
  }
}
.#{$md-icon-prefix}-folder-star {
  &:before {
    content: $md-iconset-folder-star;
  }
}
.#{$md-icon-prefix}-folder {
  &:before {
    content: $md-iconset-folder;
  }
}
.#{$md-icon-prefix}-gif {
  &:before {
    content: $md-iconset-gif;
  }
}
.#{$md-icon-prefix}-upload {
  &:before {
    content: $md-iconset-upload;
  }
}
.#{$md-icon-prefix}-border-all {
  &:before {
    content: $md-iconset-border-all;
  }
}
.#{$md-icon-prefix}-border-bottom {
  &:before {
    content: $md-iconset-border-bottom;
  }
}
.#{$md-icon-prefix}-border-clear {
  &:before {
    content: $md-iconset-border-clear;
  }
}
.#{$md-icon-prefix}-border-color {
  &:before {
    content: $md-iconset-border-color;
  }
}
.#{$md-icon-prefix}-border-horizontal {
  &:before {
    content: $md-iconset-border-horizontal;
  }
}
.#{$md-icon-prefix}-border-inner {
  &:before {
    content: $md-iconset-border-inner;
  }
}
.#{$md-icon-prefix}-border-left {
  &:before {
    content: $md-iconset-border-left;
  }
}
.#{$md-icon-prefix}-border-outer {
  &:before {
    content: $md-iconset-border-outer;
  }
}
.#{$md-icon-prefix}-border-right {
  &:before {
    content: $md-iconset-border-right;
  }
}
.#{$md-icon-prefix}-border-style {
  &:before {
    content: $md-iconset-border-style;
  }
}
.#{$md-icon-prefix}-border-top {
  &:before {
    content: $md-iconset-border-top;
  }
}
.#{$md-icon-prefix}-border-vertical {
  &:before {
    content: $md-iconset-border-vertical;
  }
}
.#{$md-icon-prefix}-copy {
  &:before {
    content: $md-iconset-copy;
  }
}
.#{$md-icon-prefix}-crop {
  &:before {
    content: $md-iconset-crop;
  }
}
.#{$md-icon-prefix}-format-align-center {
  &:before {
    content: $md-iconset-format-align-center;
  }
}
.#{$md-icon-prefix}-format-align-justify {
  &:before {
    content: $md-iconset-format-align-justify;
  }
}
.#{$md-icon-prefix}-format-align-left {
  &:before {
    content: $md-iconset-format-align-left;
  }
}
.#{$md-icon-prefix}-format-align-right {
  &:before {
    content: $md-iconset-format-align-right;
  }
}
.#{$md-icon-prefix}-format-bold {
  &:before {
    content: $md-iconset-format-bold;
  }
}
.#{$md-icon-prefix}-format-clear-all {
  &:before {
    content: $md-iconset-format-clear-all;
  }
}
.#{$md-icon-prefix}-format-clear {
  &:before {
    content: $md-iconset-format-clear;
  }
}
.#{$md-icon-prefix}-format-color-fill {
  &:before {
    content: $md-iconset-format-color-fill;
  }
}
.#{$md-icon-prefix}-format-color-reset {
  &:before {
    content: $md-iconset-format-color-reset;
  }
}
.#{$md-icon-prefix}-format-color-text {
  &:before {
    content: $md-iconset-format-color-text;
  }
}
.#{$md-icon-prefix}-format-indent-decrease {
  &:before {
    content: $md-iconset-format-indent-decrease;
  }
}
.#{$md-icon-prefix}-format-indent-increase {
  &:before {
    content: $md-iconset-format-indent-increase;
  }
}
.#{$md-icon-prefix}-format-italic {
  &:before {
    content: $md-iconset-format-italic;
  }
}
.#{$md-icon-prefix}-format-line-spacing {
  &:before {
    content: $md-iconset-format-line-spacing;
  }
}
.#{$md-icon-prefix}-format-list-bulleted {
  &:before {
    content: $md-iconset-format-list-bulleted;
  }
}
.#{$md-icon-prefix}-format-list-numbered {
  &:before {
    content: $md-iconset-format-list-numbered;
  }
}
.#{$md-icon-prefix}-format-ltr {
  &:before {
    content: $md-iconset-format-ltr;
  }
}
.#{$md-icon-prefix}-format-rtl {
  &:before {
    content: $md-iconset-format-rtl;
  }
}
.#{$md-icon-prefix}-format-size {
  &:before {
    content: $md-iconset-format-size;
  }
}
.#{$md-icon-prefix}-format-strikethrough-s {
  &:before {
    content: $md-iconset-format-strikethrough-s;
  }
}
.#{$md-icon-prefix}-format-strikethrough {
  &:before {
    content: $md-iconset-format-strikethrough;
  }
}
.#{$md-icon-prefix}-format-subject {
  &:before {
    content: $md-iconset-format-subject;
  }
}
.#{$md-icon-prefix}-format-underlined {
  &:before {
    content: $md-iconset-format-underlined;
  }
}
.#{$md-icon-prefix}-format-valign-bottom {
  &:before {
    content: $md-iconset-format-valign-bottom;
  }
}
.#{$md-icon-prefix}-format-valign-center {
  &:before {
    content: $md-iconset-format-valign-center;
  }
}
.#{$md-icon-prefix}-format-valign-top {
  &:before {
    content: $md-iconset-format-valign-top;
  }
}
.#{$md-icon-prefix}-redo {
  &:before {
    content: $md-iconset-redo;
  }
}
.#{$md-icon-prefix}-select-all {
  &:before {
    content: $md-iconset-select-all;
  }
}
.#{$md-icon-prefix}-space-bar {
  &:before {
    content: $md-iconset-space-bar;
  }
}
.#{$md-icon-prefix}-text-format {
  &:before {
    content: $md-iconset-text-format;
  }
}
.#{$md-icon-prefix}-transform {
  &:before {
    content: $md-iconset-transform;
  }
}
.#{$md-icon-prefix}-undo {
  &:before {
    content: $md-iconset-undo;
  }
}
.#{$md-icon-prefix}-wrap-text {
  &:before {
    content: $md-iconset-wrap-text;
  }
}
.#{$md-icon-prefix}-comment-alert {
  &:before {
    content: $md-iconset-comment-alert;
  }
}
.#{$md-icon-prefix}-comment-alt-text {
  &:before {
    content: $md-iconset-comment-alt-text;
  }
}
.#{$md-icon-prefix}-comment-alt {
  &:before {
    content: $md-iconset-comment-alt;
  }
}
.#{$md-icon-prefix}-comment-edit {
  &:before {
    content: $md-iconset-comment-edit;
  }
}
.#{$md-icon-prefix}-comment-image {
  &:before {
    content: $md-iconset-comment-image;
  }
}
.#{$md-icon-prefix}-comment-list {
  &:before {
    content: $md-iconset-comment-list;
  }
}
.#{$md-icon-prefix}-comment-more {
  &:before {
    content: $md-iconset-comment-more;
  }
}
.#{$md-icon-prefix}-comment-outline {
  &:before {
    content: $md-iconset-comment-outline;
  }
}
.#{$md-icon-prefix}-comment-text-alt {
  &:before {
    content: $md-iconset-comment-text-alt;
  }
}
.#{$md-icon-prefix}-comment-text {
  &:before {
    content: $md-iconset-comment-text;
  }
}
.#{$md-icon-prefix}-comment-video {
  &:before {
    content: $md-iconset-comment-video;
  }
}
.#{$md-icon-prefix}-comment {
  &:before {
    content: $md-iconset-comment;
  }
}
.#{$md-icon-prefix}-comments {
  &:before {
    content: $md-iconset-comments;
  }
}
.#{$md-icon-prefix}-check-all {
  &:before {
    content: $md-iconset-check-all;
  }
}
.#{$md-icon-prefix}-check-circle-u {
  &:before {
    content: $md-iconset-check-circle-u;
  }
}
.#{$md-icon-prefix}-check-circle {
  &:before {
    content: $md-iconset-check-circle;
  }
}
.#{$md-icon-prefix}-check-square {
  &:before {
    content: $md-iconset-check-square;
  }
}
.#{$md-icon-prefix}-check {
  &:before {
    content: $md-iconset-check;
  }
}
.#{$md-icon-prefix}-circle-o {
  &:before {
    content: $md-iconset-circle-o;
  }
}
.#{$md-icon-prefix}-circle {
  &:before {
    content: $md-iconset-circle;
  }
}
.#{$md-icon-prefix}-dot-circle-alt {
  &:before {
    content: $md-iconset-dot-circle-alt;
  }
}
.#{$md-icon-prefix}-dot-circle {
  &:before {
    content: $md-iconset-dot-circle;
  }
}
.#{$md-icon-prefix}-minus-circle-outline {
  &:before {
    content: $md-iconset-minus-circle-outline;
  }
}
.#{$md-icon-prefix}-minus-circle {
  &:before {
    content: $md-iconset-minus-circle;
  }
}
.#{$md-icon-prefix}-minus-square {
  &:before {
    content: $md-iconset-minus-square;
  }
}
.#{$md-icon-prefix}-minus {
  &:before {
    content: $md-iconset-minus;
  }
}
.#{$md-icon-prefix}-plus-circle-o-duplicate {
  &:before {
    content: $md-iconset-plus-circle-o-duplicate;
  }
}
.#{$md-icon-prefix}-plus-circle-o {
  &:before {
    content: $md-iconset-plus-circle-o;
  }
}
.#{$md-icon-prefix}-plus-circle {
  &:before {
    content: $md-iconset-plus-circle;
  }
}
.#{$md-icon-prefix}-plus-square {
  &:before {
    content: $md-iconset-plus-square;
  }
}
.#{$md-icon-prefix}-plus {
  &:before {
    content: $md-iconset-plus;
  }
}
.#{$md-icon-prefix}-square-o {
  &:before {
    content: $md-iconset-square-o;
  }
}
.#{$md-icon-prefix}-star-circle {
  &:before {
    content: $md-iconset-star-circle;
  }
}
.#{$md-icon-prefix}-star-half {
  &:before {
    content: $md-iconset-star-half;
  }
}
.#{$md-icon-prefix}-star-outline {
  &:before {
    content: $md-iconset-star-outline;
  }
}
.#{$md-icon-prefix}-star {
  &:before {
    content: $md-iconset-star;
  }
}
.#{$md-icon-prefix}-bluetooth-connected {
  &:before {
    content: $md-iconset-bluetooth-connected;
  }
}
.#{$md-icon-prefix}-bluetooth-off {
  &:before {
    content: $md-iconset-bluetooth-off;
  }
}
.#{$md-icon-prefix}-bluetooth-search {
  &:before {
    content: $md-iconset-bluetooth-search;
  }
}
.#{$md-icon-prefix}-bluetooth-setting {
  &:before {
    content: $md-iconset-bluetooth-setting;
  }
}
.#{$md-icon-prefix}-bluetooth {
  &:before {
    content: $md-iconset-bluetooth;
  }
}
.#{$md-icon-prefix}-camera-add {
  &:before {
    content: $md-iconset-camera-add;
  }
}
.#{$md-icon-prefix}-camera-alt {
  &:before {
    content: $md-iconset-camera-alt;
  }
}
.#{$md-icon-prefix}-camera-bw {
  &:before {
    content: $md-iconset-camera-bw;
  }
}
.#{$md-icon-prefix}-camera-front {
  &:before {
    content: $md-iconset-camera-front;
  }
}
.#{$md-icon-prefix}-camera-mic {
  &:before {
    content: $md-iconset-camera-mic;
  }
}
.#{$md-icon-prefix}-camera-party-mode {
  &:before {
    content: $md-iconset-camera-party-mode;
  }
}
.#{$md-icon-prefix}-camera-rear {
  &:before {
    content: $md-iconset-camera-rear;
  }
}
.#{$md-icon-prefix}-camera-roll {
  &:before {
    content: $md-iconset-camera-roll;
  }
}
.#{$md-icon-prefix}-camera-switch {
  &:before {
    content: $md-iconset-camera-switch;
  }
}
.#{$md-icon-prefix}-camera {
  &:before {
    content: $md-iconset-camera;
  }
}
.#{$md-icon-prefix}-card-alert {
  &:before {
    content: $md-iconset-card-alert;
  }
}
.#{$md-icon-prefix}-card-off {
  &:before {
    content: $md-iconset-card-off;
  }
}
.#{$md-icon-prefix}-card-sd {
  &:before {
    content: $md-iconset-card-sd;
  }
}
.#{$md-icon-prefix}-card-sim {
  &:before {
    content: $md-iconset-card-sim;
  }
}
.#{$md-icon-prefix}-desktop-mac {
  &:before {
    content: $md-iconset-desktop-mac;
  }
}
.#{$md-icon-prefix}-desktop-windows {
  &:before {
    content: $md-iconset-desktop-windows;
  }
}
.#{$md-icon-prefix}-device-hub {
  &:before {
    content: $md-iconset-device-hub;
  }
}
.#{$md-icon-prefix}-devices-off {
  &:before {
    content: $md-iconset-devices-off;
  }
}
.#{$md-icon-prefix}-devices {
  &:before {
    content: $md-iconset-devices;
  }
}
.#{$md-icon-prefix}-dock {
  &:before {
    content: $md-iconset-dock;
  }
}
.#{$md-icon-prefix}-floppy {
  &:before {
    content: $md-iconset-floppy;
  }
}
.#{$md-icon-prefix}-gamepad {
  &:before {
    content: $md-iconset-gamepad;
  }
}
.#{$md-icon-prefix}-gps-dot {
  &:before {
    content: $md-iconset-gps-dot;
  }
}
.#{$md-icon-prefix}-gps-off {
  &:before {
    content: $md-iconset-gps-off;
  }
}
.#{$md-icon-prefix}-gps {
  &:before {
    content: $md-iconset-gps;
  }
}
.#{$md-icon-prefix}-headset-mic {
  &:before {
    content: $md-iconset-headset-mic;
  }
}
.#{$md-icon-prefix}-headset {
  &:before {
    content: $md-iconset-headset;
  }
}
.#{$md-icon-prefix}-input-antenna {
  &:before {
    content: $md-iconset-input-antenna;
  }
}
.#{$md-icon-prefix}-input-composite {
  &:before {
    content: $md-iconset-input-composite;
  }
}
.#{$md-icon-prefix}-input-hdmi {
  &:before {
    content: $md-iconset-input-hdmi;
  }
}
.#{$md-icon-prefix}-input-power {
  &:before {
    content: $md-iconset-input-power;
  }
}
.#{$md-icon-prefix}-input-svideo {
  &:before {
    content: $md-iconset-input-svideo;
  }
}
.#{$md-icon-prefix}-keyboard-hide {
  &:before {
    content: $md-iconset-keyboard-hide;
  }
}
.#{$md-icon-prefix}-keyboard {
  &:before {
    content: $md-iconset-keyboard;
  }
}
.#{$md-icon-prefix}-laptop-chromebook {
  &:before {
    content: $md-iconset-laptop-chromebook;
  }
}
.#{$md-icon-prefix}-laptop-mac {
  &:before {
    content: $md-iconset-laptop-mac;
  }
}
.#{$md-icon-prefix}-laptop {
  &:before {
    content: $md-iconset-laptop;
  }
}
.#{$md-icon-prefix}-mic-off {
  &:before {
    content: $md-iconset-mic-off;
  }
}
.#{$md-icon-prefix}-mic-outline {
  &:before {
    content: $md-iconset-mic-outline;
  }
}
.#{$md-icon-prefix}-mic-setting {
  &:before {
    content: $md-iconset-mic-setting;
  }
}
.#{$md-icon-prefix}-mic {
  &:before {
    content: $md-iconset-mic;
  }
}
.#{$md-icon-prefix}-mouse {
  &:before {
    content: $md-iconset-mouse;
  }
}
.#{$md-icon-prefix}-network-alert {
  &:before {
    content: $md-iconset-network-alert;
  }
}
.#{$md-icon-prefix}-network-locked {
  &:before {
    content: $md-iconset-network-locked;
  }
}
.#{$md-icon-prefix}-network-off {
  &:before {
    content: $md-iconset-network-off;
  }
}
.#{$md-icon-prefix}-network-outline {
  &:before {
    content: $md-iconset-network-outline;
  }
}
.#{$md-icon-prefix}-network-setting {
  &:before {
    content: $md-iconset-network-setting;
  }
}
.#{$md-icon-prefix}-network {
  &:before {
    content: $md-iconset-network;
  }
}
.#{$md-icon-prefix}-phone-bluetooth {
  &:before {
    content: $md-iconset-phone-bluetooth;
  }
}
.#{$md-icon-prefix}-phone-end {
  &:before {
    content: $md-iconset-phone-end;
  }
}
.#{$md-icon-prefix}-phone-forwarded {
  &:before {
    content: $md-iconset-phone-forwarded;
  }
}
.#{$md-icon-prefix}-phone-in-talk {
  &:before {
    content: $md-iconset-phone-in-talk;
  }
}
.#{$md-icon-prefix}-phone-locked {
  &:before {
    content: $md-iconset-phone-locked;
  }
}
.#{$md-icon-prefix}-phone-missed {
  &:before {
    content: $md-iconset-phone-missed;
  }
}
.#{$md-icon-prefix}-phone-msg {
  &:before {
    content: $md-iconset-phone-msg;
  }
}
.#{$md-icon-prefix}-phone-paused {
  &:before {
    content: $md-iconset-phone-paused;
  }
}
.#{$md-icon-prefix}-phone-ring {
  &:before {
    content: $md-iconset-phone-ring;
  }
}
.#{$md-icon-prefix}-phone-setting {
  &:before {
    content: $md-iconset-phone-setting;
  }
}
.#{$md-icon-prefix}-phone-sip {
  &:before {
    content: $md-iconset-phone-sip;
  }
}
.#{$md-icon-prefix}-phone {
  &:before {
    content: $md-iconset-phone;
  }
}
.#{$md-icon-prefix}-portable-wifi-changes {
  &:before {
    content: $md-iconset-portable-wifi-changes;
  }
}
.#{$md-icon-prefix}-portable-wifi-off {
  &:before {
    content: $md-iconset-portable-wifi-off;
  }
}
.#{$md-icon-prefix}-portable-wifi {
  &:before {
    content: $md-iconset-portable-wifi;
  }
}
.#{$md-icon-prefix}-radio {
  &:before {
    content: $md-iconset-radio;
  }
}
.#{$md-icon-prefix}-reader {
  &:before {
    content: $md-iconset-reader;
  }
}
.#{$md-icon-prefix}-remote-control-alt {
  &:before {
    content: $md-iconset-remote-control-alt;
  }
}
.#{$md-icon-prefix}-remote-control {
  &:before {
    content: $md-iconset-remote-control;
  }
}
.#{$md-icon-prefix}-router {
  &:before {
    content: $md-iconset-router;
  }
}
.#{$md-icon-prefix}-scanner {
  &:before {
    content: $md-iconset-scanner;
  }
}
.#{$md-icon-prefix}-smartphone-android {
  &:before {
    content: $md-iconset-smartphone-android;
  }
}
.#{$md-icon-prefix}-smartphone-download {
  &:before {
    content: $md-iconset-smartphone-download;
  }
}
.#{$md-icon-prefix}-smartphone-erase {
  &:before {
    content: $md-iconset-smartphone-erase;
  }
}
.#{$md-icon-prefix}-smartphone-info {
  &:before {
    content: $md-iconset-smartphone-info;
  }
}
.#{$md-icon-prefix}-smartphone-iphone {
  &:before {
    content: $md-iconset-smartphone-iphone;
  }
}
.#{$md-icon-prefix}-smartphone-landscape-lock {
  &:before {
    content: $md-iconset-smartphone-landscape-lock;
  }
}
.#{$md-icon-prefix}-smartphone-landscape {
  &:before {
    content: $md-iconset-smartphone-landscape;
  }
}
.#{$md-icon-prefix}-smartphone-lock {
  &:before {
    content: $md-iconset-smartphone-lock;
  }
}
.#{$md-icon-prefix}-smartphone-portrait-lock {
  &:before {
    content: $md-iconset-smartphone-portrait-lock;
  }
}
.#{$md-icon-prefix}-smartphone-ring {
  &:before {
    content: $md-iconset-smartphone-ring;
  }
}
.#{$md-icon-prefix}-smartphone-setting {
  &:before {
    content: $md-iconset-smartphone-setting;
  }
}
.#{$md-icon-prefix}-smartphone-setup {
  &:before {
    content: $md-iconset-smartphone-setup;
  }
}
.#{$md-icon-prefix}-smartphone {
  &:before {
    content: $md-iconset-smartphone;
  }
}
.#{$md-icon-prefix}-speaker {
  &:before {
    content: $md-iconset-speaker;
  }
}
.#{$md-icon-prefix}-tablet-android {
  &:before {
    content: $md-iconset-tablet-android;
  }
}
.#{$md-icon-prefix}-tablet-mac {
  &:before {
    content: $md-iconset-tablet-mac;
  }
}
.#{$md-icon-prefix}-tablet {
  &:before {
    content: $md-iconset-tablet;
  }
}
.#{$md-icon-prefix}-tv-alt-play {
  &:before {
    content: $md-iconset-tv-alt-play;
  }
}
.#{$md-icon-prefix}-tv-list {
  &:before {
    content: $md-iconset-tv-list;
  }
}
.#{$md-icon-prefix}-tv-play {
  &:before {
    content: $md-iconset-tv-play;
  }
}
.#{$md-icon-prefix}-tv {
  &:before {
    content: $md-iconset-tv;
  }
}
.#{$md-icon-prefix}-usb {
  &:before {
    content: $md-iconset-usb;
  }
}
.#{$md-icon-prefix}-videocam-off {
  &:before {
    content: $md-iconset-videocam-off;
  }
}
.#{$md-icon-prefix}-videocam-switch {
  &:before {
    content: $md-iconset-videocam-switch;
  }
}
.#{$md-icon-prefix}-videocam {
  &:before {
    content: $md-iconset-videocam;
  }
}
.#{$md-icon-prefix}-watch {
  &:before {
    content: $md-iconset-watch;
  }
}
.#{$md-icon-prefix}-wifi-alt-2 {
  &:before {
    content: $md-iconset-wifi-alt-2;
  }
}
.#{$md-icon-prefix}-wifi-alt {
  &:before {
    content: $md-iconset-wifi-alt;
  }
}
.#{$md-icon-prefix}-wifi-info {
  &:before {
    content: $md-iconset-wifi-info;
  }
}
.#{$md-icon-prefix}-wifi-lock {
  &:before {
    content: $md-iconset-wifi-lock;
  }
}
.#{$md-icon-prefix}-wifi-off {
  &:before {
    content: $md-iconset-wifi-off;
  }
}
.#{$md-icon-prefix}-wifi-outline {
  &:before {
    content: $md-iconset-wifi-outline;
  }
}
.#{$md-icon-prefix}-wifi {
  &:before {
    content: $md-iconset-wifi;
  }
}
.#{$md-icon-prefix}-arrow-left-bottom {
  &:before {
    content: $md-iconset-arrow-left-bottom;
  }
}
.#{$md-icon-prefix}-arrow-left {
  &:before {
    content: $md-iconset-arrow-left;
  }
}
.#{$md-icon-prefix}-arrow-merge {
  &:before {
    content: $md-iconset-arrow-merge;
  }
}
.#{$md-icon-prefix}-arrow-missed {
  &:before {
    content: $md-iconset-arrow-missed;
  }
}
.#{$md-icon-prefix}-arrow-right-top {
  &:before {
    content: $md-iconset-arrow-right-top;
  }
}
.#{$md-icon-prefix}-arrow-right {
  &:before {
    content: $md-iconset-arrow-right;
  }
}
.#{$md-icon-prefix}-arrow-split {
  &:before {
    content: $md-iconset-arrow-split;
  }
}
.#{$md-icon-prefix}-arrows {
  &:before {
    content: $md-iconset-arrows;
  }
}
.#{$md-icon-prefix}-caret-down-circle {
  &:before {
    content: $md-iconset-caret-down-circle;
  }
}
.#{$md-icon-prefix}-caret-down {
  &:before {
    content: $md-iconset-caret-down;
  }
}
.#{$md-icon-prefix}-caret-left-circle {
  &:before {
    content: $md-iconset-caret-left-circle;
  }
}
.#{$md-icon-prefix}-caret-left {
  &:before {
    content: $md-iconset-caret-left;
  }
}
.#{$md-icon-prefix}-caret-right-circle {
  &:before {
    content: $md-iconset-caret-right-circle;
  }
}
.#{$md-icon-prefix}-caret-right {
  &:before {
    content: $md-iconset-caret-right;
  }
}
.#{$md-icon-prefix}-caret-up-circle {
  &:before {
    content: $md-iconset-caret-up-circle;
  }
}
.#{$md-icon-prefix}-caret-up {
  &:before {
    content: $md-iconset-caret-up;
  }
}
.#{$md-icon-prefix}-chevron-down {
  &:before {
    content: $md-iconset-chevron-down;
  }
}
.#{$md-icon-prefix}-chevron-left {
  &:before {
    content: $md-iconset-chevron-left;
  }
}
.#{$md-icon-prefix}-chevron-right {
  &:before {
    content: $md-iconset-chevron-right;
  }
}
.#{$md-icon-prefix}-chevron-up {
  &:before {
    content: $md-iconset-chevron-up;
  }
}
.#{$md-icon-prefix}-forward {
  &:before {
    content: $md-iconset-forward;
  }
}
.#{$md-icon-prefix}-long-arrow-down {
  &:before {
    content: $md-iconset-long-arrow-down;
  }
}
.#{$md-icon-prefix}-long-arrow-left {
  &:before {
    content: $md-iconset-long-arrow-left;
  }
}
.#{$md-icon-prefix}-long-arrow-return {
  &:before {
    content: $md-iconset-long-arrow-return;
  }
}
.#{$md-icon-prefix}-long-arrow-right {
  &:before {
    content: $md-iconset-long-arrow-right;
  }
}
.#{$md-icon-prefix}-long-arrow-tab {
  &:before {
    content: $md-iconset-long-arrow-tab;
  }
}
.#{$md-icon-prefix}-long-arrow-up {
  &:before {
    content: $md-iconset-long-arrow-up;
  }
}
.#{$md-icon-prefix}-rotate-ccw {
  &:before {
    content: $md-iconset-rotate-ccw;
  }
}
.#{$md-icon-prefix}-rotate-cw {
  &:before {
    content: $md-iconset-rotate-cw;
  }
}
.#{$md-icon-prefix}-rotate-left {
  &:before {
    content: $md-iconset-rotate-left;
  }
}
.#{$md-icon-prefix}-rotate-right {
  &:before {
    content: $md-iconset-rotate-right;
  }
}
.#{$md-icon-prefix}-square-down {
  &:before {
    content: $md-iconset-square-down;
  }
}
.#{$md-icon-prefix}-square-right {
  &:before {
    content: $md-iconset-square-right;
  }
}
.#{$md-icon-prefix}-swap-alt {
  &:before {
    content: $md-iconset-swap-alt;
  }
}
.#{$md-icon-prefix}-swap-vertical-circle {
  &:before {
    content: $md-iconset-swap-vertical-circle;
  }
}
.#{$md-icon-prefix}-swap-vertical {
  &:before {
    content: $md-iconset-swap-vertical;
  }
}
.#{$md-icon-prefix}-swap {
  &:before {
    content: $md-iconset-swap;
  }
}
.#{$md-icon-prefix}-trending-down {
  &:before {
    content: $md-iconset-trending-down;
  }
}
.#{$md-icon-prefix}-trending-flat {
  &:before {
    content: $md-iconset-trending-flat;
  }
}
.#{$md-icon-prefix}-trending-up {
  &:before {
    content: $md-iconset-trending-up;
  }
}
.#{$md-icon-prefix}-unfold-less {
  &:before {
    content: $md-iconset-unfold-less;
  }
}
.#{$md-icon-prefix}-unfold-more {
  &:before {
    content: $md-iconset-unfold-more;
  }
}
.#{$md-icon-prefix}-apps {
  &:before {
    content: $md-iconset-apps;
  }
}
.#{$md-icon-prefix}-grid-off {
  &:before {
    content: $md-iconset-grid-off;
  }
}
.#{$md-icon-prefix}-grid {
  &:before {
    content: $md-iconset-grid;
  }
}
.#{$md-icon-prefix}-view-agenda {
  &:before {
    content: $md-iconset-view-agenda;
  }
}
.#{$md-icon-prefix}-view-array {
  &:before {
    content: $md-iconset-view-array;
  }
}
.#{$md-icon-prefix}-view-carousel {
  &:before {
    content: $md-iconset-view-carousel;
  }
}
.#{$md-icon-prefix}-view-column {
  &:before {
    content: $md-iconset-view-column;
  }
}
.#{$md-icon-prefix}-view-comfy {
  &:before {
    content: $md-iconset-view-comfy;
  }
}
.#{$md-icon-prefix}-view-compact {
  &:before {
    content: $md-iconset-view-compact;
  }
}
.#{$md-icon-prefix}-view-dashboard {
  &:before {
    content: $md-iconset-view-dashboard;
  }
}
.#{$md-icon-prefix}-view-day {
  &:before {
    content: $md-iconset-view-day;
  }
}
.#{$md-icon-prefix}-view-headline {
  &:before {
    content: $md-iconset-view-headline;
  }
}
.#{$md-icon-prefix}-view-list-alt {
  &:before {
    content: $md-iconset-view-list-alt;
  }
}
.#{$md-icon-prefix}-view-list {
  &:before {
    content: $md-iconset-view-list;
  }
}
.#{$md-icon-prefix}-view-module {
  &:before {
    content: $md-iconset-view-module;
  }
}
.#{$md-icon-prefix}-view-quilt {
  &:before {
    content: $md-iconset-view-quilt;
  }
}
.#{$md-icon-prefix}-view-stream {
  &:before {
    content: $md-iconset-view-stream;
  }
}
.#{$md-icon-prefix}-view-subtitles {
  &:before {
    content: $md-iconset-view-subtitles;
  }
}
.#{$md-icon-prefix}-view-toc {
  &:before {
    content: $md-iconset-view-toc;
  }
}
.#{$md-icon-prefix}-view-web {
  &:before {
    content: $md-iconset-view-web;
  }
}
.#{$md-icon-prefix}-view-week {
  &:before {
    content: $md-iconset-view-week;
  }
}
.#{$md-icon-prefix}-widgets {
  &:before {
    content: $md-iconset-widgets;
  }
}
.#{$md-icon-prefix}-alarm-check {
  &:before {
    content: $md-iconset-alarm-check;
  }
}
.#{$md-icon-prefix}-alarm-off {
  &:before {
    content: $md-iconset-alarm-off;
  }
}
.#{$md-icon-prefix}-alarm-plus {
  &:before {
    content: $md-iconset-alarm-plus;
  }
}
.#{$md-icon-prefix}-alarm-snooze {
  &:before {
    content: $md-iconset-alarm-snooze;
  }
}
.#{$md-icon-prefix}-alarm {
  &:before {
    content: $md-iconset-alarm;
  }
}
.#{$md-icon-prefix}-calendar-alt {
  &:before {
    content: $md-iconset-calendar-alt;
  }
}
.#{$md-icon-prefix}-calendar-check {
  &:before {
    content: $md-iconset-calendar-check;
  }
}
.#{$md-icon-prefix}-calendar-close {
  &:before {
    content: $md-iconset-calendar-close;
  }
}
.#{$md-icon-prefix}-calendar-note {
  &:before {
    content: $md-iconset-calendar-note;
  }
}
.#{$md-icon-prefix}-calendar {
  &:before {
    content: $md-iconset-calendar;
  }
}
.#{$md-icon-prefix}-time-countdown {
  &:before {
    content: $md-iconset-time-countdown;
  }
}
.#{$md-icon-prefix}-time-interval {
  &:before {
    content: $md-iconset-time-interval;
  }
}
.#{$md-icon-prefix}-time-restore-setting {
  &:before {
    content: $md-iconset-time-restore-setting;
  }
}
.#{$md-icon-prefix}-time-restore {
  &:before {
    content: $md-iconset-time-restore;
  }
}
.#{$md-icon-prefix}-time {
  &:before {
    content: $md-iconset-time;
  }
}
.#{$md-icon-prefix}-timer-off {
  &:before {
    content: $md-iconset-timer-off;
  }
}
.#{$md-icon-prefix}-timer {
  &:before {
    content: $md-iconset-timer;
  }
}
.#{$md-icon-prefix}-android-alt {
  &:before {
    content: $md-iconset-android-alt;
  }
}
.#{$md-icon-prefix}-android {
  &:before {
    content: $md-iconset-android;
  }
}
.#{$md-icon-prefix}-apple {
  &:before {
    content: $md-iconset-apple;
  }
}
.#{$md-icon-prefix}-behance {
  &:before {
    content: $md-iconset-behance;
  }
}
.#{$md-icon-prefix}-codepen {
  &:before {
    content: $md-iconset-codepen;
  }
}
.#{$md-icon-prefix}-dribbble {
  &:before {
    content: $md-iconset-dribbble;
  }
}
.#{$md-icon-prefix}-dropbox {
  &:before {
    content: $md-iconset-dropbox;
  }
}
.#{$md-icon-prefix}-evernote {
  &:before {
    content: $md-iconset-evernote;
  }
}
.#{$md-icon-prefix}-facebook-box {
  &:before {
    content: $md-iconset-facebook-box;
  }
}
.#{$md-icon-prefix}-facebook {
  &:before {
    content: $md-iconset-facebook;
  }
}
.#{$md-icon-prefix}-github-box {
  &:before {
    content: $md-iconset-github-box;
  }
}
.#{$md-icon-prefix}-github {
  &:before {
    content: $md-iconset-github;
  }
}
.#{$md-icon-prefix}-google-drive {
  &:before {
    content: $md-iconset-google-drive;
  }
}
.#{$md-icon-prefix}-google-earth {
  &:before {
    content: $md-iconset-google-earth;
  }
}
.#{$md-icon-prefix}-google-glass {
  &:before {
    content: $md-iconset-google-glass;
  }
}
.#{$md-icon-prefix}-google-maps {
  &:before {
    content: $md-iconset-google-maps;
  }
}
.#{$md-icon-prefix}-google-pages {
  &:before {
    content: $md-iconset-google-pages;
  }
}
.#{$md-icon-prefix}-google-play {
  &:before {
    content: $md-iconset-google-play;
  }
}
.#{$md-icon-prefix}-google-plus-box {
  &:before {
    content: $md-iconset-google-plus-box;
  }
}
.#{$md-icon-prefix}-google-plus {
  &:before {
    content: $md-iconset-google-plus;
  }
}
.#{$md-icon-prefix}-google {
  &:before {
    content: $md-iconset-google;
  }
}
.#{$md-icon-prefix}-instagram {
  &:before {
    content: $md-iconset-instagram;
  }
}
.#{$md-icon-prefix}-language-css3 {
  &:before {
    content: $md-iconset-language-css3;
  }
}
.#{$md-icon-prefix}-language-html5 {
  &:before {
    content: $md-iconset-language-html5;
  }
}
.#{$md-icon-prefix}-language-javascript {
  &:before {
    content: $md-iconset-language-javascript;
  }
}
.#{$md-icon-prefix}-language-python-alt {
  &:before {
    content: $md-iconset-language-python-alt;
  }
}
.#{$md-icon-prefix}-language-python {
  &:before {
    content: $md-iconset-language-python;
  }
}
.#{$md-icon-prefix}-lastfm {
  &:before {
    content: $md-iconset-lastfm;
  }
}
.#{$md-icon-prefix}-linkedin-box {
  &:before {
    content: $md-iconset-linkedin-box;
  }
}
.#{$md-icon-prefix}-paypal {
  &:before {
    content: $md-iconset-paypal;
  }
}
.#{$md-icon-prefix}-pinterest-box {
  &:before {
    content: $md-iconset-pinterest-box;
  }
}
.#{$md-icon-prefix}-pocket {
  &:before {
    content: $md-iconset-pocket;
  }
}
.#{$md-icon-prefix}-polymer {
  &:before {
    content: $md-iconset-polymer;
  }
}
.#{$md-icon-prefix}-share {
  &:before {
    content: $md-iconset-share;
  }
}
.#{$md-icon-prefix}-stack-overflow {
  &:before {
    content: $md-iconset-stack-overflow;
  }
}
.#{$md-icon-prefix}-steam-square {
  &:before {
    content: $md-iconset-steam-square;
  }
}
.#{$md-icon-prefix}-steam {
  &:before {
    content: $md-iconset-steam;
  }
}
.#{$md-icon-prefix}-twitter-box {
  &:before {
    content: $md-iconset-twitter-box;
  }
}
.#{$md-icon-prefix}-twitter {
  &:before {
    content: $md-iconset-twitter;
  }
}
.#{$md-icon-prefix}-vk {
  &:before {
    content: $md-iconset-vk;
  }
}
.#{$md-icon-prefix}-wikipedia {
  &:before {
    content: $md-iconset-wikipedia;
  }
}
.#{$md-icon-prefix}-windows {
  &:before {
    content: $md-iconset-windows;
  }
}
.#{$md-icon-prefix}-aspect-ratio-alt {
  &:before {
    content: $md-iconset-aspect-ratio-alt;
  }
}
.#{$md-icon-prefix}-aspect-ratio {
  &:before {
    content: $md-iconset-aspect-ratio;
  }
}
.#{$md-icon-prefix}-blur-circular {
  &:before {
    content: $md-iconset-blur-circular;
  }
}
.#{$md-icon-prefix}-blur-linear {
  &:before {
    content: $md-iconset-blur-linear;
  }
}
.#{$md-icon-prefix}-blur-off {
  &:before {
    content: $md-iconset-blur-off;
  }
}
.#{$md-icon-prefix}-blur {
  &:before {
    content: $md-iconset-blur;
  }
}
.#{$md-icon-prefix}-brightness-2 {
  &:before {
    content: $md-iconset-brightness-2;
  }
}
.#{$md-icon-prefix}-brightness-3 {
  &:before {
    content: $md-iconset-brightness-3;
  }
}
.#{$md-icon-prefix}-brightness-4 {
  &:before {
    content: $md-iconset-brightness-4;
  }
}
.#{$md-icon-prefix}-brightness-5 {
  &:before {
    content: $md-iconset-brightness-5;
  }
}
.#{$md-icon-prefix}-brightness-6 {
  &:before {
    content: $md-iconset-brightness-6;
  }
}
.#{$md-icon-prefix}-brightness-7 {
  &:before {
    content: $md-iconset-brightness-7;
  }
}
.#{$md-icon-prefix}-brightness-auto {
  &:before {
    content: $md-iconset-brightness-auto;
  }
}
.#{$md-icon-prefix}-brightness-setting {
  &:before {
    content: $md-iconset-brightness-setting;
  }
}
.#{$md-icon-prefix}-broken-image {
  &:before {
    content: $md-iconset-broken-image;
  }
}
.#{$md-icon-prefix}-center-focus-strong {
  &:before {
    content: $md-iconset-center-focus-strong;
  }
}
.#{$md-icon-prefix}-center-focus-weak {
  &:before {
    content: $md-iconset-center-focus-weak;
  }
}
.#{$md-icon-prefix}-compare {
  &:before {
    content: $md-iconset-compare;
  }
}
.#{$md-icon-prefix}-crop-16-9 {
  &:before {
    content: $md-iconset-crop-16-9;
  }
}
.#{$md-icon-prefix}-crop-3-2 {
  &:before {
    content: $md-iconset-crop-3-2;
  }
}
.#{$md-icon-prefix}-crop-5-4 {
  &:before {
    content: $md-iconset-crop-5-4;
  }
}
.#{$md-icon-prefix}-crop-7-5 {
  &:before {
    content: $md-iconset-crop-7-5;
  }
}
.#{$md-icon-prefix}-crop-din {
  &:before {
    content: $md-iconset-crop-din;
  }
}
.#{$md-icon-prefix}-crop-free {
  &:before {
    content: $md-iconset-crop-free;
  }
}
.#{$md-icon-prefix}-crop-landscape {
  &:before {
    content: $md-iconset-crop-landscape;
  }
}
.#{$md-icon-prefix}-crop-portrait {
  &:before {
    content: $md-iconset-crop-portrait;
  }
}
.#{$md-icon-prefix}-crop-square {
  &:before {
    content: $md-iconset-crop-square;
  }
}
.#{$md-icon-prefix}-exposure-alt {
  &:before {
    content: $md-iconset-exposure-alt;
  }
}
.#{$md-icon-prefix}-exposure {
  &:before {
    content: $md-iconset-exposure;
  }
}
.#{$md-icon-prefix}-filter-b-and-w {
  &:before {
    content: $md-iconset-filter-b-and-w;
  }
}
.#{$md-icon-prefix}-filter-center-focus {
  &:before {
    content: $md-iconset-filter-center-focus;
  }
}
.#{$md-icon-prefix}-filter-frames {
  &:before {
    content: $md-iconset-filter-frames;
  }
}
.#{$md-icon-prefix}-filter-tilt-shift {
  &:before {
    content: $md-iconset-filter-tilt-shift;
  }
}
.#{$md-icon-prefix}-gradient {
  &:before {
    content: $md-iconset-gradient;
  }
}
.#{$md-icon-prefix}-grain {
  &:before {
    content: $md-iconset-grain;
  }
}
.#{$md-icon-prefix}-graphic-eq {
  &:before {
    content: $md-iconset-graphic-eq;
  }
}
.#{$md-icon-prefix}-hdr-off {
  &:before {
    content: $md-iconset-hdr-off;
  }
}
.#{$md-icon-prefix}-hdr-strong {
  &:before {
    content: $md-iconset-hdr-strong;
  }
}
.#{$md-icon-prefix}-hdr-weak {
  &:before {
    content: $md-iconset-hdr-weak;
  }
}
.#{$md-icon-prefix}-hdr {
  &:before {
    content: $md-iconset-hdr;
  }
}
.#{$md-icon-prefix}-iridescent {
  &:before {
    content: $md-iconset-iridescent;
  }
}
.#{$md-icon-prefix}-leak-off {
  &:before {
    content: $md-iconset-leak-off;
  }
}
.#{$md-icon-prefix}-leak {
  &:before {
    content: $md-iconset-leak;
  }
}
.#{$md-icon-prefix}-looks {
  &:before {
    content: $md-iconset-looks;
  }
}
.#{$md-icon-prefix}-loupe {
  &:before {
    content: $md-iconset-loupe;
  }
}
.#{$md-icon-prefix}-panorama-horizontal {
  &:before {
    content: $md-iconset-panorama-horizontal;
  }
}
.#{$md-icon-prefix}-panorama-vertical {
  &:before {
    content: $md-iconset-panorama-vertical;
  }
}
.#{$md-icon-prefix}-panorama-wide-angle {
  &:before {
    content: $md-iconset-panorama-wide-angle;
  }
}
.#{$md-icon-prefix}-photo-size-select-large {
  &:before {
    content: $md-iconset-photo-size-select-large;
  }
}
.#{$md-icon-prefix}-photo-size-select-small {
  &:before {
    content: $md-iconset-photo-size-select-small;
  }
}
.#{$md-icon-prefix}-picture-in-picture {
  &:before {
    content: $md-iconset-picture-in-picture;
  }
}
.#{$md-icon-prefix}-slideshow {
  &:before {
    content: $md-iconset-slideshow;
  }
}
.#{$md-icon-prefix}-texture {
  &:before {
    content: $md-iconset-texture;
  }
}
.#{$md-icon-prefix}-tonality {
  &:before {
    content: $md-iconset-tonality;
  }
}
.#{$md-icon-prefix}-vignette {
  &:before {
    content: $md-iconset-vignette;
  }
}
.#{$md-icon-prefix}-wb-auto {
  &:before {
    content: $md-iconset-wb-auto;
  }
}
.#{$md-icon-prefix}-eject-alt {
  &:before {
    content: $md-iconset-eject-alt;
  }
}
.#{$md-icon-prefix}-eject {
  &:before {
    content: $md-iconset-eject;
  }
}
.#{$md-icon-prefix}-equalizer {
  &:before {
    content: $md-iconset-equalizer;
  }
}
.#{$md-icon-prefix}-fast-forward {
  &:before {
    content: $md-iconset-fast-forward;
  }
}
.#{$md-icon-prefix}-fast-rewind {
  &:before {
    content: $md-iconset-fast-rewind;
  }
}
.#{$md-icon-prefix}-forward-10 {
  &:before {
    content: $md-iconset-forward-10;
  }
}
.#{$md-icon-prefix}-forward-30 {
  &:before {
    content: $md-iconset-forward-30;
  }
}
.#{$md-icon-prefix}-forward-5 {
  &:before {
    content: $md-iconset-forward-5;
  }
}
.#{$md-icon-prefix}-hearing {
  &:before {
    content: $md-iconset-hearing;
  }
}
.#{$md-icon-prefix}-pause-circle-outline {
  &:before {
    content: $md-iconset-pause-circle-outline;
  }
}
.#{$md-icon-prefix}-pause-circle {
  &:before {
    content: $md-iconset-pause-circle;
  }
}
.#{$md-icon-prefix}-pause {
  &:before {
    content: $md-iconset-pause;
  }
}
.#{$md-icon-prefix}-play-circle-outline {
  &:before {
    content: $md-iconset-play-circle-outline;
  }
}
.#{$md-icon-prefix}-play-circle {
  &:before {
    content: $md-iconset-play-circle;
  }
}
.#{$md-icon-prefix}-play {
  &:before {
    content: $md-iconset-play;
  }
}
.#{$md-icon-prefix}-playlist-audio {
  &:before {
    content: $md-iconset-playlist-audio;
  }
}
.#{$md-icon-prefix}-playlist-plus {
  &:before {
    content: $md-iconset-playlist-plus;
  }
}
.#{$md-icon-prefix}-repeat-one {
  &:before {
    content: $md-iconset-repeat-one;
  }
}
.#{$md-icon-prefix}-repeat {
  &:before {
    content: $md-iconset-repeat;
  }
}
.#{$md-icon-prefix}-replay-10 {
  &:before {
    content: $md-iconset-replay-10;
  }
}
.#{$md-icon-prefix}-replay-30 {
  &:before {
    content: $md-iconset-replay-30;
  }
}
.#{$md-icon-prefix}-replay-5 {
  &:before {
    content: $md-iconset-replay-5;
  }
}
.#{$md-icon-prefix}-replay {
  &:before {
    content: $md-iconset-replay;
  }
}
.#{$md-icon-prefix}-shuffle {
  &:before {
    content: $md-iconset-shuffle;
  }
}
.#{$md-icon-prefix}-skip-next {
  &:before {
    content: $md-iconset-skip-next;
  }
}
.#{$md-icon-prefix}-skip-previous {
  &:before {
    content: $md-iconset-skip-previous;
  }
}
.#{$md-icon-prefix}-stop {
  &:before {
    content: $md-iconset-stop;
  }
}
.#{$md-icon-prefix}-surround-sound {
  &:before {
    content: $md-iconset-surround-sound;
  }
}
.#{$md-icon-prefix}-tune {
  &:before {
    content: $md-iconset-tune;
  }
}
.#{$md-icon-prefix}-volume-down {
  &:before {
    content: $md-iconset-volume-down;
  }
}
.#{$md-icon-prefix}-volume-mute {
  &:before {
    content: $md-iconset-volume-mute;
  }
}
.#{$md-icon-prefix}-volume-off {
  &:before {
    content: $md-iconset-volume-off;
  }
}
.#{$md-icon-prefix}-volume-up {
  &:before {
    content: $md-iconset-volume-up;
  }
}
.#{$md-icon-prefix}-n-1-square {
  &:before {
    content: $md-iconset-n-1-square;
  }
}
.#{$md-icon-prefix}-n-2-square {
  &:before {
    content: $md-iconset-n-2-square;
  }
}
.#{$md-icon-prefix}-n-3-square {
  &:before {
    content: $md-iconset-n-3-square;
  }
}
.#{$md-icon-prefix}-n-4-square {
  &:before {
    content: $md-iconset-n-4-square;
  }
}
.#{$md-icon-prefix}-n-5-square {
  &:before {
    content: $md-iconset-n-5-square;
  }
}
.#{$md-icon-prefix}-n-6-square {
  &:before {
    content: $md-iconset-n-6-square;
  }
}
.#{$md-icon-prefix}-neg-1 {
  &:before {
    content: $md-iconset-neg-1;
  }
}
.#{$md-icon-prefix}-neg-2 {
  &:before {
    content: $md-iconset-neg-2;
  }
}
.#{$md-icon-prefix}-plus-1 {
  &:before {
    content: $md-iconset-plus-1;
  }
}
.#{$md-icon-prefix}-plus-2 {
  &:before {
    content: $md-iconset-plus-2;
  }
}
.#{$md-icon-prefix}-sec-10 {
  &:before {
    content: $md-iconset-sec-10;
  }
}
.#{$md-icon-prefix}-sec-3 {
  &:before {
    content: $md-iconset-sec-3;
  }
}
.#{$md-icon-prefix}-zero {
  &:before {
    content: $md-iconset-zero;
  }
}
.#{$md-icon-prefix}-airline-seat-flat-angled {
  &:before {
    content: $md-iconset-airline-seat-flat-angled;
  }
}
.#{$md-icon-prefix}-airline-seat-flat {
  &:before {
    content: $md-iconset-airline-seat-flat;
  }
}
.#{$md-icon-prefix}-airline-seat-individual-suite {
  &:before {
    content: $md-iconset-airline-seat-individual-suite;
  }
}
.#{$md-icon-prefix}-airline-seat-legroom-extra {
  &:before {
    content: $md-iconset-airline-seat-legroom-extra;
  }
}
.#{$md-icon-prefix}-airline-seat-legroom-normal {
  &:before {
    content: $md-iconset-airline-seat-legroom-normal;
  }
}
.#{$md-icon-prefix}-airline-seat-legroom-reduced {
  &:before {
    content: $md-iconset-airline-seat-legroom-reduced;
  }
}
.#{$md-icon-prefix}-airline-seat-recline-extra {
  &:before {
    content: $md-iconset-airline-seat-recline-extra;
  }
}
.#{$md-icon-prefix}-airline-seat-recline-normal {
  &:before {
    content: $md-iconset-airline-seat-recline-normal;
  }
}
.#{$md-icon-prefix}-airplay {
  &:before {
    content: $md-iconset-airplay;
  }
}
.#{$md-icon-prefix}-closed-caption {
  &:before {
    content: $md-iconset-closed-caption;
  }
}
.#{$md-icon-prefix}-confirmation-number {
  &:before {
    content: $md-iconset-confirmation-number;
  }
}
.#{$md-icon-prefix}-developer-board {
  &:before {
    content: $md-iconset-developer-board;
  }
}
.#{$md-icon-prefix}-disc-full {
  &:before {
    content: $md-iconset-disc-full;
  }
}
.#{$md-icon-prefix}-explicit {
  &:before {
    content: $md-iconset-explicit;
  }
}
.#{$md-icon-prefix}-flight-land {
  &:before {
    content: $md-iconset-flight-land;
  }
}
.#{$md-icon-prefix}-flight-takeoff {
  &:before {
    content: $md-iconset-flight-takeoff;
  }
}
.#{$md-icon-prefix}-flip-to-back {
  &:before {
    content: $md-iconset-flip-to-back;
  }
}
.#{$md-icon-prefix}-flip-to-front {
  &:before {
    content: $md-iconset-flip-to-front;
  }
}
.#{$md-icon-prefix}-group-work {
  &:before {
    content: $md-iconset-group-work;
  }
}
.#{$md-icon-prefix}-hd {
  &:before {
    content: $md-iconset-hd;
  }
}
.#{$md-icon-prefix}-hq {
  &:before {
    content: $md-iconset-hq;
  }
}
.#{$md-icon-prefix}-markunread-mailbox {
  &:before {
    content: $md-iconset-markunread-mailbox;
  }
}
.#{$md-icon-prefix}-memory {
  &:before {
    content: $md-iconset-memory;
  }
}
.#{$md-icon-prefix}-nfc {
  &:before {
    content: $md-iconset-nfc;
  }
}
.#{$md-icon-prefix}-play-for-work {
  &:before {
    content: $md-iconset-play-for-work;
  }
}
.#{$md-icon-prefix}-power-input {
  &:before {
    content: $md-iconset-power-input;
  }
}
.#{$md-icon-prefix}-present-to-all {
  &:before {
    content: $md-iconset-present-to-all;
  }
}
.#{$md-icon-prefix}-satellite {
  &:before {
    content: $md-iconset-satellite;
  }
}
.#{$md-icon-prefix}-tap-and-play {
  &:before {
    content: $md-iconset-tap-and-play;
  }
}
.#{$md-icon-prefix}-vibration {
  &:before {
    content: $md-iconset-vibration;
  }
}
.#{$md-icon-prefix}-voicemail {
  &:before {
    content: $md-iconset-voicemail;
  }
}