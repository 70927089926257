/*----------------------------------
    Avatars
-----------------------------------*/
.avatar-img,
.avatar-char {
    border-radius: 50%;

    &:not(.a-lg) {
        width: 38px;
        height: 38px;
    }

    &.a-lg {
        width: 50px;
        height: 50px;
    }
}

.avatar-char {
    color: #fff;
    line-height: 38px;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    
    &.ac-light {
        color: #717171;
    }
}

.ac-check {
    position: relative;
    cursor: pointer;

    .acc-helper,
    .acc-check {
        position: absolute;
        height: 100%;
        width:100%;
        top: 0;
        left: 0;
    }

    .acc-check {
        z-index: 1;
        margin: 0;
        @include opacity(0);

        &:checked + span {
            background-color: #e1e1e1;
            font-size: 0px;

            &:before {
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                border: 0;
                background-color: transparent;
                content: '\f26b';
                font-family: $font-icon-md;
                font-size: 23px;
                color: #fff;
            }
        }
    }

    .acc-helper {
        border-radius: 50%;
        @include transition(all);
        @include transition-duration(100ms);
    }

    &:hover {
        .acc-helper {
            background-color: #e1e1e1;

            &:before {
                content: '';
                position: absolute;
                width: 18px;
                height: 18px;
                border-radius: 2px;
                border: 2px solid #7a7a7a;
                background-color: #fff;
                top: 10px;
                left: 10px;
            }
        }
    }
}

/*----------------------------------
    View More
-----------------------------------*/
.view-more {
    text-align: center;
    padding: 10px 0;
    color: #AEAEAE;
    font-size: 12px;
    margin-top: 10px;
    border-bottom: 0;
    
    &:hover {
        color: #929292;
    }
    
    i {
        font-size: 15px;
        line-height: 100%;
        width: 29px;
        height: 29px;
        border-radius: 50%;
        background: #EFEFEF;
        text-align: center;
        line-height: 30px;
        margin-right: 5px;
        color: #9E9E9E;
    }
}


/*
 * Content Header
 * Used for Heading outside the Cards.
 */
.c-header {
    @media screen and (min-width: $screen-sm-min) {
        padding: 0 32px;
    }

    @media screen and (max-width: $screen-sm-max) {
        padding: 0 18px;
    }

    margin-bottom: 25px;
    position: relative;

    & > h2 {
        font-size: 15px;
        color: #fff;
        margin: 0;
        font-weight: 400;
        text-transform: uppercase;

        & > small {
            display: block;
            text-transform: none;
            margin-top: 8px;
            margin-bottom: 20px;
            color: rgba(255, 255, 255, 0.6);
            line-height: 140%;
            font-size: 13px;
        }
    }

    .actions {
        position: absolute;
        right: 15px;
        top: -5px;
        z-index: 4;
    }
}

/*
 * Header Actions
 */

.actions {
    list-style: none;
    padding: 0;
    z-index: 3;
    margin: 0;

    & > li {
        display: inline-block;
        vertical-align: baseline;
        
        & > a {
            width: 30px;
            height: 30px;
            display: inline-block;
            text-align: center;
            padding-top: 5px;
            border-radius: 50%;
    
            & > i {
                color: #adadad;
                font-size: 20px;
            }
    
            &:hover {
                background-color: rgba(0,0,0,0.08);
                
                & > i {
                    color: #000;
                }
            }
        }
        
        & > a,
        & > a > i {
            @include transition(all);
            @include transition-duration(400ms);
        }
    }

    &.a-alt > li > a > i {
        color: #fff;
    }
}

/*
 * Collapse Menu Icons
 */
.menu-collapse:before,
.mc-wrap,
.mcw-line {
    @include transition(all);
    @include transition-duration(300ms);
}

.menu-collapse {
    display: block;
    cursor: pointer;
    position: relative;
    width: 55px;
    height: 35px;
    padding: 12px 0 0 8px;
    margin-top: 18px;
    margin-left: 21px;
    @include mq(md){
        display: none;
    }
    &:before {
        content: "";
        position: absolute;
        top: -5px;
        left: -6px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.12);
        @include scale(0);
        z-index: 0;
    }
    
    &.toggled {
        .mc-wrap {
            @include rotate(180deg);
    
            .mcw-line {
                &.top {
                    width: 12px;
                    transform: translateX(8px) translateY(1px) rotate(45deg);
                    -webkit-transform: translateX(8px) translateY(1px) rotate(45deg);
                }
    
                &.bottom {
                    width: 12px;
                    transform: translateX(8px) translateY(-1px) rotate(-45deg);
                    -webkit-transform: translateX(8px) translateY(-1px) rotate(-45deg);
                }
            }
        }
        
        &:before {
            @include scale(1);
        }
    }

    
}

.mc-wrap {
    width: 18px;
    height: 12px;
 
    .mcw-line{
        width: 18px;
        height: 2px;

        &.center {
            margin: 3px 0;
        }
    }
}

/*
 * Load More
 */
.load-more {
    text-align: center;
    margin-top: 30px;

    a {
        padding: 8px 15px 6px;
        display: inline-block;
        background-color: $m-red;
        color: #FFF;
        border-radius: 2px;
        white-space: nowrap;

        i {
            font-size: 20px;
            vertical-align: middle;
            position: relative;
            margin-top: -2px;
        }

        &:hover {
            background-color: darken($m-red, 10%);
        }
    }
}

/*
 * Page Loader
 */

html {
    &:not(.ismobile) {
        .page-loader {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1000;

            .preloader {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
    
    &.ismobile .page-loader {
        display: none;
    }
}

/*----------------------------------------
    Action Header
-----------------------------------------*/
.action-header {
    padding: 25px 30px;
    line-height: 100%;
    font-weight: 500;
    color: #4C4C4C;
    margin-bottom: 10px;
    font-size: 15px;
    position: relative;
    z-index: 1;
    background-color: #f7f7f7;
    min-height: 65px;
    border-bottom:1px solid $palette-Grey-200;
    .actions {
        position: absolute;
        top: 18px;
        right: 17px;
        z-index: 10;
    }
}

.ah-search {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 11;
    background: #fff;
    display: none;
}

.ahs-input {
    border: 0;
    padding: 0 26px 0 55px;
    height: 100%;
    font-size: 15px;
    width: 100%;
    font-weight: 100;
    background: #fff;
    border-bottom: 1px solid #EEE;
    line-height: 60px;
}

.ah-search-close {
    font-style: normal;
    position: absolute;
    top: 23px;
    left: 22px;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}


/*----------------------------------
    Body overflow hidden
-----------------------------------*/
body.o-hidden {
    overflow: hidden;
    height: 100%;
    height: 100vh;
}