/*--

TEMPLATE NAME: Sinewave Hosting HTML Template
TEMPLATE URI: - http://froid.works/themeforest/sinewave/resource/
DESCRIPTION: Sinewave Hosting HTML Template
VERSION: 1.0
AUTHOR: Ajay Kumar Choudhary
AUTHOR URL: http://codecanyon.net/user/ajay138/
DESIGN BY: Rakesh Kumar

[TABLE OF CONTENTS]

1.0 BUTTONS
2.0 ALERTS
3.0 FORM ELEMENTS
4.0 MENU
5.0 PAGE STYLING
6.0 RESET

--*/


/*
 * Overwrite Variables
 */
@import 'inc/variables';
/*
 * Load Main Bootstrap SCSS files
 */

@import '../../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss';

@import 'base/variables';
@import 'base/colors_variable';
@import 'base/mixin';
@import 'components/buttons';
@import 'partial/header';
@import 'partial/sidebar';
@import 'partial/footer';
@import 'partial/content';
//@import 'base/demo';





/*
 * Variables and Mixin
 */

@import 'inc/mixin';
@import 'inc/generics';

///*
// * LESS Plugins
// */
//@import 'inc/less-plugins/for';

/*
 * Load Font
 */
@import 'inc/font';

///*
// * Vendors
// */
@import '../../../bower_components/jasny-bootstrap/scss/fileinput';
@import '../../../bower_components/material-shadows/material-shadows';
@import '../../../bower_components/Waves/src/scss/waves';
@import '../../../bower_components/simple-line-icons/scss/simple-line-icons';
@import '../../../bower_components/material-design-iconic-font/scss/material-design-iconic-font';
@import '../../../bower_components/lightgallery/src/sass/lightgallery';
@import '../../../bower_components/bootstrap-select/sass/bootstrap-select';
@import '../../../bower_components/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build';

/*
 * Load Website related SCSS files
 */
@import 'inc/bootstrap-overrides';
@import 'inc/base';
@import 'inc/list';
//@import 'inc/header';
//@import 'inc/sidebar';
@import 'inc/dropdown';
@import 'inc/listview';
@import 'inc/badge';
@import 'inc/label';
@import 'inc/list-group';
@import 'inc/progress-bar';
@import 'inc/tabs';
@import 'inc/card';
@import 'inc/chart';
@import 'inc/widgets';
@import 'inc/table';
@import 'inc/button';
@import 'inc/form';
@import 'inc/pagination';
@import 'inc/breadcrumb';
@import 'inc/popover';
@import 'inc/wizard';
@import 'inc/alert';
@import 'inc/media';
@import 'inc/modal';
@import 'inc/panel';
@import 'inc/tooltip';
@import 'inc/messages';
@import 'inc/404';
@import 'inc/login';
@import 'inc/profile';
@import 'inc/photos';
@import 'inc/contacts';
@import 'inc/shadow';
@import 'inc/misc';
@import 'inc/ie-warning';
//@import 'inc/footer';
@import 'inc/pricing-table';
@import 'inc/invoice';
@import 'inc/wall';
@import 'inc/preloader';
@import 'inc/skin';
@import 'inc/thumbnail';
@import 'inc/dashboard';

@import 'inc/print';

/*
 * Vendor Overrides
 */
@import 'inc/vendor-overrides/fullcalendar';
@import 'inc/vendor-overrides/bootgrid';
@import 'inc/vendor-overrides/bootstrap-select';
@import 'inc/vendor-overrides/chosen';
@import 'inc/vendor-overrides/noUiSlider';
@import 'inc/vendor-overrides/farbtastic';
@import 'inc/vendor-overrides/summernote';
@import 'inc/vendor-overrides/bootstrap-datetimepicker';
@import 'inc/vendor-overrides/fileinput';
@import 'inc/vendor-overrides/light-gallery';
@import 'inc/vendor-overrides/waves';
@import 'inc/vendor-overrides/sweetalert';
@import 'inc/vendor-overrides/typeahead';
@import 'inc/vendor-overrides/malihu-custom-scrollbar';
@import 'inc/vendor-overrides/dropzone';
@import 'inc/vendor-overrides/cropper';
@import 'inc/vendor-overrides/color-picker';


@import 'base/reset';
@import 'custom';

