#messages {
    position: relative;
    margin-bottom: 0;
    
    @media(max-width: $screen-xs-max) {
        overflow: hidden;
    }
}

.ms-user {
    height: 65px;
    padding: 17px 24px;

    & > img {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.42);
        border-radius: 50%;
        width: 30px;
        float: left;
    }

    & > div {
        overflow: hidden;
        padding: 0px 5px 7px 12px;
        font-size: 11px;
        color: #fff;
    }
}

.ms-menu {
    position: absolute;
    left: 0;
    top: 0;
    padding-bottom: 50px;
    height: 100%;
    background: #fff;
    width: 250px;
    @include transition(all);
    @include transition-duration(250ms);

    &:before {
        position: absolute;
        right: 0;
        top: 65px;
        content: '';
        height: calc(100% - 65px);
        width: 2px;
        background-color: #f5f5f5;
        z-index: 1;
    }

    @media (max-width: $screen-xs-max) {
        height: calc(100% - 58px);
        @include translate3d(-240px, 0, 0);
        @include opacity(0);
        z-index: 1;
        box-shadow: 0px 0 30px rgba(0, 0, 0, 0.2);
        height: 100%;

        &.toggled {
            @include translate3d(0, 0, 0);
            @include opacity(1);
            
            &:before {
                display: none;
            }
        }
    }

    .list-group-item {
        padding: 12px 22px !important;
    }
}

.ms-body {
    @media (min-width: $screen-sm-min) {
        padding-left: 250px;
    }

    @media (max-width: $screen-xs-max) {
        overflow: hidden;
    }

    .list-group-item:nth-child(odd) .msb-item {
        background-color: $palette-Grey-200;
    }

    .list-group-item:nth-child(even) {
        text-align: right;

        .msb-item {
            background-color: $palette-Amber-500;
            color: #fff;
        }
    }

    .menu-collapse {
        float: left;
        margin: -10px;
    }
}

#ms-menu-trigger {
    @include user-select(none);
    float: left;
    margin: 1px 0 0 -7px;

    @media (min-width: $screen-sm-min) {
        display: none;
    }

    .line-wrap .line {
        background-color: #717171;
    }
}

.msb-item {
    padding: 13px 19px 15px;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 5px;

    @media (min-width: $screen-sm-min) {
        max-width: 70%;
    }
}

.ms-date {
    display: block;
    color: #B3B3B3;

    & > i {
        font-size: 14px;
        vertical-align: bottom;
        line-height: 100%;
    }
}

.ms-reply {
    position: relative;
    border-top: 2px solid #f5f5f5;
    margin-top: 30px;

    textarea {
        width: 100%;
        font-size: 13px;
        border: 0;
        padding: 12px 14px;
        resize: none;
        height: 80px;
    }

    button {
        position: absolute;
        top: 16px;
        right: 17px;
        border: 0;
        height: 50px;
        width: 50px;
        font-size: 22px;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        background: $palette-Green-400;

        &:hover {
            background: $palette-Green-500;
        }
    }
}