@mixin ma-header($color) {
  &:before,
  #header {
    background-color: $color;
  }

  .smm-alerts > li.active,
  .main-menu li.active > a {
    color: $color;
  }
}

[data-ma-header="teal"] { @include ma-header($palette-Teal-500); }
[data-ma-header="blue"] { @include ma-header($palette-Blue-500); }
[data-ma-header="bluegrey"] { @include ma-header($palette-Blue-Grey-500); }
[data-ma-header="cyan-600"] { @include ma-header($palette-Cyan-600); }
[data-ma-header="green"] { @include ma-header($palette-Green-500); }
[data-ma-header="lightgreen"] { @include ma-header($palette-Light-Green-500); }
[data-ma-header="purple-400"] { @include ma-header($palette-Purple-400); }
[data-ma-header="red-400"] { @include ma-header($palette-Red-400); }
[data-ma-header="pink-400"] { @include ma-header($palette-Pink-400); }
[data-ma-header="brown"] { @include ma-header($palette-Brown-500); }
[data-ma-header="grey-600"] { @include ma-header($palette-Grey-600); }
[data-ma-header="orange"] { @include ma-header($palette-Orange-500); }