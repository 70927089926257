.photos {
    margin: -10px 0 0;
    
    .lightbox {
        margin: 0 -8px;
    }
    
    &:not(.p-timeline) {
        [data-src] {
            padding: 3px; 
            @include transition(all);
            @include transition-duration(150ms);
        }
    }
}

.p-timeline {
    position: relative;
    padding-left: 80px;
    margin-bottom: 75px;
    
    [data-src] {
        float: left;
        width: 70px;
        height: 70px;
        margin: 0 3px 3px 0;
    }
    
            
    &:last-child .pt-line:before {
        height: 100%;
    }
}

.ptb-title { 
    font-size: 15px;
    font-weight: 400; 
    margin-bottom: 20px; 
}

.pt-line {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    line-height: 14px;
    
    &:before,
    &:after {
        content: "";
        position: absolute;
    }
    
    &:before {
        width: 1px;
        height: calc(100% + 63px);
        background: #E2E2E2;
        top: 14px;
        right: -20px;
    }
    
    &:after {
        top: 2px;
        right: -26px;
        width: 13px;
        height: 13px;
        border: 1px solid #C1C1C1;
        border-radius: 50%;
    }
}