.breadcrumb {
  border-bottom: 1px solid #ddd;
  background-color: transparent;
  padding: 10px 33px 11px
}

.breadcrumb>li>a {
  color: #5E5E5E
}
.breadcrumb>li>a:hover {
  color: #7c7c7c
}