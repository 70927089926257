.btn {
    border: 0;
    border-radius: 2px;

    .caret {
        margin-top: -3px;
    }
     
    &:not(.btn-link) {
        @include z-depth(1);
    }

    &:not(.bgm-white):not(.btn-default) {
        color: #fff;
    }

    &.bgm-white {
        color: #333;
    }
}

.btn-group, .btn-group-vertical {
    &:not(.bootstrap-select) {
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    }

    .btn,
    .btn:active,
    .btn:focus,
    .btn-group {
        box-shadow: none !important;
    }
    
    .btn {
        margin: 0;
    }
}

.btn-xs {
  @include button-size(2px, $padding-xs-horizontal, 11px, $line-height-small, $border-radius-small);
}

.btn-link {
    color: #797979;
    text-decoration: none;
    border-radius: 2px;

    &:hover {
        color: #0a0a0a;
    }

    &:hover,
    &:active,
    &:focus  {
        text-decoration: none;
    }
}

.btn-inverse {
    @include button-variant(#fff, #454545, transparent);
}

.btn-icon {
    border-radius: 50%;
    width: 40px;
    line-height: 43px;
    height: 40px;
    padding: 0;
    text-align: center;
    
    .zmdi {
        font-size: 17px;
    }
}

.btn-icon-text {
    & > .zmdi {
        font-size: 15px;
        vertical-align: top;
        display: inline-block;
        margin-top: 2px;
        line-height: 100%;
        margin-right: 5px;
    }
}

.btn-float { 
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 45px !important;
    
    &:not(.m-btn) {
        position: absolute !important;
    }

    i {
        font-size: 23px;
        @include transition(all);
        @include transition-duration(500ms);
    }
    
    &:hover {
        i {
            @include rotate(360deg);
        }
    }
}


.open .btn {
    outline: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    
    &:focus, &:active {
        outline: none !important;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    }
}

/*
 * Material Design Add button
 */
.m-btn {
    z-index: 1;
    bottom: 40px;
    right: 40px;
    position: fixed !important;
}
.btn-primary {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}
// Success appears as green
.btn-success {
    @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}
// Info appears as blue-green
.btn-info {
    @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}
// Warning appears as orange
.btn-warning {
    @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
    @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}