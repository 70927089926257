.dropzone {
  border: 0;
  background-color: #f3f3f3;
  border-radius: 2px;
  .dz-message span {
    font-size: 20px;
    color: #b5b5b5;
    text-shadow: 0 1px 1px #fff;
    border: 2px solid #e4e4e4;
    padding: 15px 20px;
    display: inline-block;
    border-radius: 5px;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    &:before{
      content: '\f21e';
      font-family: Material-Design-Iconic-Font;
      margin: -6px 10px 0 0;
      font-size: 25px;
      display: inline-block;
      position: relative;
      top: 2px;
    }
  }
}