/*
 * Font Icon Family
 */
$font-icon-md:              'Material-Design-Iconic-Font';

/*
 * Material Colors
 */
$m-white:                  #ffffff;
$m-black:                  #000000;
$m-blue:                   #3096f3;
$m-red:                    #e94034;
$m-purple:                 #9C27B0;
$m-deeppurple:             #673AB7;
$m-lightblue:              #03A9F4;
$m-cyan:                   #55bcd5;
$m-teal:                   #009688;
$m-green:                  #59af51;
$m-lightgreen:             #8BC34A;
$m-lime:                   #CDDC39;
$m-yellow:                 #FFEB3B;
$m-amber:                  #FFC107;
$m-orange:                 #f19634;
$m-deeporange:             #FF5722;
$m-gray:                   #9E9E9E;
$m-bluegray:               #607D8B;
$m-indigo:                 #3F51B5;
$m-pink:                   #E91E63;
$m-brown:                  #795548;

$m-dark:                   #1f292f;
$m-card:                   #263238;
$overlay-white:            rgba(255,255,255,0.12);
$overlay-black:            rgba(0, 0, 0, 0.04);


//Color Palette
$palette-Red-50: #FFEBEE;
$palette-Red-100: #FFCDD2;
$palette-Red-200: #EF9A9A;
$palette-Red-300: #E57373;
$palette-Red-400: #EF5350;
$palette-Red-500: #F44336;
$palette-Red-600: #E53935;
$palette-Red-700: #D32F2F;
$palette-Red-800: #C62828;
$palette-Red-900: #B71C1C;
$palette-Red-A100: #FF8A80;
$palette-Red-A200: #FF5252;
$palette-Red-A400: #FF1744;
$palette-Red-A700: #D50000;
$palette-Pink-50: #FCE4EC;
$palette-Pink-100: #F8BBD0;
$palette-Pink-200: #F48FB1;
$palette-Pink-300: #F06292;
$palette-Pink-400: #EC407A;
$palette-Pink-500: #E91E63;
$palette-Pink-600: #D81B60;
$palette-Pink-700: #C2185B;
$palette-Pink-800: #AD1457;
$palette-Pink-900: #880E4F;
$palette-Pink-A100: #FF80AB;
$palette-Pink-A200: #FF4081;
$palette-Pink-A400: #F50057;
$palette-Pink-A700: #C51162;
$palette-Purple-50: #F3E5F5;
$palette-Purple-100: #E1BEE7;
$palette-Purple-200: #CE93D8;
$palette-Purple-300: #BA68C8;
$palette-Purple-400: #AB47BC;
$palette-Purple-500: #9C27B0;
$palette-Purple-600: #8E24AA;
$palette-Purple-700: #7B1FA2;
$palette-Purple-800: #6A1B9A;
$palette-Purple-900: #4A148C;
$palette-Purple-A100: #EA80FC;
$palette-Purple-A200: #E040FB;
$palette-Purple-A400: #D500F9;
$palette-Purple-A700: #AA00FF;
$palette-Deep-Purple-50: #EDE7F6;
$palette-Deep-Purple-100: #D1C4E9;
$palette-Deep-Purple-200: #B39DDB;
$palette-Deep-Purple-300: #9575CD;
$palette-Deep-Purple-400: #7E57C2;
$palette-Deep-Purple-500: #673AB7;
$palette-Deep-Purple-600: #5E35B1;
$palette-Deep-Purple-700: #512DA8;
$palette-Deep-Purple-800: #4527A0;
$palette-Deep-Purple-900: #311B92;
$palette-Deep-Purple-A100: #B388FF;
$palette-Deep-Purple-A200: #7C4DFF;
$palette-Deep-Purple-A400: #651FFF;
$palette-Deep-Purple-A700: #6200EA;
$palette-Indigo-50: #E8EAF6;
$palette-Indigo-100: #C5CAE9;
$palette-Indigo-200: #9FA8DA;
$palette-Indigo-300: #7986CB;
$palette-Indigo-400: #5C6BC0;
$palette-Indigo-500: #3F51B5;
$palette-Indigo-600: #3949AB;
$palette-Indigo-700: #303F9F;
$palette-Indigo-800: #283593;
$palette-Indigo-900: #1A237E;
$palette-Indigo-A100: #8C9EFF;
$palette-Indigo-A200: #536DFE;
$palette-Indigo-A400: #3D5AFE;
$palette-Indigo-A700: #304FFE;
$palette-Blue-50: #E3F2FD;
$palette-Blue-100: #BBDEFB;
$palette-Blue-200: #90CAF9;
$palette-Blue-300: #64B5F6;
$palette-Blue-400: #42A5F5;
$palette-Blue-500: #2196F3;
$palette-Blue-600: #1E88E5;
$palette-Blue-700: #1976D2;
$palette-Blue-800: #1565C0;
$palette-Blue-900: #0D47A1;
$palette-Blue-A100: #82B1FF;
$palette-Blue-A200: #448AFF;
$palette-Blue-A400: #2979FF;
$palette-Blue-A700: #2962FF;
$palette-Light-Blue-50: #E1F5FE;
$palette-Light-Blue-100: #B3E5FC;
$palette-Light-Blue-200: #81D4FA;
$palette-Light-Blue-300: #4FC3F7;
$palette-Light-Blue-400: #29B6F6;
$palette-Light-Blue-500: #03A9F4;
$palette-Light-Blue-600: #039BE5;
$palette-Light-Blue-700: #0288D1;
$palette-Light-Blue-800: #0277BD;
$palette-Light-Blue-900: #01579B;
$palette-Light-Blue-A100: #80D8FF;
$palette-Light-Blue-A200: #40C4FF;
$palette-Light-Blue-A400: #00B0FF;
$palette-Light-Blue-A700: #0091EA;
$palette-Cyan-50: #E0F7FA;
$palette-Cyan-100: #B2EBF2;
$palette-Cyan-200: #80DEEA;
$palette-Cyan-300: #4DD0E1;
$palette-Cyan-400: #26C6DA;
$palette-Cyan-500: #00BCD4;
$palette-Cyan-600: #00ACC1;
$palette-Cyan-700: #0097A7;
$palette-Cyan-800: #00838F;
$palette-Cyan-900: #006064;
$palette-Cyan-A100: #84FFFF;
$palette-Cyan-A200: #18FFFF;
$palette-Cyan-A400: #00E5FF;
$palette-Cyan-A700: #00B8D4;
$palette-Teal-50: #E0F2F1;
$palette-Teal-100: #B2DFDB;
$palette-Teal-200: #80CBC4;
$palette-Teal-300: #4DB6AC;
$palette-Teal-400: #26A69A;
$palette-Teal-500: #009688;
$palette-Teal-600: #00897B;
$palette-Teal-700: #00796B;
$palette-Teal-800: #00695C;
$palette-Teal-900: #004D40;
$palette-Teal-A100: #A7FFEB;
$palette-Teal-A200: #64FFDA;
$palette-Teal-A400: #1DE9B6;
$palette-Teal-A700: #00BFA5;
$palette-Green-50: #E8F5E9;
$palette-Green-100: #C8E6C9;
$palette-Green-200: #A5D6A7;
$palette-Green-300: #81C784;
$palette-Green-400: #66BB6A;
$palette-Green-500: #4CAF50;
$palette-Green-600: #43A047;
$palette-Green-700: #388E3C;
$palette-Green-800: #2E7D32;
$palette-Green-900: #1B5E20;
$palette-Green-A100: #B9F6CA;
$palette-Green-A200: #69F0AE;
$palette-Green-A400: #00E676;
$palette-Green-A700: #00C853;
$palette-Light-Green-50: #F1F8E9;
$palette-Light-Green-100: #DCEDC8;
$palette-Light-Green-200: #C5E1A5;
$palette-Light-Green-300: #AED581;
$palette-Light-Green-400: #9CCC65;
$palette-Light-Green-500: #8BC34A;
$palette-Light-Green-600: #7CB342;
$palette-Light-Green-700: #689F38;
$palette-Light-Green-800: #558B2F;
$palette-Light-Green-900: #33691E;
$palette-Light-Green-A100: #CCFF90;
$palette-Light-Green-A200: #B2FF59;
$palette-Light-Green-A400: #76FF03;
$palette-Light-Green-A700: #64DD17;
$palette-Lime-50: #F9FBE7;
$palette-Lime-100: #F0F4C3;
$palette-Lime-200: #E6EE9C;
$palette-Lime-300: #DCE775;
$palette-Lime-400: #D4E157;
$palette-Lime-500: #CDDC39;
$palette-Lime-600: #C0CA33;
$palette-Lime-700: #AFB42B;
$palette-Lime-800: #9E9D24;
$palette-Lime-900: #827717;
$palette-Lime-A100: #F4FF81;
$palette-Lime-A200: #EEFF41;
$palette-Lime-A400: #C6FF00;
$palette-Lime-A700: #AEEA00;
$palette-Yellow-50: #FFFDE7;
$palette-Yellow-100: #FFF9C4;
$palette-Yellow-200: #FFF59D;
$palette-Yellow-300: #FFF176;
$palette-Yellow-400: #FFEE58;
$palette-Yellow-500: #FFEB3B;
$palette-Yellow-600: #FDD835;
$palette-Yellow-700: #FBC02D;
$palette-Yellow-800: #F9A825;
$palette-Yellow-900: #F57F17;
$palette-Yellow-A100: #FFFF8D;
$palette-Yellow-A200: #FFFF00;
$palette-Yellow-A400: #FFEA00;
$palette-Yellow-A700: #FFD600;
$palette-Amber-50: #FFF8E1;
$palette-Amber-100: #FFECB3;
$palette-Amber-200: #FFE082;
$palette-Amber-300: #FFD54F;
$palette-Amber-400: #FFCA28;
$palette-Amber-500: #FFC107;
$palette-Amber-600: #FFB300;
$palette-Amber-700: #FFA000;
$palette-Amber-800: #FF8F00;
$palette-Amber-900: #FF6F00;
$palette-Amber-A100: #FFE57F;
$palette-Amber-A200: #FFD740;
$palette-Amber-A400: #FFC400;
$palette-Amber-A700: #FFAB00;
$palette-Orange-50: #FFF3E0;
$palette-Orange-100: #FFE0B2;
$palette-Orange-200: #FFCC80;
$palette-Orange-300: #FFB74D;
$palette-Orange-400: #FFA726;
$palette-Orange-500: #FF9800;
$palette-Orange-600: #FB8C00;
$palette-Orange-700: #F57C00;
$palette-Orange-800: #EF6C00;
$palette-Orange-900: #E65100;
$palette-Orange-A100: #FFD180;
$palette-Orange-A200: #FFAB40;
$palette-Orange-A400: #FF9100;
$palette-Orange-A700: #FF6D00;
$palette-Deep-Orange-50: #FBE9E7;
$palette-Deep-Orange-100: #FFCCBC;
$palette-Deep-Orange-200: #FFAB91;
$palette-Deep-Orange-300: #FF8A65;
$palette-Deep-Orange-400: #FF7043;
$palette-Deep-Orange-500: #FF5722;
$palette-Deep-Orange-600: #F4511E;
$palette-Deep-Orange-700: #E64A19;
$palette-Deep-Orange-800: #D84315;
$palette-Deep-Orange-900: #BF360C;
$palette-Deep-Orange-A100: #FF9E80;
$palette-Deep-Orange-A200: #FF6E40;
$palette-Deep-Orange-A400: #FF3D00;
$palette-Deep-Orange-A700: #DD2C00;
$palette-Brown-50: #EFEBE9;
$palette-Brown-100: #D7CCC8;
$palette-Brown-200: #BCAAA4;
$palette-Brown-300: #A1887F;
$palette-Brown-400: #8D6E63;
$palette-Brown-500: #795548;
$palette-Brown-600: #6D4C41;
$palette-Brown-700: #5D4037;
$palette-Brown-800: #4E342E;
$palette-Brown-900: #3E2723;
$palette-Grey-50: #FAFAFA;
$palette-Grey-100: #F5F5F5;
$palette-Grey-200: #EEEEEE;
$palette-Grey-300: #E0E0E0;
$palette-Grey-400: #BDBDBD;
$palette-Grey-500: #9E9E9E;
$palette-Grey-600: #757575;
$palette-Grey-700: #616161;
$palette-Grey-800: #424242;
$palette-Grey-900: #212121;
$palette-Blue-Grey-50: #ECEFF1;
$palette-Blue-Grey-100: #CFD8DC;
$palette-Blue-Grey-200: #B0BEC5;
$palette-Blue-Grey-300: #90A4AE;
$palette-Blue-Grey-400: #78909C;
$palette-Blue-Grey-500: #607D8B;
$palette-Blue-Grey-600: #546E7A;
$palette-Blue-Grey-700: #455A64;
$palette-Blue-Grey-800: #37474F;
$palette-Blue-Grey-900: #263238;
$palette-Black-500: #000000;
$palette-Black-Text: rgba(0,0,0,0.87);
$palette-Black-Secondary-Text: rgba(0,0,0,0.54);
$palette-Black-Icons: rgba(0,0,0,0.54);
$palette-Black-Disabled: rgba(0,0,0,0.26);
$palette-Black-Hint-Text: rgba(0,0,0,0.26);
$palette-Black-Dividers: rgba(0,0,0,0.12);
$palette-White-500: #ffffff;
$palette-White-Text: #ffffff;
$palette-White-Secondary-Text: rgba(255,255,255,0.7);
$palette-White-Icons: #ffffff;
$palette-White-Disabled: rgba(255,255,255,0.3);
$palette-White-Hint-Text: rgba(255,255,255,0.3);
$palette-White-Dividers: rgba(255,255,255,0.12);

/* --------------------------------------------------------
    Container
-----------------------------------------------------------*/
$container-tablet:                      100%;
$container-desktop:                     100%;
$container-large-desktop:               100%;


/* Typography + Scaffolding + Links */
$body-bg:                   #edecec;
$text-color:                #5E5E5E;
$font-family-sans-serif:    noto-sans;
$font-size-base:            13px;
$link-hover-decoration:     none;
$headings-color:			#000000;
$sidebar-link-color:        #4C4C4C;

/* Border Radius */
$border-radius-base:        2px;
$border-radius-large:       4px;
$border-radius-small:       2px;

/* Tabs */
$nav-tabs-border-color:                     #fff;
$nav-tabs-active-link-hover-border-color:   #fff;
$nav-tabs-active-link-hover-bg:             transparent;

/* Form */
$input-border:              #e0e0e0;
$input-color:               #000000;
$input-border-radius:       0;
$input-border-radius-large: 0px;
$input-height-large:        40px;
$input-height-base:         35px;
$input-height-small:        30px;
$input-border-focus:        #b4b4b4;

/* Table */
$table-bg:                      #fff;
$table-border-color:            #f0f0f0;
$table-cell-padding:            15px;
$table-condensed-cell-padding:  10px;
$table-bg-accent:               #f4f4f4;
$table-bg-active:               #FFFCBE;

/*
 * Input Group
 */
$input-group-addon-bg: transparent;
$input-group-addon-border-color: transparent;

/*
 * Pagination
 */
$pagination-bg:                 #E2E2E2;
$pagination-border:             #fff;
$pagination-color:              #7E7E7E;
$pagination-active-bg:          $m-cyan;
$pagination-active-border:      $pagination-border;
$pagination-disabled-bg:        #E2E2E2;
$pagination-disabled-border:    $pagination-border;
$pagination-hover-color:        #333;
$pagination-hover-bg:           #d7d7d7;
$pagination-hover-border:       $pagination-border;
$pager-border-radius:           5px;

/*
 * Popover
 */
$popover-fallback-border-color: transparent;
$popover-border-color:          transparent;

/*
 * Dropdown
 */
$dropdown-fallback-border:      transparent;
$dropdown-border:               transparent;
$dropdown-divider-bg:           '';
$dropdown-link-hover-bg:        rgba(0,0,0,0.075);
$dropdown-link-color:           $text-color;
$dropdown-link-hover-color:     #333;
$dropdown-link-disabled-color:  #e4e4e4;
$dropdown-divider-bg:           rgba(0,0,0,0.08);
$dropdown-link-active-color:    #333;
$dropdown-link-active-bg:       rgba(0, 0, 0, 0.075);
$zindex-dropdown:               9;
$dropdown-shadow:               0 2px 10px rgba(0, 0, 0, 0.2);

/*
 * Thumbnail
 */
$thumbnail-bg:                  #fff;

/*
 * Alerts
 */
$alert-success-border:                  transparent;
$alert-info-border:                     transparent;
$alert-warning-border:		 	        transparent;
$alert-danger-border:		 	        transparent;
$alert-inverse-border:		 	        transparent;

$alert-success-bg:                      $m-green;
$alert-info-bg:		 		            $m-blue;
$alert-warning-bg:                      $m-amber;
$alert-danger-bg:                       $m-red;
$alert-inverse-bg:                      #333;

$alert-success-text:                    #fff;
$alert-info-text:                       #fff;
$alert-warning-text:                    #fff;
$alert-danger-text:                     #fff;
$alert-inverse-text:                    #fff;

/*
 * Form Validations
 */
$state-success-text:                    lighten($m-green, 8%);
$state-warning-text:                    lighten($m-orange, 8%);
$state-danger-text:                     lighten($m-red, 8%);

$state-success-bg:                      lighten($m-green, 8%);
$state-warning-bg:                      lighten($m-orange, 8%);
$state-danger-bg:                       lighten($m-red, 8%);



/*
 * Buttons
 */
$border-radius-base:            2px;
$border-radius-large:           2px;
$border-radius-small:           2px;
$btn-font-weight:				400;

/*
 * Thumbnail
 */
$thumbnail-border:				#EDEDED;
$thumbnail-padding:				3px;

/*
 * Carousel
 */
$carousel-caption-color:        #fff;

/*
 * Modal
 */
$modal-content-fallback-border-color:	transparent;
$modal-content-border-color:			transparent;
$modal-backdrop-bg:						#000;
$modal-header-border-color:				transparent;
$modal-title-line-height:				transparent;
$modal-footer-border-color:				transparent;
$zindex-modal-background: 				11;

/*
 * Tooltips
 */
$tooltip-bg:                #737373;
$tooltip-opacity:           1;

/*
 * Popover
 */
$zindex-popover:                9;
$popover-title-bg:              #fff;
$popover-border-color:          #fff;
$popover-fallback-border-color: #fff;

/*
 * Jumbotron
 */
$jumbotron-bg:              #F7F7F7;

/* --------------------------------------------------------
    List Group
-----------------------------------------------------------*/
$list-group-bg:                         transparent;
$list-group-border:                     #f4f4f4;
$list-group-active-color:               #000;
$list-group-active-bg:                  #f5f5f5;
$list-group-active-border:              $list-group-border;
$list-group-disabled-color:             #B5B4B4;
$list-group-disabled-bg:                #fff;
$list-group-disabled-text-color:        #B5B4B4;


/* Bootstrap Branding */
$brand-primary:             $m-blue;
$brand-success:             $m-green;
$brand-info:                $m-cyan;
$brand-warning:             $m-orange;
$brand-danger:              $m-red;
$app-gray:                  #F7F7F7;


/*
 * Badges
 */
$badge-color:               #fff;
$badge-bg:                  $brand-primary;
$badge-font-weight:         400;
$badge-active-color:        #fff;
$badge-active-bg:           $brand-primary;



/* --------------------------------------------------------
    Header
-----------------------------------------------------------*/
$header-bg:                 $m-teal;
$header-height:             265px;




/*
 * Colors
 */
$ace:                      #F7F7F7;

/*
 * Blocks
 */
$sidebar-left-width: 270px;


/*
 * Misc
 */
$card-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
$sidebar-shadow: 0px 0 30px rgba(0, 0, 0, 0.3);

/* --------------------------------------------------------
    Custom Media Query widths
-----------------------------------------------------------*/
$screen-mobile-min: 1280px;
$screen-mobile-max: 1279px;


/* --------------------------------------------------------
    Pre
-----------------------------------------------------------*/
$pre-bg:                      #333;
$pre-color:                   #ccc;
$pre-border-color:            #333;
$pre-scrollable-max-height:   340px;

/* --------------------------------------------------------
    Buttons
-----------------------------------------------------------*/
$btn-primary-color:              #fff !default;
$btn-primary-bg:                 $brand-primary !default;
$btn-primary-border:             darken($btn-primary-bg, 5%) !default;

$btn-success-color:              #fff !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             darken($btn-success-bg, 5%) !default;

$btn-info-color:                 #fff !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                darken($btn-info-bg, 5%) !default;

$btn-warning-color:              #fff !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             darken($btn-warning-bg, 5%) !default;

$btn-danger-color:               #fff !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              darken($btn-danger-bg, 5%) !default;