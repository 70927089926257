.chart-edge {
    margin: 0 -8px 0 -10px;
    overflow: hidden;
    position: relative;

    .flot-chart {
        bottom: -14px;
    }
}

.charts-row {
    margin-top: 50px;
    margin-bottom: 20px;
}


/*----------------------------------
    Easy Pie Charts
-----------------------------------*/
.easy-pie {
    display: inline-block;
    position: relative;
}

.ep-value {
    position: absolute;
    color: #fff;
    left: 0;
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 15px;
    line-height: 74px;
    
    &:after {
        content: "%";
    }
}

.ep-lg .ep-value {
    line-height: 149px;
    font-size: 40px;
}
 

/*----------------------------------
    Number Stats Chart
-----------------------------------*/
.ns-chart {
    height: 130px;
}

.ns-item {
    & > h3 {
        color: #fff;
        margin: 0;
        @include text-overflow();
    }
    
    & > small {
        color: #fff;
    }
    
}

/*----------------------------------
    Content Anylitics
-----------------------------------*/
.c-analytics {
    padding: 14px 0 70px;
}

.ca-item {
    padding: 10px 20px 15px;
    border-radius: 2px;
    margin-top: -20px !important;
    background: rgba(255, 255, 255, 0.09);
    
    h2, small {
        color: #fff;
        @include text-overflow();
    }
    
    h2 {
        line-height: 100%;
        margin: 7px 0 0;
    }
}

.cai-chart {
    position: relative;
    top: 4px;
}


/*
 * Flot Chart
 */
[class*="flot-chart"] {
    width: 100%;
    display: block;
}

.flot-chart {
    height: 200px;
}

.flot-chart-pie {
    height: 300px;

    @media (min-width: $screen-sm-min) {
        margin-bottom: 20px;
    }
}

.flot-tooltip, #flotTip {
    position: absolute;
    color: #333;
    display: none;
    font-size: 12px;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    padding: 5px 10px;
    background-color: #fff;
    z-index: 99999;
    border-radius: 2px;
}

[class*="flc-"] {
    text-align: center;
    margin: 10px 0 5px;
    
    table {
        display: inline-block;
    }
    
    .legendColorBox {
        & > div {
            border: #fff !important;
            
            & > div {
                border-radius: 50%;
            }
        }
    }
    
    .legendLabel {
        padding: 0 8px 0 3px;
    }
}

/*----------------------------------
    Sparkline Charts
-----------------------------------*/
#jqstooltip {
    min-width: 21px;
    min-height: 23px;
    border: 0;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    background-color: #fff;
    border-radius: 2px;

    .jqsfield {
        font-size: 12px;
        font-family: inherit;
        text-align: center;
        color: #333;

        & > span {
            display: none;
        }
    }
}

.mini-charts-item {
    position: relative;
    margin-bottom: 30px
}
.mini-charts-item .chart {
    padding: 21px 18px 0;
    float: left
}

.mini-charts-item:before {
    -webkit-transition: width;
    -o-transition: width;
    transition: width;
    -webkit-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 105px;
    height: 100%;
    background: rgba(0, 0, 0, .1)!important;
    position: absolute;
    top: 0
}
.mini-charts-item:hover .count {
    color: #fff!important
}
.mini-charts-item:hover:before {
    width: 100%!important;
}


.mini-charts-item .chart.chart-pie {
    margin: 0 20px;
    padding: 14px 11px
}
.mini-charts-item .count {
    overflow: hidden;
    color: rgba(255, 255, 255, .9);
    padding: 16px 12px
}
.mini-charts-item .count>h2 {
    margin: 0;
    line-height: 100%;
    font-size: 22px;
    font-weight: 300;
    color: #fff
}
.mini-charts-item .count>small {
    margin-bottom: 2px;
    display: block
}
.mini-charts-item .count>h2,
.mini-charts-item .count>small {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
.mini-charts-item>.btn-group-vertical>.btn-group,
.mini-charts-item>.btn-toolbar,
.mini-charts-item>.clearfix,
.mini-charts-item>.container,
.mini-charts-item>.container-fluid,
.mini-charts-item>.dl-horizontal dd,
.mini-charts-item>.form-horizontal .form-group,
.mini-charts-item>.modal-footer,
.mini-charts-item>.modal-header,
.mini-charts-item>.nav,
.mini-charts-item>.navbar,
.mini-charts-item>.navbar-collapse,
.mini-charts-item>.navbar-header,
.mini-charts-item>.pager,
.mini-charts-item>.panel-body,
.mini-charts-item>.row {
    position: relative;
    z-index: 1
}

    //Easy Pie Chart

.epc-item {
    box-shadow: 0 1px 1px rgba(0, 0, 0, .15);
    position: relative;
    margin-bottom: 30px;
    padding: 30px 20px;
    text-align: center
}
.easy-pie {
    display: inline-block;
    position: relative;
    padding: 0 0 10px
}
.easy-pie .percent {
    position: absolute;
    font-weight: 300;
    width: 100%;
    line-height: 100%;
    left: 4px;
    color: #fff
}
.easy-pie .percent:after {
    content: "%"
}
.easy-pie.main-pie .percent {
    margin-top: 49px;
    font-size: 50px;
    text-align: center
}
.easy-pie.main-pie .percent:after {
    font-size: 30px
}
.easy-pie.main-pie .pie-title {
    color: #fff
}
.easy-pie:not(.main-pie) .percent {
    font-size: 24px;
    margin-top: 33px
}
.easy-pie:not(.main-pie) .percent:after {
    font-size: 20px
}
.easy-pie .pie-title {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: -3px;
    left: 0
}

    