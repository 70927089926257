.footer{
  color: #6c7888;
  position: relative;
  height: 50px;
  padding: 16px;
  @include mq(md){
    -webkit-transition: all 350ms cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 350ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 350ms cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transform: translate3d(220px, 0, 0);
    -moz-transform: translate3d(220px, 0, 0);
    transform: translate3d(220px, 0, 0);
  }
}