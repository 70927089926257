.thumbnail {
  a{
    &:hover,
    &:focus,
    &.active {
      border-color: darken($thumbnail-border, 5%);
      box-shadow: 0 0 6px #EAEAEA;
    }
  }

  .caption {
    padding: 10px 20px 15px;
  }
}