*, button, input, i, a {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*:active,
*:hover {
    outline: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

html {
    -ms-overflow-style: none;
    overflow-x: hidden;
}

body {
  &:before {
    content: '';
    height: 265px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

audio, video {  
      outline: none;
}

p {
    margin-bottom: 20px;
}

blockquote {
    color: #AEAEAE;
}

pre {
    padding: 25px;
}

.container {
    padding: 0;

    &.c-boxed {
      max-width: 1200px;
    }
}

#main {
    position: relative;
    
    @media (min-width: $screen-mobile-min) {
        padding: 0 40px 30px $sidebar-left-width;
        min-height: calc(100vh - 159px);
    }
    
    @media (max-width: $screen-mobile-max) and (min-width: $screen-sm-min) {
        padding: 0 40px 30px;
    }
    
    @media (max-width: $screen-xs-max)  {
        padding: 0 15px 30px;
    }
}
