//
// List groups
// --------------------------------------------------


.list-group-item {
  &:first-child {
    @include border-top-radius(0);
  }
  &:last-child {
    margin-bottom: 0;
    @include border-bottom-radius(0);
  }
}


// Interactive list items
//
// Use anchor or button elements instead of `li`s or `div`s to create interactive items.
// Includes an extra `.active` modifier class for showing selected items.

a.list-group-item,
button.list-group-item {
  color: $list-group-link-color;

  .list-group-item-heading {
    color: $list-group-link-heading-color;
  }

  // Hover state
  &:hover,
  &:focus {
    color: $list-group-link-hover-color;
    background-color: $list-group-hover-bg;
  }
}


.list-group-item {
  // Disabled state
  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $list-group-disabled-bg;
    color: $list-group-disabled-color;
    cursor: $cursor-disabled;

    // Force color to inherit for custom content
    .list-group-item-heading {
      color: inherit;
    }
    .list-group-item-text {
      color: $list-group-disabled-text-color;
    }
  }

  // Active class on item itself, not parent
  &.active,
  &.active:hover,
  &.active:focus {
    color: #ffffff;
    background-color: #2196f3;
    border-color: #2196f3;

    // Force color to inherit for custom content
    .list-group-item-heading,
    .list-group-item-heading > small,
    .list-group-item-heading > .small {
      color: inherit;
    }
    .list-group-item-text {
      color: $list-group-active-text-color;
    }
  }
}


// Contextual variants

@include list-group-item-variant(success, #4CAF50, $white);
@include list-group-item-variant(info, #2196F3, $white);
@include list-group-item-variant(warning, #FFC107, $white);
@include list-group-item-variant(danger, #F44336, $white);



