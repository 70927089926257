/*
 * Font Face
 */
@mixin font-face($family, $name, $style, $weight: 300){
    @font-face{
    font-family: $family;
    src:url('../fonts/#{$family}/#{$name}.woff') format('woff'),
        url('../fonts/#{$family}/#{$name}.svg#icon') format('svg');
    font-weight: $weight;
    font-style: $style;
}
}

/*
 * Background Repeat + Position
 */

@mixin bg-option($repeat: no-repeat, $position: center) {
    background-repeat: $repeat;
    background-position: $position;
}

/*
 * CSS Animations based on animate.css
 */

@mixin animated($name, $duration) {
    animation-name: $name;
    animation-duration: $duration;
    animation-fill-mode: both;
}

/*
 * CSS Transform - Scale and Rotate
 */
@mixin scale-rotate($scale, $rotate) {
    transform: scale($scale) rotate($rotate);
}

/*
 * User Select
 */
@mixin user-select ($val) {
    user-select: $val;
}

/*
 * Background Image Cover
 */
@mixin bg-cover($image) {
    background-image: url($image);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@mixin bg-cover-inline() {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/*
 * Tab Focus
 */
@mixin tab-focus() {
    outline: none !important;
}

/*
 * Scale 3d
 */
//@mixin scale3d($values...){
//    $process: ~`(function(e){return e=e||"1, 1, 1"})((function(){var e="#{$values}";return e=e.replace(/^\[|\]$/g,"")})())`;
//    transform: scale3d($process);
//}

/*
 * Pop-in Hover effects
 */
@mixin hover-pop($background: rgba(0,0,0,0.5), $radius: 0, $duration: 250ms, $zindex: 0) {
    @media (min-width: $screen-sm-min) {
        position: relative;

        &:before {
            left: 0;
            top: 0;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            //@include scale3d(0,0,0);
            @include transition(all);
            @include transition-duration($duration);
            @include backface-visibility(hidden);
            background-color: $background;
            z-index: $zindex;
            border-radius: $radius;
            @include opacity(0);
        }

        &:hover:before,
        &.open:before {
            //@include scale3d(1,1,1);
            @include opacity(1);
        }
    }
}

/*
 *  Override Bootstrap Button Mixin
 */
@mixin button-variant($color, $background, $border){
    color: $color;
    background-color: $background;
    border-color: $border;

&:hover,
&:focus,
&.focus,
&:active,
.open > .dropdown-toggle & {
    color: $color;
    background-color: $background;
    border-color: transparent;

    &:hover,
    &:focus,
    &.focus {
        color: $color;
        background-color: $background;
        border-color: transparent
    }
}
&:active,
&.active,
.open > .dropdown-toggle & {
    background-image: none;
}
&.disabled,
&[disabled],
fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active {
        background-color: $background;
        border-color: $border;
    }
}

.badge {
    color: $background;
    background-color: $color;
}
}

/*
 *  Override Bootstrap Button Mixin
 */
@mixin badge-variant($background) {
    background-color: $background;
}

