.alert {
    padding-left: 30px;
    font-size: 13px;
    border-radius: 2px;
    border: 1px solid transparent;
    
    span {
        cursor: pointer;
    }

    &:not(.alert-dismissible) {
        padding-right: 30px;
    }

    &.alert-dismissable {
        padding-right: 44px;
    } 
}
.alert-success {
    background-color: #4CAF50;
    color: #fff;
}
.alert-info {
    background-color: #2196F3;
    color: #fff;
}
.alert-warning {
    background-color: #FFC107;
    color: #fff;
}
.alert-danger {
    background-color: #F44336;
    color: #fff;
}

.alert-inverse {
    @include alert-variant($alert-inverse-bg, $alert-inverse-border, $alert-inverse-text);
}

.growl-animated {
    &.alert-inverse {
        box-shadow: 0 0 5px fade($alert-inverse-bg, 50%);
    }
    
    &.alert-info {
        box-shadow: 0 0 5px fade($alert-info-bg, 50%);
    }

    &.alert-success {
        box-shadow: 0 0 5px fade($alert-success-bg, 50%);
    }

    &.alert-warning {
        box-shadow: 0 0 5px fade($alert-warning-bg, 50%);
    }

    &.alert-danger {
        box-shadow: 0 0 5px fade($alert-danger-bg, 50%);
    }
}

.alert-link {
    color: #fff !important;
}