.form-control {
  &.demo {
    padding-left: 40px!important;
  }
  //&.bottom-right {
  //  padding-left: 0!important;
  //}
}
#position-bottom-right {
  padding-left: 0!important;
}